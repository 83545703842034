import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { differenceInMilliseconds, format } from 'date-fns';
import * as events from "events";
import {
    dateStringAsDate,
    SessionEventTypeId,
    ImageEnum,
    Session,
    SessionEvent,
    SessionCurrentRaceTypeEnum, EventRaceType
} from 'src/app/shared';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';

@Component({
    selector: 'app-event-overview',
    templateUrl: './event-overview.component.html',
    styleUrls: ['./event-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventOverviewComponent implements OnChanges {
    @Input() public session?: Session | null | undefined;
    @Output() public readonly helpButton = new EventEmitter();
    @Output() public readonly openModal = new EventEmitter();

    public readonly eventType = SessionEventTypeId;
    public eventByDays: {day: string; events: SessionEvent[]}[] | undefined;
    public readonly imageEnum = ImageEnum;
    public dateStringAsDate = dateStringAsDate;
    public helpType = DataMarkdownEnum;

    constructor(public readonly modalController: ModalController) {}

    public trackBy = (index: number) => index;

    public ngOnChanges(changes: SimpleChanges): void {
        const { session } = changes;

        if (session.currentValue) {
            const eventsByDaysIterable = this.session?.events
                .filter(
                    (sessionEvent) =>
                        !(
                            sessionEvent.typeOfEvent ===
                            SessionEventTypeId.RACE_START &&
                            sessionEvent.isStart &&
                            differenceInMilliseconds(
                                new Date(),
                                dateStringAsDate(sessionEvent.dateTimeUtc)
                            ) < 0
                        )
                )
                .reduce(
                    (entryMap, e) =>
                        entryMap.set(
                            format(dateStringAsDate(e.dateTimeUtc), 'yyyy-MM-dd'),
                            [
                                ...(entryMap.get(
                                    format(
                                        dateStringAsDate(e.dateTimeUtc),
                                        'yyyy-MM-dd'
                                    )
                                ) || []),
                                e
                            ]
                        ),
                    new Map<string,  SessionEvent[]>()
                ).entries();

            this.eventByDays = Array.from(eventsByDaysIterable).sort((a, b) => new Date(a[0]) > new Date(b[0]) ? 1 : -1).map(a => {return {day: a[0], events: a[1]};});

            for (const eventsByDay of this.eventByDays) {
                eventsByDay.events.sort((event1, event2) =>
                    event1.dateTimeUtc < event2.dateTimeUtc ? -1 : 1
                );
            }
        }
    }

    protected readonly RaceTypeEnum = EventRaceType;
}
