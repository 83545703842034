import { Injectable } from '@angular/core';
import { ActionSheetButton, ActionSheetController } from '@ionic/angular';

export interface ActionSheetChoice {
    text: string;
    id: string;
}

export interface ChoiceActionSheetConfig {
    choices: ActionSheetChoice[];
}

@Injectable({
    providedIn: 'root'
})
export class ActionSheetService {
    constructor(
        private readonly actionSheetController: ActionSheetController
    ) {}

    public async showChoiceActionSheet(
        config: ChoiceActionSheetConfig
    ): Promise<string | undefined> {
        let chosen: string;
        let alertButtons: ActionSheetButton[] = config.choices.map((choice) => {
            return {
                text: choice.text,
                handler: () => {
                    chosen = choice.id;
                    return true;
                },
                role: 'selected'
            };
        });

        alertButtons.push({
            role: 'cancel',
            text: 'Cancel'
        });

        const actionSheet = await this.actionSheetController.create({
            buttons: alertButtons
        });

        await actionSheet.present();
        await actionSheet.onDidDismiss();

        return chosen;
    }
}
