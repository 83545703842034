<div longPress (mouseLongPress)="enabled && open($event)">
    <ng-content></ng-content>
</div>
<ion-popover
    #popover
    [isOpen]="showPopover"
    (didDismiss)="showPopover = false"
    (click)="close()"
    side="bottom"
>
    <ng-template>
        <ng-content select="[popover]"></ng-content>
    </ng-template>
</ion-popover>
