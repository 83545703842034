import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SessionEvent } from '../../../../shared/index';

export enum TestingRunningResultRole {
    SAVE = 'SAVE',
    CANCEL_EDITING = 'CANCEL_EDITING',
    CANCEL_TESTING = 'CANCEL_TESTING',
    STOP = 'STOP'
}

export interface TestRunningResultData {
    sessionEvent: SessionEvent;
}

export interface TestRunningResult {
    role: TestingRunningResultRole;
    data?: TestRunningResultData;
}

@Injectable()
export class TestRunningService {
    private _testRunningResult$: Subject<TestRunningResult> =
        new Subject<TestRunningResult>();

    constructor() {}

    editTestingEvent(resultData: TestRunningResultData) {
        this._testRunningResult$.next({
            role: TestingRunningResultRole.SAVE,
            data: resultData
        });
    }

    stopTesting(resultData: TestRunningResultData) {
        this._testRunningResult$.next({
            role: TestingRunningResultRole.STOP,
            data: resultData
        });
    }

    cancelEditing() {
        this._testRunningResult$.next({
            role: TestingRunningResultRole.CANCEL_EDITING
        });
    }

    cancelTesting() {
        this._testRunningResult$.next({
            role: TestingRunningResultRole.CANCEL_TESTING
        });
    }

    public get testRunningResult$(): Observable<TestRunningResult> {
        return this._testRunningResult$;
    }
}
