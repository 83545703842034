import {
    Directive,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output
} from '@angular/core';
import { EMPTY, fromEvent, merge, Subscription, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { filterEmpty } from '../../shared';

@Directive({
    // eslint-disable-next-line
    selector: '[longPress]'
})
export class LongPressDirective implements OnDestroy {
    private eventSubscribe: Subscription;
    private readonly threshold = 500;

    @Output()
    public readonly mouseLongPress = new EventEmitter<
        MouseEvent | TouchEvent
    >();

    constructor(private elementRef: ElementRef) {
        const mousedown = fromEvent<MouseEvent>(
            this.elementRef.nativeElement,
            'mousedown'
        ).pipe(
            filter((event) => event.button === 0), // Only allow left button (Primary button)
            map((event) => ({
                result: true,
                event
            })) // turn on threshold counter
        );
        const touchstart = fromEvent<TouchEvent>(
            this.elementRef.nativeElement,
            'touchstart'
        ).pipe(
            map((event) => ({
                result: true,
                event
            }))
        );
        const touchEnd = fromEvent(
            this.elementRef.nativeElement,
            'touchend'
        ).pipe(
            map(() => ({
                result: false,
                event: null
            }))
        );
        const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
            filter((event) => event.button === 0), // Only allow left button (Primary button)
            map(() => ({
                result: false,
                event: null
            })) // reset threshold counter
        );
        this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
            .pipe(
                switchMap((state) =>
                    (state ? timer(this.threshold, 100) : EMPTY).pipe(
                        filter(Boolean),
                        map(() => state?.event),
                        filterEmpty()
                    )
                )
            )
            .subscribe((event) => this.mouseLongPress.emit(event));
    }

    public ngOnDestroy(): void {
        if (this.eventSubscribe) {
            this.eventSubscribe.unsubscribe();
        }
    }
}
