<div
    class="grid grid-cols-[2fr_0.5fr] items-center"
    [class.px-5]="title && !imageSource && !subTitle"
>
    <div class="content">
        <app-images
            [image]="imageSource"
            *ngIf="imageSource !== null"
            class="svg-20 pb-[17px]"
        ></app-images>
        <!-- 
    <ion-img
      [src]="imageSource"
      *ngIf="imageSource !== null"
      class="pb-[17px]"
    ></ion-img> -->
        <h1 *ngIf="title && !imageSource && !subTitle">
            {{ title }}
        </h1>
        <h2 *ngIf="title && (imageSource || subTitle)">
            {{ title }}
        </h2>
        <ion-label *ngIf="subTitle" class="subtext pt-[10px]">{{
            subTitle
        }}</ion-label>
    </div>
    <ng-content></ng-content>
</div>
