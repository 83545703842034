import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
    Boat,
    DataService,
    randomColor,
    Sail,
    SailsUpFavorite,
    SailUp
} from '../shared/index';

@Injectable({
    providedIn: 'root'
})
export class BoatsService {
    constructor(private dataService: DataService) {}

    upsertSailToBoat(sail: Sail, boatId: string) {
        this.dataService
            .getBoatById$(boatId)
            .pipe(take(1))
            .subscribe((boat) => {
                const boatCopy: Boat = cloneDeep(boat);

                const sailsWithoutSailWithSameId = boatCopy.sails.filter(
                    (s) => s.id !== sail.id
                );

                sailsWithoutSailWithSameId.push(sail);

                boatCopy.sails = sailsWithoutSailWithSameId;

                this.dataService.upsertBoat(boatCopy);
            });
    }

    public addSailUpFavorite(favorite: SailUp[], boatId: string) {
        this.dataService
            .getBoatById$(boatId)
            .pipe(take(1))
            .subscribe((boat) => {
                const boatCopy: Boat = cloneDeep(boat);

                const currentFavorites = boatCopy.sailUpFavorites
                    ? [...boatCopy.sailUpFavorites]
                    : [];
                currentFavorites.push({
                    id: uuidv4(),
                    sailUp: [...favorite],
                    color: randomColor()
                });
                boatCopy.sailUpFavorites = currentFavorites;

                this.dataService.upsertBoat(boatCopy);
            });
    }

    public editFavorite(favorite: SailsUpFavorite, boatId: string) {
        this.dataService
            .getBoatById$(boatId)
            .pipe(take(1))
            .subscribe((boat) => {
                const boatCopy: Boat = cloneDeep(boat);

                const indexOfFavorite = boatCopy.sailUpFavorites.findIndex(
                    (f) => f.id === favorite.id
                );
                const newFavorites = [...boatCopy.sailUpFavorites];
                newFavorites[indexOfFavorite] = favorite;
                boatCopy.sailUpFavorites = newFavorites;

                this.dataService.upsertBoat(boatCopy);
            });
    }

    public reorderFavorites(from: number, to: number, boatId: string) {
        this.dataService
            .getBoatById$(boatId)
            .pipe(take(1))
            .subscribe((boat) => {
                const boatCopy: Boat = cloneDeep(boat);

                const favoriteFrom = boatCopy.sailUpFavorites[from];
                const favoriteTo = boatCopy.sailUpFavorites[to];
                const newFavorites = [...boatCopy.sailUpFavorites];
                newFavorites[to] = favoriteFrom;
                newFavorites[from] = favoriteTo;

                boatCopy.sailUpFavorites = newFavorites;

                this.dataService.upsertBoat(boatCopy);
            });
    }

    public removeSailUpFavorite(favorite: SailsUpFavorite, boatId: string) {
        this.dataService
            .getBoatById$(boatId)
            .pipe(take(1))
            .subscribe((boat) => {
                const boatCopy: Boat = cloneDeep(boat);

                const currentFavorites = boatCopy.sailUpFavorites
                    ? [...boatCopy.sailUpFavorites]
                    : [];

                const withoutToBeRemovedFavorite = currentFavorites.filter(
                    (f) => f.id !== favorite.id
                );

                boatCopy.sailUpFavorites = withoutToBeRemovedFavorite;

                this.dataService.upsertBoat(boatCopy);
            });
    }
}
