import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { SailService } from '../../services/sail.service';
import { Boat, Session, Settings, StatusEnum } from '../models';
import { DataTypeEnum } from '../models/data.model';
import { SessionWithBoatDetails } from '../models/sessionWithBoatDetails.model';
import { IonicStorageService } from './ionic-storage.service';

@Injectable({
    providedIn: 'root'
})
export class DataService implements OnDestroy {
    private static BOATS_STORAGE_KEY = 'OBA_BOATS_STORAGE_KEY';
    private static SESSIONS_STORAGE_KEY = 'OBA_SESSIONS_STORAGE_KEY';
    private static SETTING_STORAGE_KEY = 'OBA_SETTINGS_STORAGE_KEY';

    private _boatsMap$: BehaviorSubject<Record<string, Readonly<Boat>>> =
        new BehaviorSubject<Record<string, Boat>>({});
    private _sessionsMap$: BehaviorSubject<Record<string, Readonly<Session>>> =
        new BehaviorSubject<Record<string, Session>>({});

    private _settings$: BehaviorSubject<Readonly<Settings>> =
        new BehaviorSubject<Settings>({
            allowEmailNotifications: false,
            allowMultiSessions: false,
            allowPushNotifications: false,
            allowSmsNotifications: false,
            useImperialUnits: false
        });

    public sessions$: Observable<ReadonlyArray<Readonly<Session>>> =
        this._sessionsMap$.pipe(map((sessions) => Object.values(sessions)));
    public endedSessions$: Observable<ReadonlyArray<Readonly<Session>>> =
        this.sessions$.pipe(
            map((sessions) => sessions.filter((session) => !!session.endDate))
        );

    public boats$: Observable<ReadonlyArray<Readonly<Boat>>> =
        this._boatsMap$.pipe(map((boats) => Object.values(boats)));
    public settings$: Observable<Readonly<Settings>> = this._settings$;

    public sessionsWithBoatDetails$: Observable<
        ReadonlyArray<Readonly<SessionWithBoatDetails>>
    > = combineLatest([this.sessions$, this.boats$]).pipe(
        map(([sessions, boats]) =>
            sessions.map((session) => {
                const boat = boats.find(
                    (thisBoat) => thisBoat.id === session.boat
                );

                return {
                    session,
                    sails: this.sailService.getSailsOfSession(session, boat)
                };
            })
        )
    );

    public canActivateSession$: Observable<boolean>;
    private refreshSubject$ = new BehaviorSubject<string>('');
    private readonly componentDestroyed$ = new Subject<void>();

    constructor(
        private readonly ionicStorageService: IonicStorageService,
        private readonly sailService: SailService
    ) {}

    // This refreshing thing is a leftover from the older version where we used a sqlite database... leaving it here just in case
    public async initialize() {
        this.refreshSubject$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((f) => {
                    switch (f) {
                        case DataTypeEnum.boat:
                            this.refreshBoats();
                            break;
                        case DataTypeEnum.session:
                            this.refreshSessions();
                            break;
                        case DataTypeEnum.settings:
                            this.refreshSettings();
                            break;
                        default:
                            this.refreshAll();
                            break;
                    }
                })
            )
            .subscribe();

        this.canActivateSession$ = combineLatest([
            this.sessions$,
            this.settings$
        ]).pipe(
            map(([_sessions, _settings]) => {
                const activeSessionCount = _sessions.filter(
                    (f) => f.status === StatusEnum.active
                ).length;
                return Boolean(_settings?.allowMultiSessions)
                    ? true
                    : activeSessionCount === 0;
            })
        );
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public upsertBoat(boat: Boat) {
        const boatCopy: Boat = cloneDeep(boat);

        if (!boatCopy.id) {
            boatCopy.id = uuidv4();
        }

        const currentBoats = { ...this._boatsMap$.value };
        currentBoats[boatCopy.id] = boatCopy;
        this._boatsMap$.next(currentBoats);
        this.ionicStorageService.set(
            DataService.BOATS_STORAGE_KEY,
            currentBoats
        );
    }

    get sessions() {
        return Object.values(this._sessionsMap$.value);
    }

    public upsertSession(session: Session) {
        const sessionCopy = cloneDeep(session);

        if (!sessionCopy.id) {
            sessionCopy.id = uuidv4();
        }

        const currentSessions = { ...this._sessionsMap$.value };
        currentSessions[sessionCopy.id] = sessionCopy;
        this._sessionsMap$.next(currentSessions);
        this.ionicStorageService.set(
            DataService.SESSIONS_STORAGE_KEY,
            currentSessions
        );
    }

    public upsertSettings(settings: Settings) {
        const settingsCopy = cloneDeep(settings);

        this._settings$.next(settingsCopy);
        this.ionicStorageService.set(
            DataService.SETTING_STORAGE_KEY,
            settingsCopy
        );
    }

    public getBoatById$(id: string) {
        return this._boatsMap$.pipe(map((boats) => boats[id]));
    }

    public getBoatById(id: string) {
        return this._boatsMap$.value[id];
    }

    public getBoatByName(boatName: string): Boat | undefined {
        const boatsMap = this._boatsMap$.getValue();
        return Object.values(boatsMap).find(boat => boat.name === boatName);
      }

    public getSessionById$(id: string) {
        return this._sessionsMap$.pipe(map((sessions) => sessions[id]));
    }

    public getSessionById(id: string) {
        const session = this._sessionsMap$.value[id];
        return session;
    }

    public removeSession(id: string) {
        const currentSessions = { ...this._sessionsMap$.value };
        delete currentSessions[id];
        this._sessionsMap$.next(currentSessions);
        this.ionicStorageService.set(
            DataService.SESSIONS_STORAGE_KEY,
            currentSessions
        );
    }

    public removeBoat(id: string) {
        const currentBoats = { ...this._boatsMap$.value };
        delete currentBoats[id];
        this._boatsMap$.next(currentBoats);
        this.ionicStorageService.set(
            DataService.BOATS_STORAGE_KEY,
            currentBoats
        );
    }

    private refreshAll() {
        this.refreshSettings();
        this.refreshBoats();
        this.refreshSessions();
    }

    private async refreshBoats() {
        const boatsFromStorage = await this.ionicStorageService.get(
            DataService.BOATS_STORAGE_KEY
        );
        if (boatsFromStorage) {
            this._boatsMap$.next(boatsFromStorage);
        }
    }

    private async refreshSessions() {
        const sessionsFromStorage = await this.ionicStorageService.get(
            DataService.SESSIONS_STORAGE_KEY
        );
        if (sessionsFromStorage) {
            this._sessionsMap$.next(sessionsFromStorage);
        }
    }

    private async refreshSettings() {
        const settingsFromStorage = await this.ionicStorageService.get(
            DataService.SETTING_STORAGE_KEY
        );
        if (settingsFromStorage) {
            this._settings$.next(settingsFromStorage);
        }
    }

    async importBoat(
        sharedBoat: Boat,
        importBoatSetting: ImportBoatSetting = ImportBoatSetting.NONE
    ): Promise<ImportBoatResponse> {
        const boatByName = this.getBoatByName(sharedBoat.name);

        // Boat does not exist or should be overwritten
        if (!boatByName || importBoatSetting === ImportBoatSetting.OVERWRITE) {

            // We keep the id when overwriting an existing boat,
            // but assign a new id when creating a new boat
            if (boatByName && importBoatSetting === ImportBoatSetting.OVERWRITE) {
                sharedBoat.id = boatByName.id;
            } else if (!boatByName) {
                sharedBoat.id = uuidv4();
            }
            this.upsertBoat(sharedBoat);
            return ImportBoatResponse.IMPORTED;
        }

        if (boatByName && importBoatSetting === ImportBoatSetting.NONE) {
            return ImportBoatResponse.ALREADY_EXISTS;
        }

        if (boatByName && importBoatSetting === ImportBoatSetting.COPY) {
            // We assign a new boat id when creating a copy of the boat
            sharedBoat.id = uuidv4();

            let counter = 2;
            let newBoatName;
            do {
                newBoatName = sharedBoat.name + ' (' + counter + ')';
                counter++;
            } while (this.getBoatByName(newBoatName));

            sharedBoat.name = newBoatName;

            this.upsertBoat(sharedBoat);
            return ImportBoatResponse.IMPORTED;
        }

        return ImportBoatResponse.UNKNOWN;
    }

    doesSessionWithBoatExist(boatId: string) {
        return !!this.sessions.find(session => session.boat === boatId);
    }
}

export enum ImportBoatSetting {
    NONE,
    OVERWRITE,
    COPY
}

export enum ImportBoatResponse {
    IMPORTED,
    ALREADY_EXISTS,
    UNKNOWN
}
