import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    Boat,
    ImageEnum,
    Property,
    TypeOfBoat,
    TypeOfSail,
    TypeOfSailGroup
} from 'src/app/shared';
import { TYPE_OF_BOAT } from '../../../data/TypeOfBoat';
import { TYPE_OF_SAIL_GROUP } from '../../../data/TypeOfSailGroup';

@Component({
    selector: 'app-configuration-overview',
    templateUrl: './configuration-overview.component.html',
    styleUrls: ['./configuration-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationOverviewComponent {
    @Input() boat: Boat;
    typeOfBoats: ReadonlyArray<TypeOfBoat> = TYPE_OF_BOAT;
    typeOfSailGroup: ReadonlyArray<TypeOfSailGroup> = TYPE_OF_SAIL_GROUP;
    @Output() goToStep = new EventEmitter<number>();
    @Output() next = new EventEmitter();
    public readonly imageEnum = ImageEnum;
    constructor() {}

    isPropertyString = (property: Property): boolean => {
        return property.typeOfProperty === 'LIST_OF_VALUES';
    };

    isPropertyNumber = (property: Property): boolean => {
        return property.typeOfProperty === 'NUMBER';
    };
}
