<div class="flex flex-col justify-start h-full ion-page">
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start" class="px-[7px]">
                <ion-button button (click)="wizardService.goBack()">
                    <ion-icon name="arrow-back-outline" color="dark"></ion-icon>
                </ion-button>
            </ion-buttons>
            <app-stepper
                toolbarContent
                [stepAmount]="steps.length"
                [currentStep]="wizardService.currentStep"
            ></app-stepper>
            <ion-buttons slot="end">
                <app-help
                    contextButton
                    [helpType]="helpButtons[wizardService.currentStep]"
                ></app-help>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div
        class="m-[20px] h-full flex flex-col justify-start wizard-content-container"
    >
        <ng-container *ngIf="wizardService.current">
            <ng-container
                *ngxComponentOutlet="$any(wizardService.current)"
            ></ng-container>
        </ng-container>
    </div>
</div>
