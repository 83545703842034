import {
    HttpClient,
    HttpContext,
    HttpHeaders,
    HttpParams,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    public get<T>(
        url: string,
        options?: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]:
                          | string
                          | number
                          | boolean
                          | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
        }
    ) {
        return this.httpClient.get<T>(environment.apiUrl + url, options);
    }

    public post<T>(url: string, body: any) {
        return this.httpClient.post<T>(environment.apiUrl + url, body);
    }

    public put(url: string, body: any) {
        return this.httpClient.put(environment.apiUrl + url, body);
    }

    public getBlob(
        url: string,
        options?: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]:
                          | string
                          | number
                          | boolean
                          | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType: 'blob';
            withCredentials?: boolean;
        }
    ): Observable<Blob> {
        return this.httpClient.get(environment.apiUrl + url, options);
    }
}
