import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, interval, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class TimerService {
    public readonly time$: Observable<number>;

    private trigger = new BehaviorSubject<boolean>(false);
    private current = 0;

    constructor() {
        this.time$ = this.trigger.pipe(
            switchMap((isEnabled) =>
                iif(
                    () => !isEnabled,
                    of(this.current),
                    interval(1000).pipe(
                        filter(() => this.trigger.value),
                        tap(() => (this.current += 1000)),
                        map(() => this.current)
                    )
                )
            )
        );
    }

    public start() {
        this.trigger.next(true);
    }

    public pause() {
        this.trigger.next(false);
    }

    public clear() {
        this.pause();
        this.current = 0;
    }
}
