import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-logo-boat',
    templateUrl: './logo-boat.component.html',
    styleUrls: ['./logo-boat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoBoatComponent {
    @Input() public color: 'black' | 'white' = 'black';
}
