import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';
import { ImageService } from '../../../services/image.service';
import { ModalService } from '../../../services/modal.service';
import { BoatImage } from '../../../shared/index';

@Component({
    selector: 'app-boat-image-upload',
    templateUrl: './boat-image-upload.component.html',
    styleUrls: ['./boat-image-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class BoatImageUploadComponent implements OnInit, AfterViewInit {
    @Input()
    currentImage: BoatImage;

    imageBase64: BehaviorSubject<string> = new BehaviorSubject<string>(
        undefined
    );
    croppedImageBase64: string;

    constructor(
        private imageService: ImageService,
        private sanitizer: DomSanitizer,
        private modalService: ModalService,
        private _change: ChangeDetectorRef
    ) {}

    async takeOrUploadImage() {
        const image = await this.imageService.takeOrUploadImage();
        this.imageBase64.next(
            'data:' + image.mimeType + ';base64,' + image.base64Data
        );
    }

    imageLoaded(loadedImage: LoadedImage) {}

    cropperReady() {}

    loadImageFailed() {}

    imageCropped($event: ImageCroppedEvent) {
        this.croppedImageBase64 = $event.base64;
    }

    ngOnInit(): void {
        if (this.currentImage) {
            this.imageBase64.next(this.currentImage.uploadedImageBase64);
        }
    }

    ngAfterViewInit() {
        this._change.markForCheck();
    }

    close() {
        this.modalService.dismiss();
    }

    addPhoto() {
        const boatImage: BoatImage = {
            uploadedImageBase64: this.croppedImageBase64,
            defaultImagePath: this.currentImage?.uploadedImageBase64
        };
        this.modalService.dismiss(boatImage);
    }

    removePhoto() {
        const boatImage: BoatImage = {
            uploadedImageBase64: undefined,
            defaultImagePath: this.currentImage?.defaultImagePath
        };
        this.modalService.dismiss(boatImage);
    }

    private getFileReader() {
        const fileReader = new FileReader();
        const zoneOriginalInstance = (fileReader as any)[
            '__zone_symbol__originalInstance'
        ];
        return zoneOriginalInstance || fileReader;
    }
}
