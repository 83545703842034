import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { valueNotSet } from 'src/app/shared/index';
import { PropertyForm } from '../../../../services/boats/properties/property-form';

@Component({
    selector: 'app-edit-property-base',
    templateUrl: './edit-property-base.component.html',
    styleUrls: ['./edit-property-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPropertyBaseComponent implements OnDestroy, OnInit {
    @Input() public propertyForm: FormGroup<PropertyForm>;

    public values: FormArray;
    public valueNotSet = valueNotSet;
    public isNaN = Number.isNaN;
    private readonly componentDestroyed$ = new Subject<void>();

    constructor(public readonly modalController: ModalController) {}

    public ngOnInit(): void {
        this.propertyForm.controls.propertyEnabled.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                map(() => {
                    if (
                        this.propertyForm.controls.propertyEnabled.value ===
                        null
                    ) {
                        this.propertyForm.controls.propertyEnabled.setValue(
                            false
                        );
                    }
                    if (!this.propertyForm.controls.propertyEnabled.value) {
                        this.propertyForm.controls.valueCurrent.setValue(null);
                    }
                })
            )
            .subscribe();

        this.propertyForm.controls.valueCurrent.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                map(() => {
                    if (this.propertyForm.controls.valueCurrent.value) {
                        this.propertyForm.controls.propertyEnabled.setValue(
                            true
                        );
                    }
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public cleanValue(val: number | string | null) {
        if (this.isNaN(val)) {
            return null;
        } else if (typeof val === 'number') {
            return this.roundValue(val);
        } else {
            return val;
        }
    }

    public readableValue(val: number | string | null) {
        const clean = this.cleanValue(val);
        if (clean === null) {
            return this.valueNotSet;
        } else {
            return clean;
        }
    }

    public roundValue(num: number) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
}
