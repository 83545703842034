import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
    Validators
} from '@angular/forms';
import { valueNotSet } from 'src/app/shared';

export interface SelectOption<T> {
    displayValue: string;
    value: T;
}

@Component({
    selector: 'app-input-simple-select',
    templateUrl: 'input-simple-select.component.html',
    styleUrls: ['input-simple-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputSimpleSelectComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: InputSimpleSelectComponent
        }
    ]
})
export class InputSimpleSelectComponent<T>
    implements ControlValueAccessor, Validator
{
    @Input() public title = 'Label';
    @Input() public options: SelectOption<T>[];
    @Input() public placeholder: string = '';
    @Input() public formControl: FormControl<T>;

    public value;

    public onChange: (value: any) => void = () => {};
    public onTouched: () => void = () => {};
    public valueNotSet = valueNotSet;
    public disabled;

    public get isInvalid(): boolean {
        return this.formControl.invalid;
    }

    public get isRequired(): boolean {
        return this.formControl.hasValidator(Validators.required);
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    public registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public validate(_control: AbstractControl) {
        if (_control.valid) {
            return null;
        }

        return _control.errors;
    }
}
