<div
    class="w-[80%] bg-white block p-[20px] text-black rounded-lg drop-shadow-md"
    (click)="$event.preventDefault(); $event.stopPropagation()"
>
    <div class="flex flex-col">
        <div class="flex justify-center mb-[36px]">
            <svg
                width="39"
                height="38"
                viewBox="0 0 39 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.75 0.25V2.33333V23.1667V37.75H4.91667V23.1667H20.2975L22.3809 27.3333H38.25V4.41667H24.9525L22.8691 0.25H0.75ZM4.91667 4.41667H20.2975L22.3809 8.58333H23.6667H34.0833V23.1667H24.9525L22.8691 19H4.91667V4.41667Z"
                    fill="black"
                />
            </svg>
        </div>
        <span class="text-[23px] font-[590] text-center">{{
            isPractice ? 'End practice start' : 'The race is finished'
        }}</span>
        <span
            class="text-[15px] font-[400] text-[#777777] text-center mt-[10px]"
            >Please confirm that the race is finished.</span
        >
        <span
            class="text-[15px] font-[400] text-[#777777] text-center mb-[30px]"
            >Action cannot be undone.</span
        >
        <ion-button
            class="h-[50px] mb-[20px] mt-[10px]"
            expand="full"
            shape="round"
            (click)="modalController.dismiss(null, 'finish')"
            >Confirm
        </ion-button>
        <ion-button
            class="h-[50px]"
            color="light"
            expand="full"
            shape="round"
            (click)="cancel()"
            >Cancel
        </ion-button>
    </div>
</div>
