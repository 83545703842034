import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthStore, DataService } from './shared';
import { MenuService } from './shared/services/menu.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
    public isWeb = false;
    public isInitialized = false;
    public readonly name$ = this.authStore.name$;
    public keyboardHeight = 0;

    constructor(
        public readonly menuService: MenuService,
        private readonly platform: Platform,
        private readonly dataService: DataService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly authStore: AuthStore
    ) {
        this.platform.ready().then(async () => {
            await this.dataService.initialize();
            this.isInitialized = true;
            this.changeDetectorRef.detectChanges();
        });
    }

    public async login() {
        await this.authStore.login();
    }

    public useOffline() {
        this.authStore.useOffline();
    }

    public logout() {
        this.authStore.logout();
    }
}
