import { Injectable } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular';

export interface AlertChoice {
    text: string;
    id: string;
}

export interface AlertConfig {
    choices: AlertChoice[];
    title: string;
    message?: string;
    showCancel?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private readonly alertController: AlertController) {}

    public async showAlert(config: AlertConfig): Promise<string | undefined> {
        const alertButtons: AlertButton[] = config.choices.map((choice) => ({
            text: choice.text,
            handler: () => ({
                choice: choice.id
            }),
            role: 'selected'
        }));

        if (config.showCancel !== false) {
            alertButtons.push({
                role: 'cancel',
                text: 'Cancel'
            });
        }

        const alert = await this.alertController.create({
            header: config.title,
            buttons: alertButtons,
            message: config.message
        });

        await alert.present();
        const returnValue = await alert.onDidDismiss<{ choice: string }>();

        return returnValue.data?.choice;
    }
}
