import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Input
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-confirm-race-finish',
    templateUrl: './confirm-race-finish.component.html',
    styleUrls: ['./confirm-race-finish.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmRaceFinishComponent {
    @Input()
    public isPractice: boolean = false;

    constructor(public readonly modalController: ModalController) {}

    @HostListener('click')
    public click() {
        this.cancel();
    }

    public cancel() {
        this.modalController.dismiss(null, 'cancel');
    }
}
