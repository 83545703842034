import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-multiple-choice-checkboxes',
    templateUrl: 'multiple-choice-checkboxes.component.html',
    styleUrls: ['multiple-choice-checkboxes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MultiplChoiceCheckboxesComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: MultiplChoiceCheckboxesComponent
        }
    ]
})
export class MultiplChoiceCheckboxesComponent
    implements ControlValueAccessor, Validator, OnInit, OnDestroy
{
    @Input() list$: Observable<
        ReadonlyArray<{ value: any; displayValue: string }>
    >;
    @Input() title: string;

    private readonly componentDestroyed$ = new Subject<void>();
    private checkedItems: Set<any> = new Set<any>();

    private onChange: (value: any[]) => void = () => {};

    private onTouched: () => void = () => {};

    public constructor() {}

    public registerOnChange(fn: (value: any[]) => void): void {
        this.onChange = fn;
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public validate(_control: AbstractControl) {
        if (_control.valid) {
            return null;
        }
        return _control.errors;
    }

    writeValue(checkedItems: any[]): void {
        if (checkedItems) {
            checkedItems.forEach((item) => this.checkedItems.add(item));
        }
    }

    isItemChecked(item: string) {
        return this.checkedItems.has(item);
    }

    toggleItem(item: string) {
        if (this.checkedItems.has(item)) {
            this.checkedItems.delete(item);
        } else {
            this.checkedItems.add(item);
        }
        this.onChange([...this.checkedItems.values()]);
        this.onTouched();
    }

    ngOnInit(): void {
        this.list$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((list) => {
                this.checkedItems.forEach((item) => {
                    if (!list.find((listItem) => listItem.value == item)) {
                        this.checkedItems.delete(item);
                    }
                });
            });
    }
}
