<div class="w-[80%] bg-white p-[20px] text-black rounded-lg drop-shadow-md">
    <div class="flex flex-col">
        <div class="flex justify-center mb-[36px]">
            <ion-img class="w-[38px] mt-[34px]" [src]="icon"></ion-img>
        </div>
        <span class="text-[23px] font-[590] text-center">{{ title }}</span>
        <span
            class="text-[15px] font-[400] text-[#777777] text-center mt-[10px] mb-[30px]"
            >{{ text }}</span
        >
        <ion-button
            *ngFor="let button of buttons; trackBy: trackBy"
            class="h-[50px] mb-[20px] {{ button.css }}"
            expand="full"
            shape="round"
            [color]="button.color"
            (click)="modalController.dismiss(null, button.role)"
            >{{ button.text }}</ion-button
        >
    </div>
</div>
