import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, from, Observable, of } from 'rxjs';
import {
    filter,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import { v4 as uuidv4 } from 'uuid';
import { CommentService } from '../../../services/comment.service';
import {
    EventRaceType,
    filterEmpty,
    SessionCurrentRaceTypeEnum,
    SessionEventType,
    SessionEventTypeId
} from '../../../shared';
import { SessionEvent } from '../../../shared/models/sessionEvent.model';
import { StartRaceTime } from '../../components/start-race/start-race.component';
import { SessionComponentState } from '../../models';
import { SessionStore } from '../../session.store';

export interface RaceForm {
    raceNumber: FormControl<number>;
    numberOfMarks?: FormControl<number>;
    isPractice: FormControl<number>;
}

@Component({
    selector: 'app-start-race-page',
    templateUrl: './start-race.page.html',
    styleUrls: ['./start-race.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartRacePage
    extends ComponentStore<SessionComponentState>
    implements OnInit
{
    public readonly options = [
        { label: 'In 5 min', value: 5 },
        { label: 'In 4 min', value: 4 },
        { label: 'In 1 min', value: 1 },
        { label: 'Now', value: 0 }
    ];
    public readonly date$ = this.select((x) =>
        x?.sessionEvent?.dateTimeUtc
            ? new Date(x?.sessionEvent?.dateTimeUtc)
            : undefined
    );
    public readonly isEdit$ = this.select(() => this.lastEvent).pipe(
        map((result) => Boolean(result))
    );

    public readonly form = this.formBuilder.group<RaceForm>({
        raceNumber: this.formBuilder.control(1, Validators.required),
        numberOfMarks: this.formBuilder.control(4),
        isPractice: this.formBuilder.control(0, Validators.required)
    });

    // Parameters coming from the outside
    public typeOfEvent: Readonly<SessionEventType>;
    public lastEvent: Readonly<SessionEvent>;

    public helpType = DataMarkdownEnum;

    public readonly editDate = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(this.select((x) => x.sessionEvent)),
            switchMap(([, session]) =>
                from(
                    this.commentService.changeDate(
                        session.dateTimeUtc
                            ? new Date(session.dateTimeUtc)
                            : undefined
                    )
                ).pipe(
                    filter((result) => result.role === 'save'),
                    map((data) => data.data),
                    filterEmpty(),
                    tap((date) =>
                        this.patchState({
                            sessionEvent: {
                                ...session,
                                dateTimeUtc: date.getTime()
                            }
                        })
                    )
                )
            )
        )
    );

    public readonly addComment = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            switchMap(() => {
                const eventType = {
                    ...this.typeOfEvent,
                    commentCommit: 'Save',
                    commentTitle: Boolean(this.lastEvent)
                        ? 'Edit comment'
                        : 'Add comment'
                } as SessionEventType;
                return from(
                    this.commentService.openComment(
                        eventType,
                        this.lastEvent?.dateTimeUtc
                            ? new Date(this.lastEvent.dateTimeUtc)
                            : new Date(),
                        this.lastEvent
                    )
                ).pipe(
                    filterEmpty(),
                    withLatestFrom(this.select((x) => x.sessionEvent)),
                    tap(([comment, sessionEvent]) =>
                        this.patchState({
                            sessionEvent: {
                                ...sessionEvent,
                                comment: comment.comment
                            }
                        })
                    )
                );
            })
        )
    );

    public readonly startRace = this.effect(
        (trigger$: Observable<StartRaceTime>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.sessionEvent)),
                tap(
                    ([startRaceTime, sessionEvent]: [
                        StartRaceTime,
                        SessionEvent
                    ]) =>
                        this.modalController.dismiss(
                            {
                                ...sessionEvent,
                                typeOfEvent: this.typeOfEvent.id,
                                raceIntervalMinutes:
                                    startRaceTime.raceIntervalMinutes,
                                startTimestampMilliseconds:
                                    startRaceTime.startTimestampMilliseconds,
                                raceNumber: Number(
                                    this.form.get('raceNumber')?.value
                                ),
                                marks: this.form.value.numberOfMarks
                                    ? Number(
                                          this.form.get('numberOfMarks')?.value
                                      )
                                    : 0,
                                raceType: Boolean(
                                    this.form.get('isPractice')?.value
                                )
                                    ? SessionCurrentRaceTypeEnum.practice
                                    : SessionCurrentRaceTypeEnum.race
                            },
                            'save'
                        )
                )
            )
    );

    public readonly cancel = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(this.select((x) => x.sessionEvent)),
            tap(([, session]) =>
                this.modalController.dismiss(
                    {
                        ...session,
                        typeOfEvent: SessionEventTypeId.CANCELLED,
                        raceNumber: Number(this.form.get('raceNumber')?.value),
                        marks: this.form.value.numberOfMarks
                            ? Number(this.form.get('numberOfMarks')?.value) - 1
                            : -1,
                        raceType: SessionCurrentRaceTypeEnum.none
                    } as SessionEvent,
                    'cancel'
                )
            )
        )
    );

    constructor(
        public readonly modalController: ModalController,
        private readonly commentService: CommentService,
        private readonly formBuilder: FormBuilder,
        private readonly sesionStore: SessionStore
    ) {
        super({
            sessionEvent: {
                id: uuidv4(),
                typeOfEvent: undefined,
                dateTimeUtc: new Date().getTime(),
                comment: {
                    comment: '',
                    mediaFiles: []
                }
            }
        });

        combineLatest([
            sesionStore.highestRaceNumber$.pipe(filterEmpty()),
            of(true)
        ])
            .pipe(take(1))
            .subscribe(([number]) => {
                this.form.controls.raceNumber.setValue(number + 1);
            });
    }

    public ngOnInit(): void {
        if (!this.lastEvent) {
            return;
        }

        this.patchState({
            sessionEvent: this.lastEvent
        });

        this.form.patchValue({
            raceNumber: this.lastEvent.raceNumber!,
            numberOfMarks: (this.lastEvent.marks ?? -1) + 1,
            isPractice:
                this.lastEvent.raceType === EventRaceType.PRACTICE ? 1 : 0
        });
    }
}
