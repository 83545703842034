<div *ngIf="items$ | async as items">
    <app-layout [border]="true" [formGroup]="form">
        <ion-button button fill="clear" (click)="dismiss.emit()">
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        <h4 toolbarContent>{{ title ? title : typeOfEvent.commentTitle }}</h4>
        <app-help contextButton [helpType]="helpText"></app-help>
        <ng-content select="[additionalHeader]"></ng-content>
        <div
            class="grid grid-cols-[1fr_1fr] gap-[15px] pb-[15px]"
            *ngIf="typeOfEvent.typeOfEventOptions"
        >
            <ng-container
                *ngFor="
                    let eventOption of typeOfEvent.typeOfEventOptions;
                    trackBy: trackEventOption
                "
            >
                <span
                    (click)="select(eventOption)"
                    class="font-[17px] font-[590] rounded-[4px] flex flex-col items-center justify-center"
                    [ngClass]="{
                        big: true,
                        small: typeOfEvent.typeOfEventOptions?.length !== 2
                    }"
                    [ngStyle]="{
                        'background-color':
                            form.get('eventOption')?.value !==
                            eventOption.eventButton.name
                                ? eventOption.eventButton.color.background
                                : eventOption.eventButton.colorSelected
                                      ?.background,
                        color:
                            form.get('eventOption')?.value !==
                            eventOption.eventButton.name
                                ? eventOption.eventButton.color.text
                                : eventOption.eventButton.colorSelected?.text
                    }"
                >
                    <div
                        *ngIf="eventOption.eventButton.icon"
                        [innerHtml]="eventOption.eventButton.icon | safeHtml"
                        [ngClass]="{
                            black:
                                form.get('eventOption')?.value !== eventOption,
                            white:
                                form.get('eventOption')?.value === eventOption
                        }"
                        class="fill mb-[13px]"
                    ></div>
                    {{ eventOption.eventButton.name }}
                </span>
            </ng-container>
        </div>

        <ion-textarea
            class="border-[#BBBBBB] rounded border-2 border-solid p-[10px] h-[20vh]"
            placeholder="Type your comments here..."
            formControlName="comment"
        ></ion-textarea>

        <div class="grid grid-cols-[1fr_1fr]">
            <ion-button button fill="clear" (click)="takePicture()">
                <ion-icon slot="start" name="camera-outline"></ion-icon>
                Add photo
            </ion-button>
            <ion-button button fill="clear" (click)="recordVideo()">
                <ion-icon slot="start" name="videocam-outline"></ion-icon>
                Record video
            </ion-button>
            <ion-button button fill="clear" id="trigger-button">
                <ion-icon slot="start" name="mic-outline"></ion-icon>
                Record voice
            </ion-button>
        </div>
        <div class="grid grid-cols-3 gap-[18px] mt-[34px]">
            <div
                class="relative h-[100px] w-[100px] bg-[#083A81] rounded-[4px] items-center flex justify-center"
                [ngClass]="{ 'pt-[26px]': item.type === 'AUDIO' }"
                *ngFor="let item of items; trackBy: trackItems"
            >
                <div *ngIf="getDataBase64(item) | async as blobUrl">
                    <button
                        ion-button
                        fill="clear"
                        (click)="removeItem.emit(item)"
                    >
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute right-[10px] top-[10px]"
                        >
                            <circle
                                opacity="0.7"
                                cx="11"
                                cy="11"
                                r="11"
                                fill="white"
                            />
                            <path
                                d="M5.65997 6.88592L9.77405 11L5.65997 15.1141L6.68849 16.1426L10.8026 12.0285L14.9166 16.1426L15.9452 15.1141L11.8311 11L15.9452 6.88592L14.9166 5.8574L10.8026 9.97148L6.68849 5.8574L5.65997 6.88592Z"
                                fill="black"
                            />
                        </svg>
                    </button>

                    <div *ngIf="item.type === 'AUDIO'" class="h-full w-[50px]">
                        <button ion-button (click)="playAudio(item)">
                            <svg
                                *ngIf="currentlyPlaying !== item; else pause"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.9998 0.666992C6.63584 0.666992 0.666504 6.63633 0.666504 14.0003C0.666504 21.3643 6.63584 27.3337 13.9998 27.3337C21.3638 27.3337 27.3332 21.3643 27.3332 14.0003C27.3332 6.63633 21.3638 0.666992 13.9998 0.666992ZM13.9998 3.33366C19.8812 3.33366 24.6665 8.11899 24.6665 14.0003C24.6665 19.8817 19.8812 24.667 13.9998 24.667C8.1185 24.667 3.33317 19.8817 3.33317 14.0003C3.33317 8.11899 8.1185 3.33366 13.9998 3.33366ZM11.3332 8.00033V20.0003L19.3332 14.0003L11.3332 8.00033Z"
                                    fill="white"
                                />
                            </svg>
                            <ng-template #pause>
                                <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.9998 0.666992C6.63584 0.666992 0.666504 6.63633 0.666504 14.0003C0.666504 21.3643 6.63584 27.3337 13.9998 27.3337C21.3638 27.3337 27.3332 21.3643 27.3332 14.0003C27.3332 6.63633 21.3638 0.666992 13.9998 0.666992ZM13.9998 3.33366C19.8812 3.33366 24.6665 8.11899 24.6665 14.0003C24.6665 19.8817 19.8812 24.667 13.9998 24.667C8.1185 24.667 3.33317 19.8817 3.33317 14.0003C3.33317 8.11899 8.1185 3.33366 13.9998 3.33366ZM9.99984 8.66699V19.3337H12.6665V8.66699H9.99984ZM15.3332 8.66699V19.3337H17.9998V8.66699H15.3332Z"
                                        fill="white"
                                    />
                                </svg>
                            </ng-template>
                        </button>
                        <div class="text-white mt-[10px]">
                            {{ item.durationSec | msToUser }}
                        </div>
                    </div>

                    <div *ngIf="item.type === 'IMAGE'" class="h-full w-[50px]">
                        <img
                            [src]="blobUrl | safeUrl"
                            class="h-full w-[50px]"
                        />
                    </div>

                    <div *ngIf="item.type === 'VIDEO'">
                        <video class="h-full w-[50px]" controls>
                            <source
                                type="video/mp4"
                                [src]="blobUrl | safeUrl"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </div>

        <ng-content select="[additionalData]"></ng-content>

        <div class="bottom_space">&nbsp;</div>
        <ion-footer>
            <ion-button
                *ngIf="showSaveButton"
                shape="round"
                expand="full"
                [disabled]="
                    !(
                        form.valid ||
                        ((items?.length || 0) > 0 &&
                            !typeOfEvent.typeOfEventOptions)
                    )
                "
                (click)="dismiss.emit('save')"
                >{{ saveButtonText }}
            </ion-button>
            <ng-content select="[additionalFooterData]"></ng-content>
            <div
                class="w-full text-center pt-[30px] text-[black]"
                *ngIf="form.get('date')?.value && withDateChange"
                (click)="changeDate()"
            >
                <ion-icon name="time-outline"></ion-icon>
                {{ form.get('date')?.value | date : 'dd/MM/yyyy HH:mm:ss' }}
            </div>
        </ion-footer>
    </app-layout>

    <ion-modal [initialBreakpoint]="0.5" trigger="trigger-button">
        <ng-template>
            <app-comment-audio
                [hasRecording]="hasRecording"
                [isRecording]="isRecording"
                [time]="time$ | async | msToUser"
                (recordAudio)="recordAudio()"
                (stopRecording)="stopRecording()"
                (done)="done()"
                (dismiss)="dismiss.emit('cancel')"
            ></app-comment-audio>
        </ng-template>
    </ion-modal>
</div>
