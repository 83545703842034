import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
    CommentMedia,
    DataService,
    SessionCurrentRaceTypeEnum,
    SESSION_EVENT_TYPES,
    SessionEventTypeId
} from 'src/app/shared/index';

import { v4 as uuidv4 } from 'uuid';
import { SessionStore } from '../../../session.store';
import { TestingEventsConstants } from '../common/testing-events-constants';
import { StartTestingService } from './start-testing.service';

@Component({
    selector: 'app-start-testing',
    templateUrl: './start-testing.page.html',
    styleUrls: ['./start-testing.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartTestingPage implements OnInit {
    @Input()
    public startTestingService: StartTestingService;

    public items$: BehaviorSubject<Array<CommentMedia>> = new BehaviorSubject(
        []
    );
    public form: FormGroup;
    public date? = new Date();

    public durations = TestingEventsConstants.DURATIONS;

    constructor(
        public readonly sessionStore: SessionStore,
        public readonly dataService: DataService
    ) {}

    public addItem(comment: Readonly<CommentMedia>) {
        this.items$.value.push(comment);
        this.items$.next(this.items$.value);
    }

    public removeItem(comment: Readonly<CommentMedia>) {
        this.items$.next(this.items$.value.filter((item) => item !== comment));
    }

    public appCommentDismiss(role: string) {
        if (role === 'save') {
            this.startTesting();
        } else {
            this.dismiss();
        }
    }

    public dismiss() {
        this.startTestingService.cancelStartTesting();
    }

    public startTesting() {
        this.startTestingService.startTesting({
            sessionEvent: {
                id: uuidv4(),
                typeOfEvent: SessionEventTypeId.TESTING,
                comment: {
                    comment: this.form.get('comment')?.value,
                    mediaFiles: this.items$.value,
                    date: this.form.get('date')?.value
                },
                dateTimeUtc: new Date().getTime(),
                testIntervalMinutes: this.form.get('interval')?.value,
                isStart: true,
                duration:
                    this.form.get('interval')?.value &&
                    this.form.get('interval')?.value > 0
                        ? this.form.get('interval')?.value * 60
                        : undefined
            }
        });
    }

    public trackBy = (index: number) => index;

    public ngOnInit(): void {
        this.form = new FormGroup({
            eventOption: new FormControl(''),
            comment: new FormControl(),
            date: new FormControl(this.date, Validators.required),
            interval: new FormControl(0, Validators.required)
        });
    }

    protected readonly SessionEventTypeId = SessionEventTypeId;
    protected readonly SESSION_EVENT_TYPES = SESSION_EVENT_TYPES;
}
