import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'msToUser'
})
export class MsToUserPipe implements PipeTransform {
    transform(milliseconds: number | null) {
        if (!milliseconds) {
            return '00:00';
        }
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;

        return `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
    }

    private padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }
}
