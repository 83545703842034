import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class WizardService {
    public readonly goToStep$: Observable<unknown>;
    public readonly finish$: Observable<unknown>;

    private steps: ReadonlyArray<unknown>;
    private _current: unknown;
    private _currentIndex = 0;
    private readonly _goToStep$ = new Subject<void>();
    private readonly _finish$ = new Subject<void>();

    constructor(private readonly modalController: ModalController) {
        this.goToStep$ = this._goToStep$.asObservable();
        this.finish$ = this._finish$.asObservable();
    }

    public get current() {
        return this._current;
    }

    public get currentStep() {
        return this._currentIndex;
    }

    public initialize(steps: ReadonlyArray<unknown>) {
        this.steps = steps;
        if (!this.steps) {
            throw new Error('No steps provided for the wizard');
        }
        this._current = this.steps[this._currentIndex];
    }

    public next() {
        if (this._currentIndex + 1 >= this.steps.length) {
            this._finish$.next();
            return;
        }

        this._currentIndex += 1;
        this._current = this.steps[this._currentIndex];
        this._goToStep$.next();
    }

    public goToStep(step: number) {
        let index = step;

        if (index < 0) {
            index = 0;
        } else if (index > this.steps.length) {
            index = this.steps.length - 1;
        }

        this._currentIndex = index;
        this._current = this.steps[this._currentIndex];
    }

    public goBack() {
        if (this._currentIndex > 0) {
            this.goToStep(this._currentIndex - 1);
        } else {
            this.modalController.dismiss();
        }
    }
}
