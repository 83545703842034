import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    CommentMedia,
    DataService,
    SESSION_EVENT_TYPES,
    SessionEvent
} from 'src/app/shared/index';
import { AlertService } from '../../../../services/alert.service';
import { SessionStore } from '../../../session.store';
import { TestingEventsConstants } from '../common/testing-events-constants';
import { TestRunningService } from './test-running.service';

@Component({
    selector: 'app-start-testing',
    templateUrl: './test-running.page.html',
    styleUrls: ['./test-running.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestRunningPage implements OnInit, OnDestroy {
    @Input()
    public testRunningService: TestRunningService;
    @Input()
    public testingEvent: SessionEvent;

    public items$: BehaviorSubject<Array<CommentMedia>> = new BehaviorSubject(
        []
    );
    public form: FormGroup;
    public testingStopped = false;
    public testingStoppedTime: number;

    public durations = TestingEventsConstants.DURATIONS;

    private readonly componentDestroyed$ = new Subject<void>();

    private eventWasChanged = false;

    constructor(
        public readonly sessionStore: SessionStore,
        public readonly dataService: DataService,
        public readonly alertService: AlertService
    ) {}

    public addItem(comment: Readonly<CommentMedia>) {
        this.eventWasChanged = true;
        this.items$.value.push(comment);
        this.items$.next(this.items$.value);
    }

    public removeItem(comment: Readonly<CommentMedia>) {
        this.eventWasChanged = true;
        this.items$.next(this.items$.value.filter((item) => item !== comment));
    }

    public async dismissFromAppComment(role: string) {
        if (this.testingStopped) {
            await this.cancelTesting();
        } else {
            this.editTestingEvent();
        }
    }

    public trackBy = (index: number) => index;

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public ngOnInit(): void {
        this.form = new FormGroup({
            eventOption: new FormControl(''),
            comment: new FormControl(this.testingEvent?.comment?.comment ?? ''),
            date: new FormControl(
                this.testingEvent?.dateTimeUtc,
                Validators.required
            ),
            interval: new FormControl(
                this.testingEvent?.testIntervalMinutes ?? 0,
                Validators.required
            )
        });

        this.eventWasChanged = false;

        this.items$.next(this.testingEvent.comment.mediaFiles);

        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
                this.eventWasChanged = true;
            });
    }

    public editTestingEvent() {
        this.testRunningService.editTestingEvent({
            sessionEvent: {
                ...this.testingEvent,
                comment: {
                    comment: this.form.get('comment')?.value,
                    mediaFiles: this.items$.value,
                    date: this.form.get('date')?.value
                },
                testIntervalMinutes: this.form.get('interval')?.value,
                duration: this.calculateDuration()
            }
        });
    }

    sendStopTesting() {
        this.testRunningService.stopTesting({
            sessionEvent: {
                ...this.testingEvent,
                comment: {
                    comment: this.form.get('comment')?.value,
                    mediaFiles: this.items$.value,
                    date: this.form.get('date')?.value
                },
                testIntervalMinutes: this.form.get('interval')?.value,
                isStart: false,
                isStop: true,
                duration: this.calculateDuration()
            }
        });
    }

    protected readonly SESSION_EVENT_TYPES = SESSION_EVENT_TYPES;

    public calculateDuration() {
        let duration;
        if (this.testingStopped) {
            duration =
                (this.testingStoppedTime -
                    new Date(this.testingEvent.dateTimeUtc).getTime()) /
                1000;
        } else if (
            this.form.get('interval')?.value &&
            this.form.get('interval')?.value > 0
        ) {
            duration = this.form.get('interval')?.value * 60;
        } else {
            duration = this.testingEvent.duration;
        }
        return duration;
    }

    stop() {
        this.eventWasChanged = true;
        this.testingStopped = true;
        this.testingStoppedTime = new Date().getTime();
    }

    async cancelTesting() {
        const yes = 'YES';

        const result = await this.alertService.showAlert({
            title: 'Confirm discarding the test?',
            choices: [
                {
                    id: yes,
                    text: 'Yes'
                }
            ]
        });

        if (result === yes) {
            this.testRunningService.cancelTesting();
        }
    }

    get startTimeFormatted(): string {
        return new Date(
            this.testingEvent.dateTimeUtc as any
        ).toLocaleTimeString();
    }
}
