<ion-header translucent>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button button fill="clear" (click)="onDismiss()">
                <ion-icon name="close-outline" class="w-5"></ion-icon>
            </ion-button>
        </ion-buttons>
        <h4>{{ title }}</h4>
        <ion-buttons slot="end">
            <ion-button
                contextButton
                fill="clear"
                (click)="clearDismiss()"
                *ngIf="hasClear"
            >
                <ion-icon name="trash-outline" class="w-5"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <ion-list>
        <ion-item
            class="h-18"
            [ngClass]="button.css || ''"
            *ngFor="let button of list; trackBy: trackBy"
            (click)="onSelect(button)"
        >
            <app-images
                [image]="button.icon"
                *ngIf="button.icon"
                class="mr-4 svg-20"
            ></app-images>
            {{ button.name || button.id }}</ion-item
        >
    </ion-list>
</ion-content>
