import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    takeUntil,
    tap
} from 'rxjs/operators';
import { Property, PropertyType, valueNotSet } from 'src/app/shared';
import { ModalListItem } from 'src/app/shared/models/modelList.model';
import { DataService } from 'src/app/shared/services/data.service';
import { TYPE_OF_PROPERTY } from '../../../data/TypeOfProperty';
import { PropertyForm } from '../../../services/boats/properties/property-form';

@Component({
    selector: 'app-property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyComponent implements OnInit, OnDestroy {
    @Input() public form: FormGroup<PropertyForm>;
    @Input() public allProperties$: Observable<Readonly<Property[]>>;
    @Input() public edit: boolean = false;
    @Input() public originalProperty?: Property;

    @Output() public readonly saveProperty = new EventEmitter();

    public hasErrorsSubject$ = new BehaviorSubject<boolean>(true);
    public hasErrors$ = this.hasErrorsSubject$.asObservable();
    public errors$ = new BehaviorSubject<string[]>([]);
    public listSubject$ = new ReplaySubject<ModalListItem[]>(1);
    public list$ = this.listSubject$.asObservable();
    public valueNotSet = valueNotSet;
    public formValues: FormArray<FormControl<string>>;
    private readonly componentDestroyed$ = new Subject<void>();
    private allProperties: readonly Property[];

    constructor(
        public readonly dataService: DataService,
        public readonly formBuilder: FormBuilder
    ) {}

    public addValue(event) {
        if (
            event.target.value !== '' &&
            !this.formValues.value.includes(event.target.value)
        ) {
            const formControl = new FormControl<string>(event.target.value);
            this.formValues.push(formControl);
            event.target.value = '';
        }
    }

    removeFormValue(index: number) {
        this.formValues.at(index).value;
        this.formValues.removeAt(index);
    }

    public ngOnInit(): void {
        this.allProperties$.subscribe((allProperties) => {
            this.allProperties = allProperties;
        });

        this.formValues = this.form.controls.propertyValues;
        this.checkErrorState();

        this.listSubject$.next(
            this.formValues.value.map(
                (x) => ({ id: x, name: x } as ModalListItem)
            )
        );

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                distinctUntilChanged(
                    (first, second) =>
                        JSON.stringify(first) === JSON.stringify(second)
                ),
                debounceTime(200),
                tap(() => {
                    if (
                        this.form.value.typeOfProperty ===
                        PropertyType.LIST_OF_VALUES
                    ) {
                        this.listSubject$.next(
                            this.formValues.value.map(
                                (x) => ({ id: x, name: x } as ModalListItem)
                            )
                        );
                    }
                    this.checkErrorState();
                })
            )
            .subscribe();
    }

    private checkErrorState() {
        const prop = this.form.value;
        const errors = [];
        const noPropertyValueSet =
            prop.typeOfProperty === PropertyType.LIST_OF_VALUES &&
            prop.propertyValues.length < 1;
        if (noPropertyValueSet) {
            errors.push('At least one value needs to be added.');
        }
        const minBoundNotLowerThanmaxBound =
            prop.typeOfProperty === PropertyType.NUMBER &&
            (prop.minBound ?? 0) >= (prop.maxBound ?? 0);
        if (minBoundNotLowerThanmaxBound) {
            errors.push('Min bound needs to be lower than max bound.');
        }

        let propertyNameTaken = false;

        const nameExistsInPropertyArray = !!this.allProperties.find(
            (property) =>
                property.propertyName.toUpperCase() ===
                this.form.controls.propertyName.value?.toUpperCase()
        );

        if (
            nameExistsInPropertyArray &&
            !(
                this.edit &&
                this.originalProperty?.propertyName?.toUpperCase() ===
                    this.form.controls.propertyName.value?.toUpperCase()
            )
        ) {
            propertyNameTaken = true;
        }
        if (propertyNameTaken) {
            errors.push('The name of the property is already in use.');
        }

        const hasError =
            !this.form.valid ||
            noPropertyValueSet ||
            minBoundNotLowerThanmaxBound ||
            propertyNameTaken;
        this.hasErrorsSubject$.next(hasError);
        this.errors$.next(errors);
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    checkDuplicate(value: string, index: number) {
        const indexToRemove = this.formValues.value.findIndex(
            (thisValue, thisIndex) => thisValue === value && thisIndex !== index
        );

        if (indexToRemove !== -1) {
            this.formValues.removeAt(indexToRemove);
        }
    }

    isPropertyTypeNumber(): boolean {
        return this.form.controls.typeOfProperty.value === PropertyType.NUMBER;
    }

    isPropertyTypeString(): boolean {
        return (
            this.form.controls.typeOfProperty.value ===
            PropertyType.LIST_OF_VALUES
        );
    }

    protected readonly TYPE_OF_PROPERTY = TYPE_OF_PROPERTY;
}
