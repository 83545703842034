export const TYPE_OF_SAILING = [
    {
        id: 1,
        name: 'Racer'
    },
    {
        id: 2,
        name: 'Cruiser/racer'
    },
    {
        id: 3,
        name: 'Cruiser'
    }
];

export type SailingType = 'Racer' | 'Cruiser/racer' | 'Cruiser';

const sailingTypeLookup: Record<number, SailingType> = {};
TYPE_OF_SAILING.forEach((sailing) => {
    sailingTypeLookup[sailing.id] = sailing.name as SailingType;
});

// Usage example:
export const mapNumberToSailingType = (
    typeNumber: number
): SailingType | undefined => sailingTypeLookup[typeNumber];
