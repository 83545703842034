import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
    constructor(private readonly sanitizer: DomSanitizer) {}

    public transform(fileInput: string | null) {
        if (!fileInput) {
            return null;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(fileInput);
    }
}
