<div class="ion-page" *ngIf="formValues">
    <ng-content select="ion-header"></ng-content>

    <ion-row
        *ngIf="
            form.errors?.incrementMin ||
            form.errors?.incrementRequired ||
            (errors$ | async).length > 0
        "
    >
        <app-error-box>
            <span
                *ngIf="
                    form.errors?.incrementMin || form.errors?.incrementRequired
                "
            >
                Step needs to be set and above zero.
            </span>
            <span *ngFor="let error of errors$ | async">
                {{ error }}
            </span>
        </app-error-box>
    </ion-row>

    <ion-content [formGroup]="form" class="white">
        <app-input-text
            formControlName="propertyName"
            title="Property name"
            type="text"
        ></app-input-text>

        <ion-item lines="none">
            <ion-checkbox
                formControlName="canBeChangedWhileSailing"
                slot="start"
                color="primary"
            ></ion-checkbox>
            <ion-label>Can change while sailing</ion-label>
        </ion-item>

        <app-input-select
            formControlName="typeOfProperty"
            title="Type"
            [list]="TYPE_OF_PROPERTY | listItem"
        ></app-input-select>

        <ng-container *ngIf="isPropertyTypeString()">
            <ion-item fill="outline" class="my-5">
                <ion-label position="stacked">Property values</ion-label>
                <ion-list class="bg-transparent w-full">
                    <app-editable-item
                        *ngFor="
                            let control of formValues.controls;
                            let index = index
                        "
                        [control]="control"
                        (editingDone)="checkDuplicate(control.value, index)"
                        (delete)="removeFormValue(index)"
                    >
                    </app-editable-item>
                </ion-list>

                <ion-input
                    placeholder="Add a value..."
                    type="text"
                    inputmode="text"
                    (keyup.enter)="addValue($event)"
                    enterkeyhint="done"
                ></ion-input>
            </ion-item>

            <app-input-select
                formControlName="valueDefault"
                [listChanged$]="list$"
                title="Default"
                [list]="(list$ | async) || undefined"
                [setValueNotSet]="
                    form.controls.valueDefault.value === null ||
                    form.controls.valueDefault.value === valueNotSet
                "
            >
            </app-input-select>
        </ng-container>

        <ng-container *ngIf="isPropertyTypeNumber()">
            <div class="mt-5 grid grid-cols-3">
                <app-input-text
                    formControlName="minBound"
                    title="Min"
                    type="number"
                    inputmode="text"
                    class="mr-2.5"
                ></app-input-text>
                <app-input-text
                    formControlName="increment"
                    title="Step"
                    type="number"
                    inputmode="text"
                    class="mr-2.5"
                    min="1"
                ></app-input-text>
                <app-input-text
                    formControlName="maxBound"
                    title="Max"
                    type="number"
                    inputmode="text"
                    class="mr-2.5"
                ></app-input-text>
            </div>

            <app-input-text
                formControlName="valueDefault"
                title="Default"
                type="number"
                [convertNumberToString]="true"
                inputmode="numeric"
                [setValueNotSet]="true"
            ></app-input-text>
        </ng-container>
    </ion-content>

    <ion-footer>
        <ion-button
            expand="full"
            shape="round"
            (click)="saveProperty.emit(form)"
            [disabled]="hasErrors$ | async"
            >Save property
        </ion-button>
    </ion-footer>
</div>
