import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance, isDate, parseISO } from 'date-fns';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    public transform<T>(
        values: ReadonlyArray<T> | null,
        prop: keyof T,
        value: unknown
    ) {
        return values?.filter((item) => item[prop] === value);
    }
}

@Pipe({
    name: 'find'
})
export class FindPipe implements PipeTransform {
    public transform<T>(
        values: ReadonlyArray<T> | null,
        prop: keyof T,
        value: unknown
    ) {
        return values?.find((item) => item[prop] === value);
    }
}

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
    public transform(input: string | Date | number) {
        if (typeof input === 'number') {
            input = new Date(input);
        }

        const date = isDate(input)
            ? (input as Date)
            : parseISO(input as string);

        return formatDistance(date, new Date());
    }
}
