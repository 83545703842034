import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxdModule } from '@ngxd/core';
import { FilterPipe, FindPipe, TimeAgoPipe } from './pipes/FilterPipe';
import { HasPipe, JoinPipe, LengthPipe } from './pipes/has.pipe';
import { LookupPipe } from './pipes/LookupPipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { FirstPipe } from './pipes/first.pipe';
import { ListItemPipe } from './pipes/ListItem';

@NgModule({
    declarations: [
        SafeUrlPipe,
        HasPipe,
        JoinPipe,
        TimeAgoPipe,
        LookupPipe,
        FilterPipe,
        FindPipe,
        LengthPipe,
        FirstPipe,
        ListItemPipe
    ],
    imports: [CommonModule, NgxdModule],
    exports: [
        NgxdModule,
        SafeUrlPipe,
        HasPipe,
        JoinPipe,
        TimeAgoPipe,
        LookupPipe,
        FilterPipe,
        FindPipe,
        LengthPipe,
        FirstPipe,
        ListItemPipe
    ]
})
export class SharedModule {}
