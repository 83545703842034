<app-layout [border]="false">
    <ion-button button [routerLink]="backButtonLink">
        <ion-icon name="arrow-back-outline" color="dark"></ion-icon>
    </ion-button>
    <ion-label
        toolbarContent
        class="inline-block w-full text-center ml-[-45px]"
        >{{ markdownData?.title }}</ion-label
    >
    <app-internal-markdown [markdownData]="markdownData"></app-internal-markdown>
</app-layout>
