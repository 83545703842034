import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ImageEnum, Session, SessionEvent } from 'src/app/shared';

@Component({
    selector: 'app-event-overview-item',
    templateUrl: './event-overview-item.component.html',
    styleUrls: ['./event-overview-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventOverviewItemComponent {
    @Input() public imageSource: ImageEnum;
    @Input() public title: string;
    @Input() public event: SessionEvent;
    @Input() public session: Session | null | undefined;
    @Input() public showComment = true;
    @Output() public readonly openModal = new EventEmitter();

    constructor() {}

    public trackBy = (index: number) => index;
}
