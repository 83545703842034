import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lookup'
})
export class LookupPipe implements PipeTransform {
    public transform<T>(
        items: ReadonlyArray<T> | null,
        prop: keyof T,
        select: keyof T,
        value: unknown
    ) {
        return items?.find((item) => item[prop] === value)?.[select];
    }
}
