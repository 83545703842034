export const TYPE_OF_BOAT = [
    {
        id: 1,
        name: 'Monohull'
    },
    {
        id: 2,
        name: 'Fast planning monohull'
    },
    {
        id: 3,
        name: 'Foiling monohull'
    },
    {
        id: 4,
        name: 'Classic boat'
    },
    {
        id: 5,
        name: 'Catamaran'
    },
    {
        id: 6,
        name: 'Foiling catamaran'
    },
    {
        id: 7,
        name: 'Trimaran'
    },
    {
        id: 8,
        name: 'Foiling trimaran'
    },
    {
        id: 9,
        name: 'Other'
    }
];

export type BoatType =
    | 'Monohull'
    | 'Fast planning monohull'
    | 'Foiling monohull'
    | 'Classic boat'
    | 'Catamaran'
    | 'Foiling catamaran'
    | 'Trimaran'
    | 'Foiling trimaran'
    | 'Other';

const boatTypeLookup: Record<number, BoatType> = {};
TYPE_OF_BOAT.forEach((boat) => {
    boatTypeLookup[boat.id] = boat.name as BoatType;
});

// Usage example:
export const mapNumberToBoatType = (
    typeOfBoatNumber: number
): BoatType | undefined => boatTypeLookup[typeOfBoatNumber];
