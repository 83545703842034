import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BoatStore } from 'src/app/my-boats/current-boat-store.service';
import { SessionStore } from 'src/app/sessions/session.store';
import {
    Property,
    SessionEvent,
    SessionEventTypeId,
    valueNotSet
} from 'src/app/shared';
import { v4 as uuidv4 } from 'uuid';
import { PropertyForm } from '../../../services/boats/properties/property-form';
import { PropertyService } from '../../../services/boats/properties/property.service';

@Component({
    selector: 'app-event-change-properties',
    templateUrl: './event-change-properties.page.html',
    styleUrls: ['./event-change-properties.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventChangePropertiesPage {
    public date = new Date();

    public sessionChangeableProperties = this.sessionStore.form
        .getRawValue()
        .changeableProperties.sort(
            (a, b) =>
                a.propertyName.charCodeAt(0) - b.propertyName.charCodeAt(0)
        );

    public sessionChangeablePropertyForms: FormGroup<PropertyForm>[] =
        this.sessionChangeableProperties.map((prop) =>
            this.propertyService.propertyToFormGroup(prop, false)
        );

    public valueNotSet = valueNotSet;

    constructor(
        public readonly modalController: ModalController,
        public readonly sessionStore: SessionStore,
        private readonly propertyService: PropertyService,
        public readonly myBoatsStore: BoatStore
    ) {}

    saveProperties() {
        const sessionEvents: SessionEvent[] = [];

        const initialProperties =
            this.sessionStore.form.getRawValue().changeableProperties;
        this.sessionChangeablePropertyForms.forEach((propForm) => {
            const initialProp = initialProperties.find(
                (property) =>
                    property.propertyName === propForm.value.propertyName
            );
            if (
                JSON.stringify(initialProp) !==
                JSON.stringify(propForm.getRawValue())
            ) {
                sessionEvents.push(this.toSessionEvent(propForm.getRawValue()));
            }
        });

        if (sessionEvents.length > 0) {
            this.modalController.dismiss(sessionEvents, 'save');
        } else {
            this.modalController.dismiss();
        }
    }

    private toSessionEvent(rawValue: Property): SessionEvent {
        return {
            comment: null,
            dateTimeUtc: this.date.getTime(),
            id: uuidv4(),
            property: rawValue,
            typeOfEvent: SessionEventTypeId.PROPERTY
        };
    }
}
