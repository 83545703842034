import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import { WizardService } from '../../../services/wizard.service';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss'],
    providers: [WizardService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardComponent implements AfterViewInit, OnDestroy {
    @Input() steps: ReadonlyArray<unknown>;
    @Input() helpButtons: ReadonlyArray<DataMarkdownEnum>;
    @Input() border: boolean;
    @Input() selectedStep = 0;

    private readonly componentDestroyed$ = new Subject<void>();
    public helpType = DataMarkdownEnum;

    constructor(
        public readonly viewController: ModalController,
        public readonly wizardService: WizardService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.wizardService.finish$.subscribe(() => {
            this.viewController.dismiss();
        });
    }

    public ngAfterViewInit(): void {
        this.wizardService.initialize(this.steps);
        if (this.selectedStep !== null && this.selectedStep !== 0) {
            this.wizardService.goToStep(this.selectedStep);
        }
        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy() {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }
}
