<ion-list>
    <ion-list-header>{{ title }}</ion-list-header>
    <ion-item
        (click)="toggleItem(item.value)"
        *ngFor="let item of list$ | async"
    >
        <ion-label class="ion-text-wrap">{{ item.displayValue }}</ion-label>
        <ion-checkbox
            slot="end"
            [checked]="isItemChecked(item.value)"
        ></ion-checkbox>
    </ion-item>
</ion-list>
