<div class="flex flex-col justify-start h-full">
    <app-layout-header
        title="Great!"
        subTitle="You are about to create a new sailing session. Please review the settings below and click Save"
        [imageSource]="imageEnum.thumbUp"
    ></app-layout-header>
    <app-session-overview
        class="h-full"
        [session]="getSession()"
        [boat]="sessionStore.currentBoat$ | async"
        (goToPage)="editStep($event)"
        (editStep)="editStep($event)"
    >
    </app-session-overview>
</div>
