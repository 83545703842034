import { FormControl } from '@angular/forms';
import { Identifier } from './general.model';
import { Property } from './property.model';
import { SessionEvent } from './sessionEvent.model';

export interface Session extends Identifier {
    // Id of the boat
    boat: string;
    name: string;
    startDate: number;
    endDate?: number;
    location: string;
    typeOfSession: SessionType[];
    group: string;
    // Ids of the sail
    sails: string[];
    staticProperties: Property[];
    changeableProperties: Property[];
    events: SessionEvent[];
    raceType: SessionCurrentRaceTypeEnum;
    raceNumber?: number;
    raceTimerStartedAt: number | null;
    testTimerStartedAt: number | null;
    testInterval: number;
    raceInterval: number;
    status: StatusEnum;
    currentMark: number;
    paused: boolean;
}

export enum SessionType {
    TRAINING = 'TRAINING',
    RACING = 'RACING',
    INSHORE = 'INSHORE',
    COASTAL = 'COASTAL',
    OFFSHORE = 'OFFSHORE',
    WINWARD_LEEWARD = 'WINWARD_LEEWARD',
    OTHER = 'OTHER'
}

export const SESSION_TYPES: Readonly<{
    [key in keyof typeof SessionType]: string;
}> = {
    TRAINING: 'Training',
    RACING: 'Racing',
    INSHORE: 'Inshore',
    COASTAL: 'Coastal',
    OFFSHORE: 'Offshore',
    WINWARD_LEEWARD: 'Winward/Leeward',
    OTHER: 'Other'
};

export interface SessionTypeWithDisplayValue {
    value: SessionType;
    displayValue: string;
}

export const SESSION_TYPES_LIST: ReadonlyArray<SessionTypeWithDisplayValue> =
    Object.keys(SESSION_TYPES).map((key: SessionType) => ({
        value: key,
        displayValue: SESSION_TYPES[key]
    }));

export interface SailsUpFavoriteForm {
    id: FormControl<string>;
    name?: FormControl<string>;
    color: FormControl<string>;
}

export enum SessionCurrentRaceTypeEnum {
    none = 'None',
    test = 'Test',
    practice = 'Practice',
    race = 'Race'
}

export enum StatusEnum {
    planned = 'Planned',
    active = 'Active',
    past = 'Past'
}
