export const TYPE_OF_PROPERTY = [
    {
        id: 'LIST_OF_VALUES',
        name: 'List of values'
    },
    {
        id: 'NUMBER',
        name: 'Number'
    }
];
