import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    SessionCurrentRaceTypeEnum,
    SESSION_EVENT_TYPES,
    SessionEvent, EventRaceType, SessionEventTypeId
} from '../../../shared/index';
import { SessionStore } from '../../session.store';
import { RaceTimerService } from './race-timer.service';

@Component({
    selector: 'app-race-timer',
    templateUrl: './race-timer.component.html',
    styleUrls: ['./race-timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RaceTimerComponent implements OnDestroy {
    @Input()
    public clickable = true;

    @Output()
    public timerClicked: EventEmitter<void> = new EventEmitter();

    @Output()
    public markPassed: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public timingChanged: EventEmitter<void> = new EventEmitter<void>();

    public RaceTypeEnum = EventRaceType;

    private readonly componentDestroyed$ = new Subject<void>();

    constructor(
        public readonly raceTimerService: RaceTimerService,
        public readonly sessionStore: SessionStore
    ) {
        raceTimerService.timingChanged$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
                this.timingChanged.next();
            });
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    get currentSession$() {
        return this.sessionStore.currentSession$;
    }

    get currentTimer$() {
        return this.raceTimerService.currentTimer$;
    }

    get raceEvent(): SessionEvent {
        return this.raceTimerService.raceEvent;
    }

    protected readonly SESSION_EVENT_TYPES = SESSION_EVENT_TYPES;

    get showTimer(): boolean {
        return !!this.raceEvent && !!this.raceTimerService.currentTimer$;
    }

    protected readonly SessionEventTypeId = SessionEventTypeId;
}
