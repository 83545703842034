<app-layout>
    <h4 toolbarContent>Export sessions</h4>
    <ion-button
        button
        fill="clear"
        (click)="modalController.dismiss(null, 'cancel')"
    >
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <app-help contextButton [helpType]="helpType.helpExportSession"></app-help>
    <ion-icon size="large" name="cloud-upload-outline"></ion-icon>
    <p class="font-[590] text-[28px] leading-[33px]">Export sessions</p>
    <span class="secondary"
        >Please select the sailing sessions you'd like to export.</span
    >
    <ng-container *ngIf="checkedItems$ | async as checkedItems">
        <section class="sessions mt-[30px]">
            <ng-container
                *ngFor="let session of dataService.sessions$ | async; trackBy: trackSession"
            >
                <ion-item (click)="checkItem(session)">
                    <ion-checkbox slot="start" color="primary"></ion-checkbox>
                    <ion-label>{{ session.name + " ("+ getBoatName(session) + ")"}}</ion-label>
                </ion-item>
            </ng-container>
        </section>
    </ng-container>
    <ion-footer>
        <ion-button
            shape="round"
            expand="full"
            (click)="exportSessions()"
            [disabled]="isEnabled$ | async"
            >Export sessions</ion-button
        >
    </ion-footer>
</app-layout>
