import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController, PickerController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { parseISO } from 'date-fns';
import { Observable, from } from 'rxjs';
import {
    filter,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CommentService } from '../../../services/comment.service';
import {
    dateStringAsDate,
    SessionEventTypeId,
    filterEmpty,
    SessionCurrentRaceTypeEnum,
    timeElapsed
} from '../../../shared';
import { SessionEvent } from '../../../shared/models/sessionEvent.model';
import { SessionComponentState } from '../../models';
import { SessionStore } from '../../session.store';

@Component({
    selector: 'app-edit-race-page',
    templateUrl: './edit-race.page.html',
    styleUrls: ['./edit-race.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRacePageComponent extends ComponentStore<
    SessionComponentState & { currentMark: number }
> {
    private readonly markEvents$ = this.sessionStore
        .select((x) => x.currentSession?.events)
        .pipe(
            filterEmpty(),
            withLatestFrom(this.sessionStore.select((x) => x.currentSession)),
            map(([events, currentSession]) =>
                events.filter(
                    (event) =>
                        event.raceNumber === currentSession?.raceNumber &&
                        (event.typeOfEvent === SessionEventTypeId.RACE_MARK ||
                            event.typeOfEvent ===
                                SessionEventTypeId.RACE_FINISH)
                )
            )
        );

    public raceEvent$ = this.sessionStore.lastRacingEvent$;

    public readonly date$ = this.select((x) =>
        x.sessionEvent?.dateTimeUtc
            ? new Date(x.sessionEvent.dateTimeUtc)
            : undefined
    );
    public readonly currentSession$ = this.sessionStore.select(
        (x) => x.currentSession
    );
    public readonly currentMark$ = this.currentSession$.pipe(
        map((session) => session?.currentMark),
        take(1),
        tap((currentMark) => {
            this.patchState({
                currentMark
            });
        }),
        switchMap(() => this.select((x) => x.currentMark))
    );

    public readonly clock$ = this.currentSession$.pipe(
        map((currentSession) => currentSession?.raceTimerStartedAt),
        filterEmpty(),
        switchMap((start) => timeElapsed(parseISO(String(start))))
    );

    public readonly totalMarks$ = this.sessionStore.currentlyRunningEditableEvent$.pipe(
        map((event) => event?.marks ?? 0),
        map((marks) => Array.from(Array(marks).keys())),
        withLatestFrom(this.markEvents$),
        map(([marks, markEvents]) =>
            marks.map((mark) => ({
                mark: mark + 1,
                event: markEvents.find(
                    (markEvent) => markEvent.mark === mark + 1
                )
            }))
        )
    );

    public readonly cancel = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(this.select((x) => x.sessionEvent)),
            tap(([, session]) =>
                this.modalController.dismiss(
                    {
                        ...session,
                        typeOfEvent: SessionEventTypeId.CANCELLED,
                        raceType: SessionCurrentRaceTypeEnum.none
                    } as SessionEvent,
                    'cancel'
                )
            )
        )
    );

    public readonly save = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(
                this.select((x) => x.sessionEvent),
                this.select((x) => x.currentMark)
            ),
            tap(([interval, session, currentMark]) =>
                this.modalController.dismiss(
                    {
                        ...session,
                        typeOfEvent: SessionEventTypeId.RACE_MARK,
                        raceIntervalMinutes: interval,
                        mark: currentMark
                    } as SessionEvent,
                    'save'
                )
            )
        )
    );

    public readonly editDate = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(this.select((x) => x.sessionEvent.dateTimeUtc)),
            switchMap(([, date]) =>
                from(this.commentService.changeDate(date)).pipe(
                    filter((result) => result.role === 'save'),
                    withLatestFrom(this.select((x) => x.sessionEvent)),
                    tap(([result, sessionEvent]) =>
                        this.patchState({
                            sessionEvent: {
                                ...sessionEvent,
                                dateTimeUtc: dateStringAsDate(
                                    result.data
                                ).getTime()
                            }
                        })
                    )
                )
            )
        )
    );

    public readonly addComment = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            switchMap(() =>
                this.commentService.openDefaultComment().pipe(
                    filterEmpty(),
                    withLatestFrom(this.select((x) => x.sessionEvent)),
                    tap(([result, sessionEvent]) =>
                        this.patchState({
                            sessionEvent: {
                                ...sessionEvent,
                                ...result
                            }
                        })
                    )
                )
            )
        )
    );

    constructor(
        public readonly sessionStore: SessionStore,
        public readonly modalController: ModalController,
        private readonly pickerController: PickerController,
        private readonly commentService: CommentService
    ) {
        super({
            currentMark: 0,
            sessionEvent: {
                id: uuidv4(),
                typeOfEvent: undefined,
                dateTimeUtc: new Date().getTime(),
                comment: {
                    comment: '',
                    mediaFiles: []
                }
            }
        });
    }

    public trackMarkFn(_index: number, event: Readonly<SessionEvent>) {
        return event.id;
    }

    public async editCurrentMark() {
        this.totalMarks$.pipe(take(1)).subscribe(async (marks) => {
            const picker = await this.pickerController.create({
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel'
                    },
                    {
                        text: 'Confirm',
                        role: 'save',
                        handler: (value) => {
                            this.patchState({
                                currentMark: value.Mark.value
                            });
                        }
                    }
                ],
                columns: [
                    {
                        name: 'Mark',
                        options: [
                            ...Array.from(Array(marks.length)).keys()
                        ].map((mark) => ({
                            text: String(mark + 1),
                            value: mark + 1
                        }))
                    }
                ]
            });

            await picker.present();
        });
    }

    practiceFinished() {
        this.sessionStore.markPassed(null);
        this.modalController.dismiss(null, 'cancel');
    }
}
