import { Injectable } from '@angular/core';
import { ImageEnum } from '../models';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    public readonly mainMenu = [
        {
            title: 'Sailing session',
            icon: ImageEnum.sailing,
            link: '/sessions'
        },
        {
            title: 'My boats',
            icon: ImageEnum.boats,
            link: '/my-boats'
        },
        {
            title: 'Settings',
            icon: ImageEnum.setting,
            link: '/settings'
        }
    ];

    public readonly settingsMenu = [
        {
            title: 'Profile',
            icon: ImageEnum.profile,
            link: '/settings/profile'
        },
        {
            title: 'Configuration',
            icon: ImageEnum.setting,
            link: '/settings/sailsettings'
        },
        {
            title: 'Notification',
            icon: ImageEnum.notification,
            link: '/settings/notification'
        },
        {
            title: 'FAQ',
            icon: ImageEnum.faq,
            link: '/settings/faq'
        },
        {
            title: 'Terms of service',
            icon: ImageEnum.tes,
            link: '/settings/termsofservice'
        },
        {
            title: 'Privacy policy',
            icon: ImageEnum.privacy,
            link: '/settings/privacypolicy'
        },
        {
            title: 'About',
            icon: ImageEnum.about,
            link: '/settings/about'
        }
    ];
}
