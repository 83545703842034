<div class="ion-page h-full">
    <ion-content ngClass="white">
        <app-layout-header
            title="Session configuration"
            subTitle="Set your session's user defined variables."
            [imageSource]="imageEnum.check"
        ></app-layout-header>
        <ion-list [formGroup]="form">
            <ng-container formArrayName="staticProperties">
                <ng-container
                    *ngFor="
                        let property of staticPropertiesForm.controls;
                        let propIndex = index
                    "
                    [formGroupName]="propIndex"
                >
                    <!--        NICE mita: ugly, change it to function in component-->
                    <ng-container
                        *ngIf="
                            property.value.typeOfProperty === 'LIST_OF_VALUES';
                            else number
                        "
                    >
                        <app-input-select
                            [list]="
                                property.value.propertyValues | listItem : true
                            "
                            formControlName="valueCurrent"
                            [title]="property.value.propertyName"
                        ></app-input-select>
                    </ng-container>

                    <ng-template #number>
                        <ion-item class="mb-5">
                            <div class="mb-2">
                                <ion-label position="stacked">
                                    {{ property.value.propertyName }}</ion-label
                                >
                            </div>
                            <ion-range
                                [ngClass]="
                                    isValueCurrentSet(property)
                                        ? 'visiblePin'
                                        : 'invisiblePin'
                                "
                                min="{{ property.value.minBound }}"
                                max="{{ property.value.maxBound }}"
                                step="{{ property.value.increment }}"
                                value="{{initialValues[property.value.id]}}"
                                (ionChange)="
                                    numberValueChange($event, property)
                                "
                                [pin]="true"
                                [pinFormatter]="formatNumber"
                            >
                                <ion-label slot="start">{{
                                    property.value.minBound
                                }}</ion-label>
                                <ion-label slot="end">{{
                                    property.value.maxBound
                                }}</ion-label>
                            </ion-range>
                        </ion-item>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ion-list>
    </ion-content>

    <ion-footer>
        <ion-button
            *ngIf="wizard"
            (click)="goToNextPage.emit()"
            shape="round"
            expand="full"
            [disabled]="hasErrors && staticPropertiesForm.controls.length > 0"
            >Continue
            <ion-icon class="pl-5" name="arrow-forward-outline"></ion-icon>
        </ion-button>
        <ion-button
            *ngIf="!wizard"
            (click)="modalController.dismiss('save')"
            shape="round"
            expand="full"
            [disabled]="hasErrors && staticPropertiesForm.controls.length > 0"
            >Save
            <ion-icon class="pl-5" name="arrow-forward-outline"></ion-icon>
        </ion-button>
    </ion-footer>
</div>
