import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./home/home.module').then((m) => m.HomePageModule)
    },
    {
        path: 'my-boats',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./my-boats/my-boats.module').then(
                (m) => m.MyBoatsPageModule
            )
    },
    {
        path: 'sessions',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./sessions/sessions.module').then(
                (m) => m.SessionsPageModule
            )
    },
    {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./settings/settings.module').then(
                (m) => m.SettingsPageModule
            )
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
