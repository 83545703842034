import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Markdown } from 'src/app/shared';

@Component({
    selector: 'app-markdown-modal',
    templateUrl: './markdown-modal.component.html',
    styleUrls: ['./markdown-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownModalComponent {
    public markdown: Markdown | undefined;

    constructor(public readonly modalController: ModalController) {}
}
