export class TestingEventsConstants {
    public static readonly DURATIONS = [
        {
            id: 0,
            name: 'Manually'
        },
        {
            id: 3,
            name: 'In 3 min'
        },
        {
            id: 5,
            name: 'In 5 min'
        },
        {
            id: 10,
            name: 'In 10 min'
        },
        {
            id: 15,
            name: 'In 15 min'
        }
    ];
}
