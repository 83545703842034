<div [class.ion-invalid]="isInvalid">
    <ion-item fill="outline" mode="md">
        <ion-label position="stacked">{{
            title + (isRequired ? ' *' : '')
        }}</ion-label>
        <ion-input
            [disabled]="disabled"
            [value]="calculateValue()"
            [type]="type"
            (input)="valueChange($any($event.target).value)"
            (blur)="onTouched()"
            class="text-black"
            [placeholder]="setValueNotSet ? valueNotSet : ''"
            [inputmode]="inputmode"
            (keyup.enter)="this.editingDone.emit()"
            (focusout)="this.editingDone.emit()"
        ></ion-input>
    </ion-item>
</div>
