import { Injectable } from '@angular/core';
import { Boat, ImageEnum } from '../shared/index';

// NICE mita: Now we have two sailServices. Why do we have a shared folder with services and a services folder here?
@Injectable({
    providedIn: 'root'
})
export class SailService {
    constructor() {}

    getSailImageByGroupId(id: number) {
        switch (id) {
            case 1:
                return ImageEnum.mainsail;
            case 2:
                return ImageEnum.headsail;
            case 3:
                return ImageEnum.staysail;
            case 4:
                return ImageEnum.symmetricalSpinnaker;
            case 5:
                return ImageEnum.asymmetricalSpinnaker;
        }
        return ImageEnum.sail;
    }

    getSailsOfSession(session: { sails: string[] }, boat: Boat) {
        return (
            boat?.sails.filter((sail) => session.sails.includes(sail.id)) || []
        );
    }
}
