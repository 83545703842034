<app-property
    [form]="this.form"
    (saveProperty)="addProperty()"
    [allProperties$]="allProperties$"
    [edit]="edit"
    [originalProperty]="data"
    class="m-[20px] h-full relative"
>
    <ion-header class="white">
        <ion-toolbar>
            <ion-buttons slot="start" class="px-[7px]">
                <ion-button
                    button
                    fill="clear"
                    (click)="modalController.dismiss(null)"
                >
                    <ion-icon name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
            <h4 toolbarContent>{{ title }} property</h4>
            <ion-buttons slot="end"></ion-buttons>
        </ion-toolbar>
    </ion-header>
</app-property>
