<ng-container [formGroup]="propertyForm">
    <ion-card>
        <ion-card-header>
            <ion-item lines="none">
                <ion-label
                    >{{
                        propertyForm.controls.propertyName.value
                    }}
                    is</ion-label
                >
                <ion-toggle formControlName="propertyEnabled"></ion-toggle>
            </ion-item>
            <h1>
                {{ readableValue(propertyForm.controls.valueCurrent.value) }}
            </h1>
        </ion-card-header>
        <!--            NICE mita: ugly, change to function in component-->
        <ion-card-content>
            <ion-range
                *ngIf="
                    propertyForm.controls.typeOfProperty.value === 'NUMBER';
                    else list
                "
                [min]="propertyForm.controls.minBound.value"
                [max]="propertyForm.controls.maxBound.value"
                [step]="propertyForm.controls.increment.value"
                formControlName="valueCurrent"
            >
                <ion-label slot="start">{{
                    propertyForm.controls.minBound.value
                }}</ion-label>
                <ion-label slot="end">{{
                    propertyForm.controls.maxBound.value
                }}</ion-label>
            </ion-range>
            <ng-template #list>
                <ion-radio-group formControlName="valueCurrent">
                    <ion-item
                        *ngFor="
                            let value of propertyForm.controls.propertyValues
                                .value
                        "
                    >
                        <ion-radio [value]="value"></ion-radio>
                        <ion-label class="ml-4">{{ value }}</ion-label>
                    </ion-item>
                </ion-radio-group>
            </ng-template>
        </ion-card-content>
    </ion-card>
</ng-container>
