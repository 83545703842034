import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
    debounceTime,
    distinctUntilChanged,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { WizardComponent } from '../components/components/wizard/wizard.component';
import { PropertyForm } from '../services/boats/properties/property-form';
import { PropertyService } from '../services/boats/properties/property.service';
import { Boat, BoatFormGroup, Property, Sail, SailForm } from '../shared';
import { DataMarkdownEnum } from '../shared/models/data.model';
import { DataService } from '../shared/services/data.service';
import { ConfigurationPage } from './pages/boat-configuration/configuration-page.component';
import { BoatDetailsPage } from './pages/boat-details/boat-details.page';
import { ConfigurationOverviewPage } from './pages/configuration-overview/configuration-overview.page';
import { SailInventoryPage } from './pages/sail-inventory/sail-inventory.page';

export interface MyBoatsState {
    currentBoat?: Boat;
}

@Injectable({
    providedIn: 'root'
})
export class BoatStore
    extends ComponentStore<MyBoatsState>
    implements OnDestroy
{
    public readonly forms: {
        boatDetail: FormGroup<BoatFormGroup>;
    };
    public readonly sailsFormArray: FormArray<FormGroup<SailForm>>;
    public readonly boatPropertyArray: FormArray;

    public detailHasErrorsSubject$ = new BehaviorSubject<boolean>(true);
    public detailHasErrors$: Observable<boolean> =
        this.detailHasErrorsSubject$.asObservable();
    public sailHasErrorsSubject$ = new BehaviorSubject<boolean>(true);
    public sailHasErrors$: Observable<boolean> =
        this.sailHasErrorsSubject$.asObservable();
    public boatPropHasErrorsSubject$ = new BehaviorSubject<boolean>(true);
    public boatSailsSubject$ = new BehaviorSubject<ReadonlyArray<Sail>>([]);
    public boatSails$ = this.boatSailsSubject$.asObservable();
    public boatPropertiesSubject$ = new BehaviorSubject<
        ReadonlyArray<Property>
    >([]);
    public boatProperties$ = this.boatPropertiesSubject$.asObservable();

    constructor(
        private readonly dataService: DataService,
        private readonly modalController: ModalController,
        private readonly formBuilder: FormBuilder,
        private readonly propertyService: PropertyService
    ) {
        super({});

        const boatDetailForm = this.formBuilder.group<BoatFormGroup>({
            id: this.formBuilder.control(null),
            name: this.formBuilder.nonNullable.control(null, [
                Validators.required
            ]),
            typeOfBoat: this.formBuilder.nonNullable.control(null, [
                Validators.required
            ]),
            numberOfMasts: this.formBuilder.nonNullable.control(1, [
                Validators.required
            ]),
            length_ft: this.formBuilder.nonNullable.control(0, [
                Validators.required,
                Validators.min(0)
            ]),
            displacement: this.formBuilder.control(null),
            typeOfSailing: this.formBuilder.control(null),
            canPlane: this.formBuilder.control(null),
            canFoil: this.formBuilder.control(null),
            canAsymmetricalSails: this.formBuilder.control(null),
            upwind: this.formBuilder.control(null),
            downwind: this.formBuilder.control(null),
            sails: this.formBuilder.array<Sail>([]),
            properties: this.formBuilder.array<Property>([]),
            image: this.formBuilder.control(null),
            sailUpFavorites: this.formBuilder.control([])
        });

        boatDetailForm.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                distinctUntilChanged(),
                debounceTime(200),

                tap(() => {
                    this.detailHasErrorsSubject$.next(
                        !(
                            boatDetailForm.get('name')?.valid &&
                            boatDetailForm.get('typeOfBoat')?.valid &&
                            boatDetailForm.get('numberOfMasts')?.valid &&
                            boatDetailForm.get('length_ft')?.valid
                        )
                    );

                    this.sailHasErrorsSubject$.next(
                        (boatDetailForm.controls.sails as FormArray).controls
                            .length === 0
                    );

                    this.boatPropHasErrorsSubject$.next(
                        (boatDetailForm.controls.properties as FormArray)
                            .controls.length === 0
                    );

                    this.boatSailsSubject$.next(
                        (boatDetailForm.controls.sails as FormArray)?.value
                    );

                    this.boatPropertiesSubject$.next(
                        (boatDetailForm.controls.properties as FormArray)?.value
                    );
                })
            )
            .subscribe();

        this.forms = {
            boatDetail: boatDetailForm
        };

        this.sailsFormArray = this.forms.boatDetail.get('sails') as FormArray;

        this.boatPropertyArray = this.forms.boatDetail.get(
            'properties'
        ) as FormArray;
    }

    public async editBoat(boatId: string) {
        const boat = await this.dataService
            .getBoatById$(boatId)
            .pipe(take(1))
            .toPromise();

        this.patchState({
            currentBoat: boat
        });

        // Rehydrate forms
        this.hydrateWithBoat(boat);
        this.editBoatDetails();
    }

    private hydrateWithBoat(boat: Readonly<Boat>) {
        const { boatDetail } = this.forms;

        const sails = boatDetail.get('sails') as FormArray;
        sails.clear();
        if (boat.sails !== undefined) {
            boat.sails.forEach((sail) => {
                sails.push(this.formBuilder.group(sail));
            });
        }

        const properties = boatDetail.get('properties') as FormArray;
        properties.clear();
        if (boat.properties !== undefined) {
            boat.properties.forEach((property) => {
                const form: FormGroup<PropertyForm> =
                    this.propertyService.propertyToFormGroup(property);
                properties.push(form);
            });
        }

        boatDetail.reset();
        boatDetail.patchValue(boat);
    }

    public showBoatDetails = this.effect((trigger$) =>
        trigger$.pipe(
            tap(() => {
                this.forms.boatDetail.reset();
                const { boatDetail } = this.forms;
                const sails = boatDetail.get('sails') as FormArray;
                sails.clear();
                const properties = boatDetail.get('properties') as FormArray;
                properties.clear();
            }),
            switchMap(() => this.showBoatModal()),
            tap(() => this.saveBoat())
        )
    );

    private async editBoatDetails(): Promise<void> {
        await this.showBoatModal();
        this.saveBoat();
    }

    public readonly removeBoat = this.effect((trigger$: Observable<string>) =>
        trigger$.pipe(
            tap((boatId: string) => this.dataService.removeBoat(boatId))
        )
    );

    private readonly componentDestroyed$: Subject<void> = new Subject<void>();

    public addSailToBoat(sail: Sail) {
        const sailForm = this.formBuilder.group<SailForm>({
            id: this.formBuilder.control(sail.id, Validators.required),
            name: this.formBuilder.control(sail.name, Validators.required),
            reefs: this.formBuilder.control(sail.reefs, Validators.required),
            weight: this.formBuilder.control(sail.weight),
            typeOfSail: this.formBuilder.control(
                sail.typeOfSail,
                Validators.required
            )
        });
        this.sailsFormArray.push(sailForm);
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    private async showBoatModal(): Promise<void> {
        const steps = [
            BoatDetailsPage,
            SailInventoryPage,
            ConfigurationPage,
            ConfigurationOverviewPage
        ];
        const helpButtons = [
            DataMarkdownEnum.helpBoatDetail,
            DataMarkdownEnum.helpSailInventory,
            DataMarkdownEnum.helpBoatProperty,
            DataMarkdownEnum.helpBoatOverview
        ];

        const modal = await this.modalController.create({
            component: WizardComponent,
            componentProps: {
                steps,
                helpButtons,
                border: true
            }
        });
        await modal.present();
        await modal.onWillDismiss();
    }

    public saveBoat() {
        const { boatDetail } = this.forms;
        if (boatDetail.valid) {
            this.addDefaultImageToBoat(boatDetail);
            this.dataService.upsertBoat(boatDetail.getRawValue());
        }
    }

    private addDefaultImageToBoat(boatDetail: FormGroup<BoatFormGroup>) {
        let defaultImagePath;
        switch (boatDetail.controls.typeOfBoat.value) {
            case 1:
                defaultImagePath = 'assets/boats/monohull.svg';
                break;
            case 2:
                defaultImagePath = 'assets/boats/monohull.svg';
                break;
            case 3:
                defaultImagePath = 'assets/boats/foiling-monohull.svg';
                break;
            case 4:
                defaultImagePath = 'assets/boats/classic-boat.svg';
                break;
            case 5:
                defaultImagePath = 'assets/boats/catamaran-trimaran.svg';
                break;
            case 6:
                defaultImagePath = 'assets/boats/catamaran-trimaran.svg';
                break;
            case 7:
                defaultImagePath = 'assets/boats/catamaran-trimaran.svg';
                break;
            case 8:
                defaultImagePath = 'assets/boats/catamaran-trimaran.svg';
                break;
            case 9:
                defaultImagePath = 'assets/boats/classic-boat.svg';
                break;
        }

        const previousImage = boatDetail.controls.image.value;

        boatDetail.controls.image.setValue({
            ...previousImage,
            defaultImagePath: defaultImagePath
        });
    }

    setBoat(boat: Boat) {
        this.hydrateWithBoat(boat);
    }
}
