import { Injectable, Type } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { take, tap } from 'rxjs/operators';
import { DataService } from 'src/app/shared/index';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import { MarkdownModalComponent } from '../components/components/markdown-modal/markdown-modal.component';
import { ModalComponent } from '../components/components/modal/modal.component';
import { MARKDOWN } from '../data/Markdown';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(
        private readonly modalController: ModalController,
        private readonly dataService: DataService
    ) {}

    public dismiss<T>(data?: T, role?: string, id?: string) {
        return this.modalController.dismiss(data, role, id);
    }

    public async createBottomModal<
        T extends { id?: string; title: string; edit: boolean; data: Data },
        Data
    >(
        component: Type<T>,
        name: string,
        modalComponentProps?: { [key in keyof T]?: T[key] },
        cssClass?: string
    ) {
        const modal = await this.modalController.create({
            component: ModalComponent,
            componentProps: {
                component: component,
                props: modalComponentProps,
                title: 'Update'
            },
            cssClass: 'h-1/4 top-3/4 absolute ' + cssClass,
            showBackdrop: true
        });

        await modal.present();
        const data = await modal.onDidDismiss();
        return data;
    }

    public async createModal<T>(
        component: Type<T>,
        componentProps?: { [key in keyof T]?: T[key] },
        cssClass?: string
    ) {
        const modal = await this.modalController.create({
            component: component as any,
            componentProps,
            cssClass,
            showBackdrop: true,
            backdropDismiss: true
        });

        await modal.present();

        return modal;
    }

    public async createModalAndWaitForDismiss<T, ReturnValue = any>(
        component: Type<T>,
        componentProps?: { [key in keyof T]?: T[key] },
        cssClass?: string
    ) {
        const modal = await this.createModal(
            component,
            componentProps,
            cssClass
        );
        const data = await modal.onDidDismiss<ReturnValue>();
        return data;
    }

    public async createFullModal<T>(
        componentParam: Type<T>,
        editParam,
        titleParam,
        properties?: { [key in keyof T]?: T[key] }
    ) {
        const modal = await this.modalController.create({
            component: componentParam,
            componentProps: {
                ...properties,
                edit: editParam,
                title: titleParam
            }
        });

        await modal.present();

        return modal;
    }

    public async createFullModalAndWaitForDismiss<T>(
        componentParam: Type<T>,
        editParam,
        titleParam,
        properties?: { [key in keyof T]?: T[key] }
    ) {
        const modal = await this.createFullModal(
            componentParam,
            editParam,
            titleParam,
            properties
        );
        const data = await modal.onWillDismiss();
        return data.data;
    }

    public async createDialog(component: any, componentProps?: any) {
        const modal = await this.modalController.create({
            component,
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'modal',
            componentProps
        });

        await modal.present();
        const data = await modal.onWillDismiss();
        return data;
    }

    public async createMarkdown(markdownType: DataMarkdownEnum) {
        const markdown = MARKDOWN[markdownType];

        const modal = await this.modalController.create({
            component: MarkdownModalComponent,
            componentProps: { markdown }
        });

        await modal.present();
        const data = await modal.onWillDismiss();
        return data;
    }
}
