<app-layout class="h-full" [scrollY]="false">
    <h4 toolbarContent>Edit sails up event</h4>
    <ion-button button fill="clear" (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <div class="flex flex-col h-full">
        <app-sails-up
            class="h-full"
            [date]="dateTimeUtc$ | async"
            [sailsUp$]="sailsUp$"
            (dismiss)="dismiss()"
            (addComment)="editComment()"
            (editDate)="editDate()"
            (selectSails)="editEvent()"
            [isEdit]="true"
            [isFirstEvent$]="of(true)"
            (toggleSail)="toggleSail($event)"
            (changeReef)="changeReef($event)"
        ></app-sails-up>
    </div>
</app-layout>
