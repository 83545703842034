<app-layout [border]="true">
    <ion-button button fill="clear" (click)="modalController.dismiss(null)">
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <h4 toolbarContent>User var</h4>

    <ng-container *ngFor="let propertyForm of sessionChangeablePropertyForms">
        <app-edit-property-base [propertyForm]="propertyForm">
        </app-edit-property-base>
    </ng-container>

    <ion-footer>
        <ion-button shape="round" expand="full" (click)="saveProperties()">
            Change properties
        </ion-button>
    </ion-footer>
</app-layout>
