import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent {
    @Input() icon: string;
    @Input() title: string;
    @Input() text: string;
    @Input() buttons: any[];

    constructor(public readonly modalController: ModalController) {}
    public trackBy = (index: number) => index;
}
