import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'app-comment-audio',
    templateUrl: './comment-audio.component.html',
    styleUrls: ['./comment-audio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentAudioComponent {
    @Input() public hasRecording = false;
    @Input() public isRecording = false;
    @Input() public time: string;

    @Output() public readonly dismiss = new EventEmitter();
    @Output() public readonly recordAudio = new EventEmitter();
    @Output() public readonly stopRecording = new EventEmitter();
    @Output() public readonly done = new EventEmitter();
}
