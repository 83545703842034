import {
    differenceInMilliseconds,
    intervalToDuration,
    parseISO
} from 'date-fns';
import { interval, OperatorFunction } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

export const die = (msg: string) => {
    throw msg;
};

export const filterEmpty = <T>(): OperatorFunction<T | null | undefined, T> =>
    filter<T>((value) => value !== undefined && value !== null);

export const valueNotSet = 'Not set';

export const pad = (num: number, size: number) => {
    let str = num.toString();
    while (str.length < size) {
        str = '0' + num;
    }
    return str;
};

export const durationToTime = (duration: Duration) =>
    `${pad(duration.hours ?? 0, 2)}:${pad(duration.minutes ?? 0, 2)}:${pad(
        duration.seconds ?? 0,
        2
    )}`;

export const timeElapsed = (date: Date) =>
    interval(1000).pipe(
        startWith(date),
        map(() => {
            const duration = intervalToDuration({
                start: 0,
                end: differenceInMilliseconds(new Date(), date)
            });
            return durationToTime(duration);
        })
    );

export const isNull = (input: unknown | null | undefined) =>
    input === null || input === undefined;

export const isNullOrEmpty = (input: unknown | null | undefined) =>
    isNull(input) || input === '';

export const randomColor = (): string => {
    let color = Math.floor(Math.random() * 16777215).toString(16);
    if (color.startsWith('FFFFF')) {
        color = randomColor();
    }

    return `#${color}`;
};

export const getModalCssClass = (listLength: number): string => {
    let cssClass = '';
    if (listLength <= 3) {
        cssClass = 'h-1/3 top-2/3';
    } else if (listLength > 3 && listLength <= 8) {
        cssClass = 'h-2/4 top-2/4';
    } else if (listLength > 8 && listLength <= 12) {
        cssClass = 'h-3/4 top-1/4';
    } else {
        cssClass = '';
    }
    return cssClass;
};

export const dateStringAsDate = (
    value: Date | string | null | undefined | number
): Date => {
    if (isNull(value)) {
        return new Date();
    } else if (typeof value === 'string') {
        return parseISO(String(value));
    } else if (typeof value === 'number') {
        return new Date(value);
    } else {
        return value as Date;
    }
};
