<ng-container *ngIf="currentSession$ | async as currentSession">
    <ion-card
        *ngIf="raceTimerService.timerRunning$ | async"
        class="mx-[-22px] mt-[-15px] p-1 sticky"
        [style.background-color]="
            raceTimerService.raceEvent
                | eventColor : 'background' : SESSION_EVENT_TYPES.RACE_START
        "
        [style.color]="
            raceTimerService.raceEvent
                | eventColor : 'text' : SESSION_EVENT_TYPES.RACE_START
        "
        (click)="timerClicked.emit()"
    >
        <ion-card-content>
            <section
                class="grid grid-cols-[30px_1fr_1fr_10px] items-center w-full"
            >
                <ion-icon name="bag-check-outline"></ion-icon>
                <div>
                    <ng-container
                        [ngSwitch]="raceTimerService.raceEvent.typeOfEvent"
                    >
                        <ng-container
                            *ngSwitchCase="SessionEventTypeId.TESTING"
                        >
                            <h4 class="text-white">Testing</h4>
                        </ng-container>
                        <ng-container
                            *ngSwitchCase="SessionEventTypeId.RACE_START"
                        >
                            <h4 class="text-white">
                                Race #{{
                                    raceTimerService.raceEvent.raceNumber
                                }}
                                <span
                                    *ngIf="
                                        (currentSession.currentMark ?? 0) >=
                                            0 && raceTimerService.difference >= 0
                                    "
                                >
                                    - Leg #{{
                                        (currentSession?.currentMark ?? 0) + 1
                                    }}
                                </span>
                                <span *ngIf="raceTimerService.difference < 0">
                                    - Prestart
                                </span>
                            </h4>
                        </ng-container>
                        <ng-container *ngSwitchCase="RaceTypeEnum.PRACTICE">
                            <h4 class="text-white">
                                Practice start #{{
                                    raceTimerService.raceEvent.raceNumber
                                }}
                                <span
                                    *ngIf="
                                        (currentSession?.currentMark ?? 0) >= 0
                                    "
                                ></span>
                            </h4>
                        </ng-container>
                    </ng-container>
                </div>
                <ion-label class="text-right text-[20px] font-[590]"
                    >{{ currentTimer$ | async }}
                </ion-label>

                <ion-icon name="chevron-forward-outline"></ion-icon>
            </section>
            <ion-button
                *ngIf="
                    raceTimerService.difference > 0 &&
                    (raceTimerService.raceEvent.raceType ===
                        RaceTypeEnum.RACE ||
                        raceTimerService.raceEvent.raceType ===
                            RaceTypeEnum.PRACTICE)
                "
                shape="round"
                expand="full"
                class="mark__button mt-[15px]"
                (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    markPassed.emit()
                "
            >
                <ng-container
                    *ngIf="
                        ((currentSession?.currentMark + 1) ?? 0) <
                            (raceTimerService.raceEvent.marks ?? 0);
                        else finish
                    "
                >
                    <span>
                        Ping mark {{ (currentSession?.currentMark ?? 0) + 1 }}
                    </span>
                </ng-container>
                <ng-template #finish>
                    {{
                        raceTimerService.raceEvent.raceType ===
                        RaceTypeEnum.RACE
                            ? 'Ping race finish'
                            : 'End practice start'
                    }}
                    <svg
                        class="ml-[18px]"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.5 0V1V11V18H2.5V11H9.88281L10.8828 13H18.5V2H12.1172L11.1172 0L0.5 0ZM2.5 2H9.88281L10.8828 4H11.5H16.5V11H12.1172L11.1172 9H2.5V2Z"
                            fill="black"
                        />
                    </svg>
                </ng-template>
            </ion-button>
        </ion-card-content>
    </ion-card>
</ng-container>
