import { Pipe, PipeTransform } from '@angular/core';
import { ModalListItem } from '../models/modelList.model';

@Pipe({
    name: 'listItem'
})
export class ListItemPipe implements PipeTransform {
    public transform<T>(
        items: ReadonlyArray<T> | null,
        isStringArray: boolean = false
    ) {
        let result: ModalListItem[] | undefined = [];

        if (isStringArray) {
            result = items?.map(
                (value) =>
                    ({
                        id: value,
                        name: value
                    } as unknown as ModalListItem)
            );
        } else {
            result = items as unknown as ModalListItem[];
        }

        return result;
    }
}
