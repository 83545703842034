import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalService } from '../../../services/modal.service';
import { SessionEvent } from '../../../shared/index';
import { SessionStore } from '../../session.store';
import { EditTestingPage } from './edit-testing/edit-testing.page';
import {
    EditTestingResultRole,
    EditTestingService
} from './edit-testing/edit-testing.service';
import { StartTestingPage } from './start-testing/start-testing.page';
import {
    StartTestingResultRole,
    StartTestingService
} from './start-testing/start-testing.service';
import { TestRunningPage } from './test-running/test-running.page';
import {
    TestingRunningResultRole,
    TestRunningService
} from './test-running/test-running.service';

@Injectable({
    providedIn: 'root'
})
export class TestingEventService {
    private isTesting$: Observable<boolean>;
    private isTesting: boolean;
    private lastTestingEvent: SessionEvent;

    constructor(
        private sessionStore: SessionStore,
        private modalService: ModalService
    ) {
        this.isTesting$ = this.sessionStore.currentSession$.pipe(
            map((session) => {
                return !!session?.testTimerStartedAt;
            })
        );

        this.sessionStore.lastTestingEvent$.subscribe((event) => {
            this.lastTestingEvent = event;
        });

        this.isTesting$.subscribe((isTesting) => {
            this.isTesting = isTesting;
        });
    }

    openTestingPage() {
        if (this.isTesting) {
            this.openTestRunningPage();
        } else {
            this.openStartTestingPage();
        }
    }

    async openEditPage(testingEvent: SessionEvent) {
        const editTestingService = new EditTestingService();

        const modal = await this.modalService.createModal<EditTestingPage>(
            EditTestingPage,
            {
                editTestingService: editTestingService,
                testingEvent: testingEvent
            }
        );

        editTestingService.editTestingResult$.subscribe((result) => {
            modal.dismiss();

            if (result.role === EditTestingResultRole.CANCEL) {
                return;
            } else if (result.role === EditTestingResultRole.SAVE) {
                this.sessionStore.editTestingEvent(result.data.sessionEvent);
                return;
            }
        });
    }

    private async openStartTestingPage() {
        const startTestingService = new StartTestingService();

        const modal = await this.modalService.createModal<StartTestingPage>(
            StartTestingPage,
            {
                startTestingService: startTestingService
            }
        );

        startTestingService.startTestingResult$.subscribe((result) => {
            modal.dismiss();

            if (result.role === StartTestingResultRole.CANCEL) {
                return;
            }

            this.sessionStore.addTestingEvent(result.data.sessionEvent);
        });
    }

    private async openTestRunningPage() {
        const testRunningService = new TestRunningService();

        const modal = await this.modalService.createModal<TestRunningPage>(
            TestRunningPage,
            {
                testRunningService: testRunningService,
                testingEvent: this.lastTestingEvent
            }
        );

        testRunningService.testRunningResult$.subscribe((result) => {
            modal.dismiss();

            if (result.role === TestingRunningResultRole.CANCEL_EDITING) {
                return;
            } else if (
                result.role === TestingRunningResultRole.CANCEL_TESTING
            ) {
                this.sessionStore.cancelTestingEvent(this.lastTestingEvent);
                return;
            } else if (result.role === TestingRunningResultRole.SAVE) {
                this.sessionStore.editTestingEvent(result.data.sessionEvent);
            } else if (result.role === TestingRunningResultRole.STOP) {
                this.sessionStore.stopTestingEvent(result.data.sessionEvent);
            }
        });
    }
}
