<ion-content ngClass="white">
    <app-layout-header
        title="Select a boat"
        [imageSource]="imageEnum.boats"
    ></app-layout-header>

    <app-boat-overview
        *ngFor="let boat of (dataService.boats$ | async); trackBy: trackBy"
        [boat]="boat"
        (selected)="boatSelected($event)"
    >
    </app-boat-overview>

    <div class="bottom_space">&nbsp;</div>
</ion-content>
