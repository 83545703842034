<app-layout>
    <ion-button
            button
            fill="clear"
            (click)="modalController.dismiss(null)"
    >
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>

    <h4 toolbarContent>Action log</h4>
    <app-help
            contextButton
            [helpType]="helpType.helpEventOverview"
    ></app-help>

    <div
            class="layout_content overflow-hidden"
            ngClass="white"
    >
        <ng-container
                *ngFor="
                let eventByDay of eventByDays;
                let i = index;
                trackBy: trackBy
            "
        >
            <ion-row
                    *ngIf="eventByDays.length > 1"
                    class="ml-5 my-5"
            >{{ eventByDay.day }}
            </ion-row>

            <ion-row
                    *ngFor="
                    let event of eventByDay.events;
                    let i = index;
                    trackBy: trackBy
                "
                    [ngSwitch]="event.typeOfEvent"
            >
                <!--SailsUp-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.SAIL_UP"
                        [imageSource]="imageEnum.sail"
                        title="Sails up"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                    <div *ngIf="event.sailsUp?.length">
                        {{ event.sailsUp | join : 'name' }}
                    </div>
                    <div *ngIf="!event.sailsUp?.length">
                         No sail
                    </div>
                </app-event-overview-item>

                <!--Property-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.PROPERTY"
                        [imageSource]="imageEnum.numbers"
                        title="Property"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                    {{ event.property?.propertyName }} is
                    {{
                        event.property?.valueCurrent === null
                                ? 'Not set'
                                : event.property?.valueCurrent
                    }}
                </app-event-overview-item>

                <!--Comment-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.COMMENT"
                        [imageSource]="imageEnum.comment"
                        title="Comment"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Incident-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.ISSUE"
                        [imageSource]="imageEnum.alert"
                        title="Incident"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                    {{ event.eventOption }}
                </app-event-overview-item>

                <!--Performance-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.BALANCE"
                        [imageSource]="imageEnum.thumbUp"
                        title="Performance"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Calibration-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.CALIBRATION"
                        [imageSource]="imageEnum.sliders"
                        title="Calibration"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Race start-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.RACE_START"
                        [imageSource]="imageEnum.clock"
                        [title]="
                        event.raceType === RaceTypeEnum.PRACTICE
                            ? 'PracticeStart started'
                            : 'Race started'
                    "
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Testing-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.TESTING"
                        [imageSource]="imageEnum.check"
                        [title]="'Test phase'"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Mark passed-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.RACE_MARK"
                        [imageSource]="imageEnum.check"
                        [title]="'Mark # ' + (event.mark ?? -1) + ' passed'"
                        [event]="event"
                        [showComment]="false"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Race stop-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.RACE_FINISH"
                        [imageSource]="imageEnum.clock"
                        [title]="
                        event.raceType === RaceTypeEnum.PRACTICE
                            ? 'PracticeStart finished'
                            : 'Race finished'
                    "
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Testing ended-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.CANCELLED"
                        [imageSource]="imageEnum.check"
                        title="Canceled"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                >
                </app-event-overview-item>

                <!--Start Session-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.START_SESSION"
                        [imageSource]="imageEnum.play"
                        title="Session started"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                        [showComment]="false"
                >
                </app-event-overview-item>

                <!--End Session-->
                <app-event-overview-item
                        *ngSwitchCase="eventType.STOP_SESSION"
                        [imageSource]="imageEnum.stop"
                        title="End Session"
                        [event]="event"
                        (openModal)="openModal.emit($event)"
                        [showComment]="false"
                >
                </app-event-overview-item>
            </ion-row>
        </ng-container>
    </div>
</app-layout>
