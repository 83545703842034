<ion-item (click)="toggle()" *ngIf="itemShown">
    <ion-label>{{ control.value }}</ion-label>
    <ion-icon
        class="delete-icon"
        name="trash-outline"
        slot="end"
        (click)="delete.next()"
    ></ion-icon>
</ion-item>
<app-input-text
    *ngIf="!itemShown"
    [formControl]="control"
    title="Property name"
    type="text"
    (editingDone)="editingDoneMethod()"
    [focusOnInit]="true"
></app-input-text>
