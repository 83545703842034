import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-export-status',
    templateUrl: './export-status.component.html',
    styleUrls: ['./export-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportStatusComponent {
    @Input() public isDone$: Observable<boolean | null>;
    @Input() public progress$: Observable<number | null>;

    @Output() public readonly cancel = new EventEmitter();

}
