<app-layout [border]="true" class="border top-shadow">
    <ion-button button fill="clear" (click)="modalService.dismiss(null)">
        <ion-icon name="close-outline" class="w-5"></ion-icon>
    </ion-button>
    <h4 toolbarContent>Take action</h4>
    <ng-container>
        <div class="flex items-center mt-4" (click)="edit()">
            <ion-icon name="pencil-outline" class="mr-4 w-4"></ion-icon>
            <span class="text-black">Edit {{ name }}</span>
        </div>

        <hr class="mt-6 mb-6" />

        <div
            class="flex items-center"
            (click)="modalService.dismiss(null, 'remove')"
        >
            <ion-icon
                name="trash-outline"
                class="mr-4 w-4 text-red-600"
            ></ion-icon>
            <span class="text-red-600">Remove {{ name }}</span>
        </div>
    </ng-container>
</app-layout>
