import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthStore } from '../services/auth.store';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private readonly authStore: AuthStore,
        private readonly router: Router
    ) {}

    canActivate() {
        return this.authStore.isInitialized$.pipe(
            filter((isInitialized) => isInitialized),
            switchMap(() =>
                this.authStore.isLoggedIn$.pipe(
                    take(1),
                    tap((isLoggedIn) => {
                        if (!isLoggedIn) {
                            this.router.navigate(['home']);
                        }
                    })
                )
            )
        );
    }
}
