<div class="ion-page h-full">
    <ion-content class="layout_content white">
        <app-layout-header
            [title]="title"
            [subTitle]="subTitle"
            [imageSource]="imageSource"
        ></app-layout-header>
        <div class="px-5">
            <a (click)="addProperty.emit()">
                <ion-icon
                    size="large"
                    name="add-outline"
                    class="mr-2 align-middle"
                ></ion-icon>
                <span class="align-middle">Add property...</span></a
            >
        </div>
        <div *ngFor="let property of properties; let i = index">
            <hr class="mt-6 mb-5" />

            <ion-row class="grid grid-cols-[32px_1fr_50px]">
                <ion-col>
                    <app-images [image]="imageEnum.boats"></app-images>
                </ion-col>
                <ion-col>
                    <ion-row class="block">{{ property.propertyName }}</ion-row>
                    <ng-container *ngIf="isPropertyString(property)">
                        <ion-row class="footnote">{{
                            property.propertyValues.join(', ')
                        }}</ion-row>
                    </ng-container>

                    <ng-container *ngIf="isPropertyNumber(property)">
                        <ion-row class="footnote"
                            >From {{ property.minBound }} to
                            {{ property.maxBound }} step
                            {{ property.increment }}, default
                            {{ property.valueDefault }}</ion-row
                        >
                    </ng-container>
                </ion-col>
                <ion-col>
                    <ion-buttons>
                        <ion-button
                            (click)="editProperty.emit({ index: i, property })"
                        >
                            <ion-icon
                                name="ellipsis-horizontal-outline"
                            ></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-col>
            </ion-row>
        </div>
        <div class="bottom_space">&nbsp;</div>
    </ion-content>
    <ion-footer>
        <ion-button
            shape="round"
            expand="full"
            (click)="goToNextPage.emit()"
            [disabled]="hasErrors"
            >Next
        </ion-button>
    </ion-footer>
</div>
