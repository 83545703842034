import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import {
    ImageEnum,
    Session,
    SESSION_TYPES_LIST,
    SessionTypeWithDisplayValue
} from 'src/app/shared';
import { SessionForm } from '../../../services/session/properties/session-form';

@Component({
    selector: 'app-session-detail',
    templateUrl: './session-detail.component.html',
    styleUrls: ['./session-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionDetailComponent implements OnInit, OnDestroy {
    @Input() public form: FormGroup<SessionForm>;
    @Input() public wizard = true;
    @Input() public hasErrors: boolean | null = true;
    @Input() public edit: boolean = false;

    private sessionNameOnInit: string;
    private sessionBoatOnInit: string;
    private sessionDateOnInit: Date;

    @Input()
    public allSessions$: Observable<Readonly<Session[]>>;
    private allSessions: Readonly<Session[]>;

    @Output() public next = new EventEmitter();
    public sessionTypesWithDisplayValue: ReadonlyArray<SessionTypeWithDisplayValue> =
        SESSION_TYPES_LIST;

    public hasErrorsSubject$ = new BehaviorSubject<boolean>(true);
    public hasErrors$ = this.hasErrorsSubject$.asObservable();
    public errors$ = new BehaviorSubject<string[]>([]);

    public readonly imageEnum = ImageEnum;

    private readonly componentDestroyed$ = new Subject<void>();

    constructor(public readonly modalController: ModalController) {}

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    ngOnInit(): void {
        this.sessionNameOnInit = this.form.controls.name.value;
        this.sessionBoatOnInit = this.form.controls.boat.value;
        this.sessionDateOnInit = this.form.controls.startDate?.value
            ? new Date(this.form.controls.startDate?.value)
            : undefined;

        this.allSessions$.subscribe((allSessions) => {
            this.allSessions = allSessions;
        });

        this.checkErrorState();

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                debounceTime(200),
                tap(() => {
                    this.checkErrorState();
                })
            )
            .subscribe();
    }

    public selectTrackBy(_index: number, item: { id: number }) {
        return item.id;
    }

    protected readonly of = of;

    private checkErrorState() {
        const errors = [];

        let sessionNameBoatDateCombinationTaken = false;

        const sailNameBoatAndDateCombinationExistsInPropertyArray =
            !!this.allSessions.find(
                (session) =>
                    session.name.toUpperCase() ===
                        this.form.controls.name.value?.toUpperCase() &&
                    session.boat.toUpperCase() ===
                        this.form.controls.boat.value?.toUpperCase() &&
                    session.startDate === this.form.controls.startDate.value
            );

        if (
            sailNameBoatAndDateCombinationExistsInPropertyArray &&
            !(
                this.edit &&
                this.sessionNameOnInit?.toUpperCase() ===
                    this.form.controls.name.value?.toUpperCase()
            )
        ) {
            sessionNameBoatDateCombinationTaken = true;
        }

        if (sessionNameBoatDateCombinationTaken) {
            errors.push(
                'The combination of name, boat and start date is already in use.'
            );
        }

        // NICE mita: Warum wird hier auf die ganze Form geschaut? Die muss doch nicht unbedingt valid sein zu
        // diesem Zeitpunkt??
        const hasError =
            !this.form.valid || sessionNameBoatDateCombinationTaken;
        this.hasErrorsSubject$.next(hasError);
        this.errors$.next(errors);
    }
}
