import { Injectable } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { AlertController } from '@ionic/angular';

export interface Image {
    path: string;
    creationTimestamp: number;
    mimeType: string;
    base64Data: string;
}

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    constructor(private readonly alertController: AlertController) {}

    public async takeOrUploadImage(): Promise<Image> {
        const canTakePicture = await Camera.checkPermissions();
        if (!canTakePicture.camera || !canTakePicture.photos) {
            const alertController = await this.alertController.create({
                message: 'Missing picture permission.'
            });
            await alertController.present();
            return;
        }

        const image = await Camera.getPhoto({
            saveToGallery: true,
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            height: 800,
            width: 800
        });

        let exifDate: string;
        if (image.exif?.DateTimeOriginal) {
            exifDate = image.exif.DateTimeOriginal;
        } else if (image.exif?.DateTime) {
            exifDate = image.exif.DateTime;
        }

        let creationTimestamp;
        if (exifDate) {
            const split = exifDate.split(/\D/).map((str) => Number(str));
            creationTimestamp = new Date(
                split[0],
                split[1] - 1,
                split[2],
                split[3],
                split[4],
                split[5]
            ).getTime();
        } else {
            creationTimestamp = new Date().getTime();
        }

        if (!image.path) {
            throw new Error('No image');
        }

        let mimeType: string;

        if (image.format === 'jpeg') {
            mimeType = 'image/jpeg';
        } else if (image.format === 'png') {
            mimeType = 'image/png';
        } else if (image.format === 'gif') {
            mimeType = 'image/gif';
        }

        const readFileResult = await Filesystem.readFile({
            path: image.path
        });

        return {
            path: image.path,
            creationTimestamp: creationTimestamp,
            mimeType: mimeType,
            base64Data: readFileResult.data as string
        };
    }
}
