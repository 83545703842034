import {
    ChangeDetectionStrategy,
    Component,
    Input,
    TemplateRef
} from '@angular/core';
import { ImageEnum } from 'src/app/shared';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class LayoutComponent {
    @Input() public headerComponent: TemplateRef<unknown>;
    @Input() public imageSource: ImageEnum | null = null;
    @Input() public title: string | null = null;
    @Input() public subTitle: string | null = null;
    @Input() public center = false;
    @Input() public border = false;
    @Input() public padding = true;
    @Input() public color: string;
    @Input() public scrollY: boolean = true;
}
