import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewChild
} from '@angular/core';
import { IonPopover } from '@ionic/angular';

@Component({
    selector: 'app-popover-wrapper',
    templateUrl: './popover-wrapper.component.html',
    styleUrls: ['./popover-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverWrapperComponent {
    @Input() public enabled = true;

    public showPopover = false;

    @ViewChild('popover') public popover: IonPopover;

    public open(event: unknown) {
        this.popover.event = event;
        this.showPopover = true;
    }

    public close() {
        this.showPopover = false;
    }
}
