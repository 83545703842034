import { TYPE_OF_SAIL } from './TypeOfSail';

export const TYPE_OF_SAIL_GROUP = [
    {
        id: 1,
        name: 'Mainsail',
        shortName: 'M',
        color: '#e9f2f8',
        colorSelected: '#2980B9'
    },
    {
        id: 2,
        name: 'Headsail',
        color: '#e9f7f0',
        shortName: 'H',
        colorSelected: '#27AE60'
    },
    {
        id: 3,
        name: 'Staysail',
        shortName: 'S',
        color: '#fcf2e9',
        colorSelected: '#E67E22'
    },
    {
        id: 4,
        name: 'Symmetrical Spinnaker',
        shortName: 'S',
        color: '#ebeced',
        colorSelected: '#6e6e6fb3'
    },
    {
        id: 5,
        name: 'Asymmetrical Gennaker',
        shortName: 'G',
        color: '#ebeced',
        colorSelected: '#6e6e6fb3'
    }
];

export type SailGroupType =
    | 'Mainsail'
    | 'Headsail'
    | 'Staysail'
    | 'Symmetrical Spinnaker'
    | 'Asymmetrical Gennaker';

const sailingTypeLookup: Record<number, SailGroupType> = {};
TYPE_OF_SAIL_GROUP.forEach((sail) => {
    sailingTypeLookup[sail.id] = sail.name as SailGroupType;
});

export const mapNumberToSailGroupType = (
    typeNumber: number
): SailGroupType | undefined => sailingTypeLookup[typeNumber];
