import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { add } from 'date-fns';
import { dateStringAsDate } from 'src/app/shared';

interface TimeOption {
    label: string;
    value: number;
}

@Component({
    selector: 'app-set-date-time',
    templateUrl: './set-date-time.component.html',
    styleUrls: ['./set-date-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetDateTimeComponent implements OnChanges, OnInit {
    @Input() public date = new Date();

    public dateString: string = null;
    public readonly timeOptions: ReadonlyArray<TimeOption> = [
        {
            label: '+5s',
            value: 5
        },
        {
            label: '+1m',
            value: 60
        },
        {
            label: '+5m',
            value: 300
        },
        {
            label: '+15m',
            value: 900
        },
        {
            label: '-5s',
            value: -5
        },
        {
            label: '-1m',
            value: -60
        },
        {
            label: '-5m',
            value: -300
        },
        {
            label: '-15m',
            value: -900
        }
    ];

    constructor(private readonly modalController: ModalController) {}

    public close() {
        this.modalController.dismiss(null, 'close');
    }

    public save() {
        this.modalController.dismiss(this.date, 'save');
    }

    public updateDateTime(option: Readonly<TimeOption>) {
        this.date = add(dateStringAsDate(this.date), { seconds: option.value });
        this.toLocalIsoString();
    }

    public now() {
        this.date = new Date();
    }

    public ngOnInit(): void {
        if (this.date) {
            this.date = dateStringAsDate(this.date);
            this.toLocalIsoString();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { date } = changes;
        if (date.currentValue) {
            this.date = dateStringAsDate(this.date);
            this.toLocalIsoString();
        }
    }

    onDateChange($event: any) {
        this.date = new Date($event.detail.value);
        this.toLocalIsoString();
    }

    private toLocalIsoString() {
        const tzoffset = this.date.getTimezoneOffset() * 60000; //offset in milliseconds
        this.dateString = new Date(this.date.getTime() - tzoffset)
            .toISOString()
            .slice(0, -1);
    }
}
