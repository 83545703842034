import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DataService } from '../shared/index';

@Injectable({
    providedIn: 'root'
})
export class UnitService {
    static readonly KG_LB_CONVERSION = 2.20462262185;

    constructor(private dataService: DataService) {}

    isMetric$(): Observable<boolean> {
        return this.dataService.settings$.pipe(
            map((settings) => !settings.useImperialUnits),
            distinctUntilChanged()
        );
    }

    weightShortString(): Observable<string> {
        return this.isMetric$().pipe(
            distinctUntilChanged(),
            map((isMetric) => (isMetric ? 'kg' : 'lbs'))
        );
    }

    lengthShortString(): Observable<string> {
        return this.isMetric$().pipe(
            distinctUntilChanged(),
            map((isMetric) => (isMetric ? 'm' : 'ft'))
        );
    }

    weightDisplayString$(weight: number): Observable<string> {
        return this.isMetric$().pipe(
            map((metric) => {
                if (metric) {
                    return weight.toFixed(1) + ' kg';
                } else {
                    return (
                        Number(
                            (weight * UnitService.KG_LB_CONVERSION).toFixed(1)
                        ) + ' lbs'
                    );
                }
            })
        );
    }
}
