import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'first'
})
export class FirstPipe implements PipeTransform {
    public transform<T>(
        items: ReadonlyArray<T> | null | undefined,
        amount: number
    ) {
        return items?.slice(0, amount);
    }
}
