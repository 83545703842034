import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SessionEvent } from '../../../../shared/index';

export enum StartTestingResultRole {
    START = 'START',
    CANCEL = 'CANCEL'
}

export interface StartTestingResultData {
    sessionEvent: SessionEvent;
}

export interface StartTestingResult {
    role: StartTestingResultRole;
    data?: StartTestingResultData;
}

@Injectable()
export class StartTestingService {
    private _startTestingResult$: Subject<StartTestingResult> =
        new Subject<StartTestingResult>();

    constructor() {}

    startTesting(resultData: StartTestingResultData) {
        this._startTestingResult$.next({
            role: StartTestingResultRole.START,
            data: resultData
        });
    }

    cancelStartTesting() {
        this._startTestingResult$.next({
            role: StartTestingResultRole.CANCEL
        });
    }

    public get startTestingResult$(): Observable<StartTestingResult> {
        return this._startTestingResult$;
    }
}
