import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import { DataService } from 'src/app/shared/services/data.service';
import { WizardService } from '../../../services/wizard.service';
import { BoatStore } from '../../current-boat-store.service';

@Component({
    selector: 'app-boat-details-page',
    templateUrl: './boat-details.page.html',
    styleUrls: ['./boat-details.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoatDetailsPage {
    public helpType: DataMarkdownEnum;

    constructor(
        public readonly dataService: DataService,
        public readonly wizardService: WizardService,
        public readonly myBoatsStore: BoatStore
    ) {}

    public selectTrackBy(_index: number, item: { id: number }) {
        return item.id;
    }
}
