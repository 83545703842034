<div [class.ion-invalid]="isInvalid">
    <ion-item fill="outline" mode="md">
        <ion-label position="stacked">{{
            title + (isRequired ? ' *' : '')
        }}</ion-label>
        <ion-select [placeholder]="placeholder" [formControl]="formControl">
            <ion-select-option
                *ngFor="let option of options"
                [value]="option.value"
                >{{ option.displayValue }}</ion-select-option
            >
        </ion-select>
    </ion-item>
</div>
