<app-layout [border]="true" color="blue">
    <ion-button button (click)="modalController.dismiss()">
        <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>

    <h4 toolbarContent>{{ markdown?.title }}</h4>
    <ng-container>
        <markdown ngPreserveWhitespaces>
            {{ markdown?.markdown }}
        </markdown>
    </ng-container>
</app-layout>
