<div class="ion-page">
    <ion-content class="white]">
        <app-layout-header
            title="Boat details"
            subTitle="Please provide the general boat details."
            [imageSource]="imageEnum.boats"
        ></app-layout-header>
        <div [formGroup]="form">
            <ion-list>
                <app-input-text
                    formControlName="name"
                    title="Boat name"
                ></app-input-text>

                <app-input-select
                    formControlName="typeOfBoat"
                    title="Type"
                    [list]="typeOfBoat | listItem"
                ></app-input-select>

                <app-input-simple-select
                    title="Number of masts"
                    [options]="numberOfMastsOptions"
                    [formControl]="form.controls.numberOfMasts"
                >
                </app-input-simple-select>

                <app-input-text
                    formControlName="length_ft"
                    title="Length (ft)"
                    type="number"
                    inputmode="numeric"
                ></app-input-text>
            </ion-list>

            <ion-item-divider></ion-item-divider>

            <ion-list>
                <h4>Other</h4>
                <app-weight-input
                    formControlName="displacement"
                    [title]="displacementTitle$ | async"
                    [isMetric]="unitService.isMetric$() | async"
                ></app-weight-input>

                <app-input-select
                    formControlName="typeOfSailing"
                    title="Type of sailing"
                    [list]="typeOfSailing | listItem"
                ></app-input-select>

                <ion-row>
                    <ion-col>
                        <ion-button
                            class="rounded"
                            expand="block"
                            fill="outline"
                            (click)="addPhoto()"
                        >
                            {{
                                form.controls.image?.value?.uploadedImageBase64
                                    ? 'Edit photo'
                                    : 'Add photo'
                            }}
                        </ion-button>
                    </ion-col>
                    <ion-col *ngIf="boatImage$ | async as boatImage">
                        <ion-img [src]="boatImage"></ion-img>
                    </ion-col>
                </ion-row>

                <!-- <ion-item mode="md" class="mt-5 text-black" lines="none">
                  <ion-label><span>Can plane</span></ion-label>
                  <ion-toggle formControlName="canPlane"></ion-toggle>
                </ion-item>

                <ion-item mode="md" class="mt-5 text-black" lines="none">
                  <ion-label><span>Can foil</span></ion-label>
                  <ion-toggle formControlName="canFoil"></ion-toggle>
                </ion-item>

                <ion-item mode="md" class="mt-5 text-black" lines="none">
                  <ion-label><span>Can use asymmetrical sails downwind</span></ion-label>
                  <ion-toggle formControlName="canAsymmetricalSails"></ion-toggle>
                </ion-item> -->
            </ion-list>

            <!-- <ion-item-divider></ion-item-divider>

            <ion-list>
              <h4>Approximate boatspeed</h4>
              <ion-label><span class="body-13">Upwind</span></ion-label>
              <ion-segment formControlName="upwind">
                <ion-segment-button value="7" class="mr-2">7kn</ion-segment-button>
                <ion-segment-button value="12" class="mr-2">12kn</ion-segment-button>
                <ion-segment-button value="20">20kn</ion-segment-button>
              </ion-segment>

              <ion-label><span class="body-13">Downwind</span></ion-label>
              <ion-segment formControlName="downwind">
                <ion-segment-button value="7" class="mr-2">7kn</ion-segment-button>
                <ion-segment-button value="12" class="mr-2">12kn</ion-segment-button>
                <ion-segment-button value="20">20kn</ion-segment-button>
              </ion-segment>
            </ion-list> -->
        </div>
    </ion-content>

    <ion-footer>
        <ion-button
            shape="round"
            expand="full"
            (click)="submitForm()"
            [disabled]="hasErrors"
            >Next
        </ion-button>
    </ion-footer>
</div>
