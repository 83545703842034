<div class="ion-page">
    <ion-header [ngClass]="color">
        <ion-toolbar>
            <ion-buttons slot="start" class="px-[7px]">
                <ng-content select="[button]"></ng-content>
            </ion-buttons>
            <ng-content select="[toolbarContent]"></ng-content>
            <ion-buttons slot="end">
                <ng-content select="[contextButton]"></ng-content>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ng-content select="[stickyContent]"></ng-content>

    <ion-content [ngClass]="color" [scrollY]="scrollY">
        <app-layout-header
            [title]="title"
            [subTitle]="subTitle"
            [imageSource]="imageSource"
            *ngIf="title || subTitle || imageSource"
        >
            <ng-content select="[headerButton]"></ng-content>
        </app-layout-header>
        <div class="p-[10px] h-full">
            <ng-content></ng-content>
            <div class="min-h-[10px]"></div>
        </div>
    </ion-content>
    <ng-content select="ion-footer"></ng-content>
</div>
