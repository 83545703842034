import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SESSION_EVENT_TYPES, SessionEvent } from '../../../shared/index';
import { TestingEventService } from '../../pages/testing-event/testing-event.service';
import { TestTimerService } from './test-timer.service';

@Component({
    selector: 'app-test-timer',
    templateUrl: './test-timer.component.html',
    styleUrls: ['./test-timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestTimerComponent {
    @Input()
    public clickable = true;
    @Input()
    isSticky = true;

    constructor(
        public readonly testTimerService: TestTimerService,
        public readonly testingEventService: TestingEventService
    ) {}

    get testingEvent(): SessionEvent {
        return this.testTimerService.testingEvent;
    }

    protected readonly SESSION_EVENT_TYPES = SESSION_EVENT_TYPES;

    timerClicked() {
        if (this.clickable) {
            this.testingEventService.openTestingPage();
        }
    }
}
