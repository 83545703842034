import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ImageEnum } from 'src/app/shared';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import { ModalService } from '../../../services/modal.service';

@Component({
    selector: 'app-help',
    templateUrl: './help-button.component.html',
    styleUrls: ['./help-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpButtonComponent {
    @Input() helpType: DataMarkdownEnum;

    public readonly imageType = ImageEnum;

    constructor(public readonly modalService: ModalService) {}
}
