<ion-item fill="outline" mode="md" (click)="selectOpen()">
    <ion-label position="stacked">{{
        title + (isRequired ? ' *' : '')
    }}</ion-label>
    <ion-input
        class="text-black"
        type="text"
        (input)="onChange($any($event.target).value)"
        (blur)="onTouched()"
        [disabled]="disabled"
        inputmode="text"
        [value]="valueText"
        [placeholder]="setValueNotSet ? valueNotSet : ''"
    >
    </ion-input>
    <ion-button slot="end" fill="clear" class="seeClient">
        <ion-icon name="chevron-down-outline" class="iconEye"></ion-icon>
    </ion-button>
</ion-item>
