import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { WizardService } from 'src/app/services/wizard.service';
import { DataService, ImageEnum } from 'src/app/shared';
import { PropertyForm } from '../../../services/boats/properties/property-form';
import { PropertyService } from '../../../services/boats/properties/property.service';
import { SessionStore } from '../../session.store';

@Component({
    selector: 'app-session-boat-configuration-page',
    templateUrl: './session-boat-configuration.page.html',
    styleUrls: ['./session-boat-configuration.page.scss']
})
export class SessionBoatConfigurationPage {
    public readonly form: FormGroup = this.sessionStore.form;
    public readonly staticPropertiesForm: FormArray<FormGroup<PropertyForm>> =
        this.sessionStore.staticPropertiesForm;
    public readonly imageEnum = ImageEnum;
    constructor(
        public readonly wizardService: WizardService,
        public readonly dataService: DataService,
        public readonly sessionStore: SessionStore,
        private readonly propertyService: PropertyService
    ) {
        if (this.sessionStore.staticPropertiesForm.value.length === 0) {
            this.staticPropertiesForm.getRawValue().forEach((property) => {
                const form = this.propertyService.propertyToFormGroup(
                    property,
                    true
                );
                this.sessionStore.staticPropertiesForm.push(form);
            });
        }
    }
}
