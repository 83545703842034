import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { differenceInMilliseconds, format, getMonth, getYear } from 'date-fns';
import { cloneDeep, isArray } from 'lodash';
import {
    BehaviorSubject,
    combineLatest,
    from,
    Observable,
    of,
    Subject
} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { PopupComponent } from '../components/components/popup/popup.component';
import { WizardComponent } from '../components/components/wizard/wizard.component';
import { BoatStore } from '../my-boats/current-boat-store.service';
import { PropertyForm } from '../services/boats/properties/property-form';
import { PropertyService } from '../services/boats/properties/property.service';
import { ModalService } from '../services/modal.service';
import { SailService } from '../services/sail.service';
import { SessionForm } from '../services/session/properties/session-form';
import { SessionService } from '../services/session/properties/session.service';
import {
    Boat,
    DataService,
    dateStringAsDate,
    EventRaceType,
    filterEmpty,
    ImageEnum,
    isNull,
    Property,
    Sail,
    SessionEventType,
    SessionEventTypeId,
    valueNotSet
} from '../shared';
import { DataMarkdownEnum } from '../shared/models/data.model';
import {
    Session,
    SessionCurrentRaceTypeEnum,
    StatusEnum
} from '../shared/models/session.model';
import { SessionEvent } from '../shared/models/sessionEvent.model';
import { ConfirmRaceFinishComponent } from './components/confirm-race-finish/confirm-race-finish.component';
import { EventOverviewPage } from './pages/event-overview/event-overview.page';
import { ExportSessionPage } from './pages/export-session/export-session.page';
import { SessionBoatConfigurationPage } from './pages/session-boat-configuration/session-boat-configuration.page';
import { SessionBoatPage } from './pages/session-boat/session-boat.page';
import { SessionDetailPage } from './pages/session-detail/session-detail.page';
import { SessionOverviewPage } from './pages/session-overview/session-overview.page';
import { SessionSailPage } from './pages/session-sail/session-sail.page';
import { EventService } from './services/event.service';

export interface SessionState {
    currentBoat?: Readonly<string>;
    currentSession?: Readonly<Session>;
    currentSessionId?: string;
}

@Injectable({
    providedIn: 'root'
})
export class SessionStore
    extends ComponentStore<SessionState>
    implements OnDestroy
{
    public readonly currentSession$ = this.select((x) => x.currentSession);
    public readonly currentSessionId$ = this.select((x) => x.currentSessionId);

    public readonly currentBoat$: Observable<Boat> = this.select(
        (x) => x.currentBoat
    ).pipe(
        switchMap((currentBoatId) =>
            this.dataService.getBoatById$(currentBoatId)
        )
    );

    public readonly selectedSessionSails$: Observable<Sail[]> = combineLatest([
        this.currentSession$,
        this.currentBoat$
    ]).pipe(
        map(([session, boat]) =>
            this.sailService.getSailsOfSession(session, boat)
        )
    );

    public readonly availableSessionSails$: Observable<Sail[]> =
        this.currentBoat$.pipe(map((boat) => boat.sails));

    public readonly sessionEvents$ = this.select(
        (x) => x.currentSession?.events ?? []
    );
    public readonly racingEvents$ = this.sessionEvents$.pipe(
        map((events) =>
            events.filter(
                (event) => event.typeOfEvent === SessionEventTypeId.RACE_START
            )
        )
    );
    public readonly highestRaceNumber$: Observable<number | undefined> =
        this.racingEvents$.pipe(
            map((racingEvents) => {
                const onlyRaceNumbersSorted = racingEvents
                    .map((event) => event.raceNumber)
                    .sort((a, b) => b - a);
                if (onlyRaceNumbersSorted.length > 0) {
                    return onlyRaceNumbersSorted[0];
                } else {
                    return undefined;
                }
            })
        );

    public readonly hasSailUpEvent$ = this.select((x) => x.currentSession).pipe(
        map(
            (session) =>
                !!session?.events?.find(
                    (f) => f.typeOfEvent === SessionEventTypeId.SAIL_UP
                )
        )
    );

    // This needs to be refactored... This function finds the last "running" edtiable event
    // Races and Tests can "run" and then still be editable. This finds the last one of those but ONLY
    // if there a no newer event that ended that event (like Finish Race)
    public readonly currentlyRunningEditableEvent$ = this.select(
        (x) => x.currentSession
    ).pipe(
        map(
            (session) =>
                session?.events?.find(
                    (thisEvent) =>
                        thisEvent.typeOfEvent === SessionEventTypeId.TESTING ||
                        thisEvent.typeOfEvent ===
                            SessionEventTypeId.RACE_START ||
                        thisEvent.typeOfEvent ===
                            SessionEventTypeId.RACE_FINISH ||
                        thisEvent.typeOfEvent === SessionEventTypeId.CANCELLED
                ) as SessionEvent
        ),
        map((lastRaceEvent) =>
            (lastRaceEvent?.typeOfEvent === SessionEventTypeId.TESTING &&
                lastRaceEvent?.isStart) ||
            lastRaceEvent?.typeOfEvent === SessionEventTypeId.RACE_START
                ? lastRaceEvent
                : undefined
        )
    );

    public readonly lastTestingEvent$ = this.select(
        (x) => x.currentSession
    ).pipe(
        map((currentSession) =>
            currentSession?.events?.find(
                (event) => event.typeOfEvent === SessionEventTypeId.TESTING
            )
        )
    );

    public readonly lastRacingEvent$ = this.select(
        (x) => x.currentSession
    ).pipe(
        map((currentSession) =>
            currentSession?.events?.find(
                (event) => event.typeOfEvent === SessionEventTypeId.RACE_START
            )
        )
    );

    public readonly form: FormGroup<SessionForm>;

    public readonly sailsForm: FormControl<string[]>;
    public readonly staticPropertiesForm: FormArray<FormGroup<PropertyForm>>;
    public readonly changeablePropertiesForm: FormArray<
        FormGroup<PropertyForm>
    >;

    public readonly detailHasErrorsSubject$ = new BehaviorSubject<boolean>(
        true
    );
    public readonly detailHasErrors$ =
        this.detailHasErrorsSubject$.asObservable();
    public readonly boatConfigHasErrorsSubject$ = new BehaviorSubject<boolean>(
        true
    );
    public readonly boatConfigHasErrors$ =
        this.boatConfigHasErrorsSubject$.asObservable();

    public readonly clearEvents = this.effect((trigger$: Observable<any>) =>
        trigger$.pipe(
            withLatestFrom(this.select((x) => x.currentSession)),
            tap(([, session]) => {
                this.patchState({
                    currentSession: {
                        ...session.currentSession,
                        events: []
                    }
                });
                this.saveSession();
            })
        )
    );

    public readonly selectBoat = this.effect((trigger$: Observable<string>) =>
        trigger$.pipe(
            filter(
                (selectedBoat) =>
                    selectedBoat !== this.form.controls.boat?.value
            ),
            switchMap((boatId) =>
                this.dataService.getBoatById$(boatId).pipe(
                    take(1),
                    tap((boat) => {
                        // Clear existing boat data
                        this.form.reset();
                        this.sailsForm.setValue([]);
                        this.staticPropertiesForm.clear();
                        this.changeablePropertiesForm.clear();

                        // Set boat data
                        boat.properties
                            .filter((prop) => !prop.canBeChangedWhileSailing)
                            .forEach((boatProperty) =>
                                this.addStaticBoatProperty(boatProperty)
                            );
                        boat.properties
                            .filter((prop) => prop.canBeChangedWhileSailing)
                            .forEach((customProperty) =>
                                this.addChangeableProperty(customProperty)
                            );
                        this.form.get('boat')?.patchValue(boat.id);
                        this.form.get('status')?.patchValue(StatusEnum.planned);
                        this.patchState({
                            currentBoat: boatId
                        });
                    })
                )
            )
        )
    );

    public async openSession(sessionId: string) {
        const session = await this.dataService.getSessionById(sessionId);
        const boat = await this.dataService.getBoatById(session.boat);

        this.form.reset();
        this.sailsForm.setValue([]);
        this.staticPropertiesForm.clear();
        this.changeablePropertiesForm.clear();

        this.setProperties(session, boat);

        // Set boat data
        this.form.controls.sails.patchValue(session.sails);
        this.form.controls.boat.patchValue(session.boat);
        this.form.controls.status.patchValue(StatusEnum.active);
        this.form.patchValue(session);

        this.patchState({
            currentBoat: session.boat,
            currentSession: session,
            currentSessionId: session.id
        });

        this.saveSession();
        this.showActiveSessionModal();
    }

    private setProperties(session: Session, boat: Boat) {
        if (session.changeableProperties.length > 0) {
            session.changeableProperties.forEach((prop) => {
                this.addChangeableProperty(prop);
            });
        } else {
            boat.properties
                .filter((prop) => prop.canBeChangedWhileSailing)
                .forEach((customProperty) =>
                    this.addChangeableProperty(customProperty)
                );
        }

        if (session.staticProperties.length > 0) {
            session.staticProperties.forEach((prop) => {
                this.addStaticBoatProperty(prop);
            });
        } else {
            boat.properties
                .filter((prop) => !prop.canBeChangedWhileSailing)
                .forEach((boatProperty) =>
                    this.addStaticBoatProperty(boatProperty)
                );
        }
    }

    public readonly stopSession = this.effect((trigger$: Observable<Session>) =>
        trigger$.pipe(
            switchMap((session) =>
                this.dataService.getBoatById$(session.boat).pipe(
                    take(1),
                    tap((boat) => {
                        this.form.reset();
                        this.sailsForm.setValue([]);
                        this.staticPropertiesForm.clear();
                        this.changeablePropertiesForm.clear();

                        session.endDate = new Date().getTime();

                        this.form.patchValue(session);

                        this.setProperties(session, boat);

                        // Set boat data
                        this.form.controls.sails.patchValue(session.sails);
                        this.form.controls.boat.patchValue(session.boat);
                        this.form.controls.status.patchValue(StatusEnum.past);

                        this.patchState({
                            currentBoat: session.boat,
                            currentSession: session,
                            currentSessionId: session.id
                        });
                    })
                )
            ),
            tap(() => {
                this.saveSession();
                this.modalController.dismiss(null);
            })
        )
    );

    public async copySession(sessionId: string) {
        const currentSessions = this.dataService.sessions;
        const copyName: string = this.getCopyName(sessionId, currentSessions);

        const session: Session = cloneDeep(
            this.dataService.getSessionById(sessionId)
        );

        session.id = uuidv4();
        session.name = copyName;
        session.events = [];
        session.status = StatusEnum.planned;
        session.raceNumber = undefined;
        session.testInterval = 0;
        session.raceType = SessionCurrentRaceTypeEnum.none;
        session.testTimerStartedAt = null;
        session.raceTimerStartedAt = null;

        this.patchState({
            currentSession: session,
            currentSessionId: session.id
        });
        this.dataService.upsertSession(session);
        this.editSession(session.id);
    }

    public removeSession(sessionId: string) {
        this.dataService.removeSession(sessionId);
    }

    public readonly addTestingEvent = this.effect(
        (trigger$: Observable<SessionEvent>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.currentSession)),
                tap(([sessionEvent, currentSession]) => {
                    const events = this.getEventsWithAddedOrEditedEvent(
                        currentSession,
                        sessionEvent
                    );

                    this.patchState({
                        currentSession: {
                            ...currentSession,
                            testTimerStartedAt: sessionEvent.dateTimeUtc,
                            testInterval: sessionEvent.testIntervalMinutes,
                            events: events
                        }
                    });

                    this.form.controls.testTimerStartedAt.setValue(
                        sessionEvent.dateTimeUtc
                    );
                    this.form.controls.testInterval.setValue(
                        sessionEvent.testIntervalMinutes
                    );
                }),
                tap(() => this.saveSession())
            )
    );

    public readonly editTestingEvent = this.effect(
        (trigger$: Observable<SessionEvent>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.currentSession)),
                tap(([sessionEvent, currentSession]) => {
                    const events = this.getEventsWithAddedOrEditedEvent(
                        currentSession,
                        sessionEvent
                    );

                    this.patchState({
                        currentSession: {
                            ...currentSession,
                            testInterval: sessionEvent.testIntervalMinutes,
                            events: events
                        }
                    });

                    this.form.controls.testInterval.setValue(
                        sessionEvent.testIntervalMinutes
                    );
                }),
                tap(() => this.saveSession())
            )
    );

    public readonly cancelTestingEvent = this.effect(
        (trigger$: Observable<SessionEvent>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.currentSession)),
                tap(([sessionEvent, currentSession]) => {
                    const events = currentSession?.events ?? [];
                    const eventsWithoutCancelledEvent = events.filter(
                        (event) => event.id !== sessionEvent.id
                    );

                    this.patchState({
                        currentSession: {
                            ...currentSession,
                            testInterval: undefined,
                            testTimerStartedAt: undefined,
                            events: eventsWithoutCancelledEvent
                        }
                    });

                    this.form.controls.testInterval.setValue(undefined);
                    this.form.controls.testTimerStartedAt.setValue(undefined);
                }),
                tap(() => this.saveSession())
            )
    );

    public readonly stopTestingEvent = this.effect(
        (trigger$: Observable<SessionEvent>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.currentSession)),
                tap(([sessionEvent, currentSession]) => {
                    sessionEvent.isStart = false;
                    sessionEvent.isStop = true;

                    const events = this.getEventsWithAddedOrEditedEvent(
                        currentSession,
                        sessionEvent
                    );

                    this.patchState({
                        currentSession: {
                            ...currentSession,
                            testInterval: undefined,
                            testTimerStartedAt: undefined,
                            events: events
                        }
                    });

                    this.form.controls.testTimerStartedAt.setValue(undefined);
                    this.form.controls.testInterval.setValue(
                        sessionEvent.testIntervalMinutes
                    );
                }),
                tap(() => this.saveSession())
            )
    );

    public readonly editSession = this.effect((trigger$: Observable<string>) =>
        trigger$.pipe(
            switchMap((sessionId) =>
                this.dataService.getSessionById$(sessionId).pipe(
                    take(1),
                    switchMap((session) =>
                        this.dataService.getBoatById$(session.boat).pipe(
                            take(1),
                            tap((boat) => {
                                this.form.reset();
                                this.sailsForm.setValue([]);
                                this.staticPropertiesForm.clear();
                                this.changeablePropertiesForm.clear();

                                this.form.patchValue(session);

                                this.setProperties(session, boat);

                                // Set boat data
                                this.form.controls.sails.patchValue(
                                    session.sails
                                );
                                this.form.controls.boat.patchValue(
                                    session.boat
                                );

                                this.patchState({
                                    currentBoat: session.boat,
                                    currentSession: session,
                                    currentSessionId: session.id
                                });
                            })
                        )
                    )
                )
            ),
            tap(() => this.editSessionDetails())
        )
    );

    public readonly showEventOverviewPage = this.effect(
        (trigger$: Observable<string>) =>
            trigger$.pipe(
                switchMap((sessionId) =>
                    this.dataService.getSessionById$(sessionId).pipe(
                        take(1),
                        switchMap((session) =>
                            this.dataService.getBoatById$(session.boat).pipe(
                                take(1),
                                tap((boat) => {
                                    this.form.reset();
                                    this.sailsForm.setValue([]);
                                    this.staticPropertiesForm.clear();
                                    this.changeablePropertiesForm.clear();

                                    this.form.patchValue(session);

                                    this.setProperties(session, boat);

                                    // Set boat data
                                    this.form.controls.sails.patchValue(
                                        session.sails
                                    );
                                    this.form.controls.boat.patchValue(
                                        session.boat
                                    );
                                    this.patchState({
                                        currentBoat: session.boat,
                                        currentSession: session,
                                        currentSessionId: session.id
                                    });
                                })
                            )
                        )
                    )
                ),
                tap(() => {
                    this.modalService.createModalAndWaitForDismiss(
                        EventOverviewPage
                    );
                })
            )
    );

    public readonly editSessionDetails = this.effect((trigger$) =>
        trigger$.pipe(
            switchMap(() => this.showSessionOverview())
            // tap(() => {
            //   this.saveSession();
            // })
        )
    );

    public readonly showSessionDetails = this.effect((trigger$) =>
        trigger$.pipe(
            tap(() => {
                this.form.reset();
                this.patchState({
                    currentSession: undefined,
                    currentBoat: undefined
                });
            }),
            switchMap(() => this.showSessionModal()),
            tap(() => this.saveSession())
        )
    );

    public saveSession() {
        this.select((x) => x.currentSession)
            .pipe(take(1))
            .subscribe((currentSession) => {
                // NICE mita: solve the any problem here in the future
                this.patchState({
                    currentSession: {
                        ...currentSession,
                        events: currentSession?.events ?? [], // Always initialize events
                        ...this.form.value
                    } as any
                });

                if (this.form.valid) {
                    // NICE mita: solve any problem here
                    this.dataService.upsertSession({
                        ...currentSession,
                        events: currentSession?.events ?? [], // Always initialize events
                        ...this.form.value
                    } as any);
                }
            });
    }

    public readonly createEvent = this.effect(
        (trigger$: Observable<SessionEventType>) =>
            trigger$.pipe(
                withLatestFrom(
                    this.currentlyRunningEditableEvent$,
                    this.select((x) => x.currentSession).pipe(filterEmpty())
                ),
                switchMap(([typeOfEvent, lastRaceEvent, inputSession]) =>
                    from(
                        this.eventService.createEvent(
                            typeOfEvent,
                            this.form,
                            inputSession,
                            // Pass the last session event if it's the same event = edit!
                            lastRaceEvent?.typeOfEvent === typeOfEvent.id
                                ? lastRaceEvent
                                : null,
                            !!typeOfEvent.multipleEvents
                        )
                    ).pipe(
                        filterEmpty(),
                        withLatestFrom(this.select((x) => x.currentSession)), // Get the session again because it can be updated
                        tap<[SessionEvent | SessionEvent[], Session]>(
                            ([event, currentSession]) => {
                                if (isArray(event)) {
                                    event.forEach((thisEvent) =>
                                        this.handleEvent(
                                            thisEvent,
                                            currentSession
                                        )
                                    );
                                } else {
                                    this.handleEvent(event, currentSession);
                                }
                                this.saveSession();
                            }
                        )
                    )
                )
            )
    );

    private handleEvent(event: SessionEvent, currentSession: Session) {
        const events = this.getEventsWithAddedOrEditedEvent(
            currentSession,
            event
        );

        this.patchState({
            currentSession: {
                ...currentSession,
                raceTimerStartedAt:
                    event.isStart &&
                    event.typeOfEvent === SessionEventTypeId.RACE_START
                        ? this.form.get('raceTimerStartedAt')?.value
                        : currentSession.raceTimerStartedAt,
                currentMark:
                    event.typeOfEvent === SessionEventTypeId.RACE_START
                        ? 0
                        : event.typeOfEvent === SessionEventTypeId.RACE_MARK
                        ? Number(event.mark)
                        : currentSession.currentMark,
                raceNumber: event.raceType
                    ? event.raceNumber
                    : currentSession.raceNumber,
                raceType: this.getRaceType(event, currentSession),
                events // New event will be first in the list
            }
        });
    }

    private getRaceType(event: SessionEvent, currentSession: Session) {
        if (event.typeOfEvent === SessionEventTypeId.RACE_START) {
            if (event.raceType === EventRaceType.PRACTICE) {
                return SessionCurrentRaceTypeEnum.practice;
            } else {
                return SessionCurrentRaceTypeEnum.race;
            }
        }

        if (event.typeOfEvent === SessionEventTypeId.RACE_FINISH) {
            return SessionCurrentRaceTypeEnum.none;
        }

        if (event.typeOfEvent === SessionEventTypeId.TESTING) {
            if (event.isStart) {
                return SessionCurrentRaceTypeEnum.test;
            } else {
                return SessionCurrentRaceTypeEnum.none;
            }
        }

        return currentSession.raceType;
    }

    private getEventsWithAddedOrEditedEvent(
        currentSession: Session,
        event: SessionEvent
    ) {
        const events = currentSession?.events ?? [];
        const eventIndex = events.findIndex((f) => f.id === event.id);
        if (eventIndex > -1) {
            events.splice(eventIndex, 1, event);
        } else {
            events.splice(0, 0, event);
        }
        return events;
    }

    public readonly editEvent = this.effect(
        (trigger$: Observable<[any, SessionEvent]>) =>
            trigger$.pipe(
                withLatestFrom(
                    this.select((x) => x.currentSession).pipe(filterEmpty())
                ),
                switchMap(([[typeOfEvent, sessionEvent], inputSession]) =>
                    from(
                        this.eventService.createEvent(
                            typeOfEvent,
                            this.form,
                            inputSession,
                            sessionEvent,
                            false,
                            true
                        )
                    ).pipe(
                        filterEmpty(),
                        withLatestFrom(
                            this.select((x) => x.currentSession),
                            this.select((x) =>
                                x.currentSession?.events.find(
                                    (f) =>
                                        f.typeOfEvent ===
                                        SessionEventTypeId.START_SESSION
                                )
                            ) ?? null
                        ),
                        tap<[SessionEvent, Session, SessionEvent]>(
                            async ([event, session, sessionStart]) => {
                                if (
                                    differenceInMilliseconds(
                                        dateStringAsDate(
                                            sessionStart.dateTimeUtc
                                        ),
                                        dateStringAsDate(event.dateTimeUtc)
                                    ) > 0
                                ) {
                                    await this.modalService.createDialog(
                                        PopupComponent,
                                        {
                                            icon: ImageEnum.alert,
                                            title: 'Error',
                                            text: 'The selected time cannot be before the Start session.',
                                            buttons: [
                                                {
                                                    text: 'Ok',
                                                    role: 'select',
                                                    color: 'primary'
                                                }
                                            ]
                                        }
                                    );
                                } else {
                                    const updatedEvent = {
                                        ...event,
                                        id: sessionEvent.id
                                    };
                                    const index = session.events.findIndex(
                                        (x) => x === sessionEvent
                                    );
                                    session.events.splice(
                                        index,
                                        1,
                                        updatedEvent
                                    );
                                    this.patchState({
                                        currentSession: session
                                    });
                                    this.saveSession();
                                }
                            }
                        )
                    )
                )
            )
    );

    public readonly saveDate = this.effect(
        (trigger$: Observable<SessionEvent>) =>
            trigger$.pipe(
                withLatestFrom(
                    this.select((x) => x.currentSession).pipe(filterEmpty())
                ),
                switchMap(([updatedEvent, session]) => {
                    const index = session.events.findIndex(
                        (x) => x === updatedEvent
                    );
                    session.events.splice(index, 1, updatedEvent);
                    session.events.sort(
                        (a, b) =>
                            new Date(b.dateTimeUtc).getTime() -
                            new Date(a.dateTimeUtc).getTime()
                    );
                    this.patchState({ currentSession: session });
                    this.saveSession();
                    return of(true);
                })
            )
    );

    public readonly registerStartSessionEvent = this.effect(
        (trigger$: Observable<Readonly<Session>>) =>
            trigger$.pipe(
                switchMap((session) =>
                    this.dataService.getBoatById$(session.boat).pipe(
                        take(1),
                        tap((boat) => {
                            this.form.reset();
                            this.sailsForm.setValue([]);
                            this.staticPropertiesForm.clear();
                            this.changeablePropertiesForm.clear();

                            this.form.patchValue(session);

                            this.setProperties(session, boat);

                            // Set boat data
                            this.form.controls.sails.patchValue(session.sails);
                            this.form.controls.boat.patchValue(session.boat);
                            this.form.controls.status.patchValue(
                                StatusEnum.active
                            );

                            this.patchState({
                                currentBoat: session.boat,
                                currentSession: session,
                                currentSessionId: session.id
                            });
                        })
                    )
                ),
                tap(() => {
                    this.saveSession();
                }),
                tap(() => this.showActiveSessionModal())
            )
    );

    public readonly removeEvent = this.effect(
        (trigger$: Observable<Readonly<SessionEvent>>) =>
            trigger$.pipe(
                withLatestFrom(
                    this.select((x) => x.currentSession).pipe(filterEmpty())
                ),
                tap(([toBeRemoved, currentSession]) =>
                    this.patchState({
                        currentSession: {
                            ...currentSession,
                            events: currentSession.events.filter(
                                (event) => event.id !== toBeRemoved.id
                            )
                        }
                    })
                ),
                tap(() => this.saveSession())
            )
    );

    public readonly registerSessionEvent = this.effect(
        (trigger$: Observable<Readonly<SessionEvent>>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.currentSession)),
                tap(([input, session]) =>
                    this.patchState({
                        currentSession: {
                            ...session,
                            events: [input, ...session.events]
                        }
                    })
                )
            )
    );

    public readonly markPassed = this.effect(
        (trigger$: Observable<number | null>) =>
            trigger$.pipe(
                withLatestFrom(
                    this.select((x) => x.currentSession).pipe(filterEmpty()),
                    this.currentlyRunningEditableEvent$.pipe(filterEmpty())
                ),
                switchMap(([inputMark, currentSession, lastEvent]) => {
                    const newMark = inputMark
                        ? inputMark
                        : currentSession.currentMark + 1;
                    // Check if the race is finished = all marks reached
                    if (newMark >= Number(lastEvent.marks)) {
                        this.patchState({
                            currentSession: {
                                ...currentSession,
                                paused: true
                            }
                        });
                        return from(
                            this.modalService.createDialog(
                                ConfirmRaceFinishComponent,
                                {
                                    isPractice:
                                        lastEvent.raceType ===
                                        EventRaceType.PRACTICE
                                }
                            )
                        ).pipe(
                            tap((result) => {
                                if (result.role === 'finish') {
                                    this.form.patchValue({
                                        raceType:
                                            SessionCurrentRaceTypeEnum.none,
                                        raceTimerStartedAt: null
                                    });
                                    this.patchState({
                                        currentSession: {
                                            ...currentSession,
                                            raceType:
                                                SessionCurrentRaceTypeEnum.none,
                                            raceTimerStartedAt: null,
                                            currentMark: 0,
                                            events: [
                                                {
                                                    id: uuidv4(),
                                                    dateTimeUtc:
                                                        new Date().getTime(),
                                                    typeOfEvent:
                                                        SessionEventTypeId.RACE_FINISH,
                                                    raceType:
                                                        lastEvent.raceType,
                                                    raceNumber:
                                                        currentSession.raceNumber,
                                                    comment: {
                                                        mediaFiles: [],
                                                        comment: `Finish race`
                                                    }
                                                },
                                                ...currentSession.events
                                            ],
                                            paused: false
                                        }
                                    });

                                    this.saveSession();
                                } else {
                                    this.patchState({
                                        currentSession: {
                                            ...currentSession,
                                            paused: false
                                        }
                                    });
                                }
                            })
                        );
                    }

                    this.patchState({
                        currentSession: {
                            ...currentSession,
                            currentMark: newMark,
                            events: [
                                {
                                    id: uuidv4(),
                                    dateTimeUtc: new Date().getTime(),
                                    typeOfEvent: SessionEventTypeId.RACE_MARK,
                                    raceNumber: currentSession.raceNumber,
                                    mark: newMark,
                                    comment: undefined
                                },
                                ...currentSession.events
                            ]
                        }
                    });
                    this.saveSession();
                    return of(null);
                })
            )
    );

    public readonly clearHistory = this.effect(
        (trigger$: Observable<SessionEventTypeId>) =>
            trigger$.pipe(
                withLatestFrom(
                    this.select((x) => x.currentSession).pipe(filterEmpty())
                ),
                tap<[SessionEventTypeId, Readonly<Session>]>(
                    ([eventType, session]) =>
                        this.patchState({
                            currentSession: {
                                ...session,
                                events: session.events.map((event) => {
                                    if (event.typeOfEvent === eventType) {
                                        return {
                                            ...event,
                                            hide: true
                                        };
                                    }

                                    return event;
                                })
                            }
                        })
                ),
                tap(() => this.saveSession())
            )
    );

    public readonly export = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            tap(() =>
                this.modalService.createModalAndWaitForDismiss(
                    ExportSessionPage
                )
            )
        )
    );

    private readonly componentDestroyed$ = new Subject<void>();

    constructor(
        private readonly dataService: DataService,
        private readonly modalController: ModalController,
        private readonly formBuilder: FormBuilder,
        private readonly eventService: EventService,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly propertyService: PropertyService,
        private readonly sessionService: SessionService,
        private readonly sailService: SailService,
        private readonly boatStore: BoatStore
    ) {
        super({
            currentBoat: undefined,
            currentSession: undefined,
            currentSessionId: undefined
        });

        this.form = this.sessionService.createFormGroup();

        this.sailsForm = this.form.controls.sails;
        this.staticPropertiesForm = this.form.get(
            'staticProperties'
        ) as FormArray<FormGroup<PropertyForm>>;
        this.changeablePropertiesForm = this.form.get(
            'changeableProperties'
        ) as FormArray;

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                distinctUntilChanged(),
                debounceTime(200),
                tap(() => {
                    this.select((x) =>
                        this.patchState({
                            currentSession: {
                                ...x.currentSession,
                                ...this.form.getRawValue()
                            }
                        })
                    );

                    this.detailHasErrorsSubject$.next(
                        !(
                            this.form.get('name')?.valid &&
                            this.form.get('startDate')?.valid &&
                            this.form.get('typeOfSession')?.valid
                        )
                    );
                    this.boatConfigHasErrorsSubject$.next(
                        !(
                            this.staticPropertiesForm.length > 0 &&
                            this.staticPropertiesForm.controls.filter(
                                (x) =>
                                    !isNull(x.get('valueCurrent')?.value) &&
                                    x.get('valueCurrent')?.value !== valueNotSet
                            ).length === this.staticPropertiesForm.length
                        )
                    );
                })
            )
            .subscribe();

        combineLatest([this.dataService.sessions$, this.currentSessionId$])
            .pipe(
                takeUntil(this.componentDestroyed$),

                tap(([sessions, id]) =>
                    this.patchState({
                        currentSession: sessions.find((f) => f.id === id)
                    })
                )
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public getEventTypeHistory(type: SessionEventTypeId) {
        return this.select((x) => x.currentSession).pipe(
            filterEmpty(),
            map((session) => session.events),
            map((events) =>
                events.filter(
                    (event) => event.typeOfEvent === type && !event.hide
                )
            )
        );
    }

    public getDisplayDates(
        sDate?: string | Date | number,
        eDate?: string | Date | number
    ) {
        if (typeof sDate === 'number') {
            sDate = new Date(sDate);
        }

        if (typeof eDate === 'number') {
            eDate = new Date(eDate);
        }

        if (sDate === null && eDate === null) {
            return '';
        }

        const startDate = new Date(sDate || Date.now());
        if (!eDate) {
            return format(startDate, 'dd MMM, yyyy');
        }

        const endDate = new Date(eDate || Date.now());

        if (getYear(startDate) !== getYear(endDate)) {
            return (
                format(startDate, 'dd MMM, yyyy') +
                ' - ' +
                format(endDate, 'dd MMM, yyyy')
            );
        }

        if (getMonth(startDate) !== getMonth(endDate)) {
            return (
                format(startDate, 'dd MMM') +
                ' - ' +
                format(endDate, 'dd MMM') +
                ', ' +
                getYear(startDate)
            );
        }
        return (
            format(startDate, 'dd') +
            ' - ' +
            format(endDate, 'dd') +
            ' ' +
            format(startDate, 'MMM') +
            ', ' +
            getYear(startDate)
        );
    }

    public createProperty = (property: Readonly<Property>) =>
        this.propertyService.propertyToFormGroup(property, true);

    private addStaticBoatProperty = (property: Readonly<Property>) =>
        this.staticPropertiesForm.push(this.createProperty(property));

    private addChangeableProperty = (property: Readonly<Property>) => {
        const control = this.createProperty(property);
        return this.changeablePropertiesForm.push(control);
    };

    private showActiveSessionModal() {
        this.router.navigate(['sessions/session']);
    }

    private showSessionModal(step?: number) {
        const steps = [
            SessionBoatPage,
            SessionDetailPage,
            SessionSailPage,
            SessionBoatConfigurationPage,
            SessionOverviewPage
        ];
        const helpButtons = [
            DataMarkdownEnum.helpSessionBoat,
            DataMarkdownEnum.helpSessionDetail,
            DataMarkdownEnum.helpSailInventory,
            DataMarkdownEnum.helpBoatProperty,
            DataMarkdownEnum.helpSessionOverview
        ];
        return from(
            this.modalController.create({
                component: WizardComponent,
                componentProps: {
                    steps,
                    helpButtons,
                    border: true,
                    selectedStep: step ?? 0
                }
            })
        ).pipe(
            switchMap((modal) =>
                from(modal.present()).pipe(
                    switchMap(() => from(modal.onWillDismiss()))
                )
            )
        );
    }

    private showSessionOverview() {
        this.router.navigate(['sessions/overview']);
        return of(true);
        //   this.modalController.create({
        //     component: SessionEditOverviewPage,
        //   })
        // ).pipe(
        //   switchMap((modal) =>
        //     from(modal.present()).pipe(switchMap(() => from(modal.onWillDismiss())))
        //   )
        // );
    }

    private getCopyName(sessionId: string, sessions: readonly Session[]) {
        let suffix = 1;
        const sessionToCopy = sessions.find(
            (session) => session.id === sessionId
        );
        if (!sessionToCopy) {
            return '';
        }
        while (
            !!sessions.find(
                (session) =>
                    session.name === sessionToCopy.name + '(' + suffix + ')'
            )
        ) {
            suffix++;
        }
        return sessionToCopy.name + '(' + suffix + ')';
    }
}
