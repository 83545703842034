import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ImageEnum, Property } from 'src/app/shared';

@Component({
    selector: 'app-property-config',
    templateUrl: './property-config.component.html',
    styleUrls: ['./property-config.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyConfigComponent {
    @Input() properties: ReadonlyArray<Property> | null;
    @Input() hasErrors: boolean | null;
    @Input() imageSource: ImageEnum | null = null;
    @Input() title: string | null = null;
    @Input() subTitle: string | null = null;

    @Output() goToNextPage = new EventEmitter();
    @Output() addProperty = new EventEmitter();
    @Output() editProperty = new EventEmitter<{
        index: number;
        property: Property;
    }>();

    public readonly imageEnum = ImageEnum;
    constructor() {}

    isPropertyString = (property: Property): boolean => {
        return property.typeOfProperty === 'LIST_OF_VALUES';
    };

    isPropertyNumber = (property: Property): boolean => {
        return property.typeOfProperty === 'NUMBER';
    };
}
