import { Injectable } from '@angular/core';
import { mapNumberToBoatType } from '../../../data/TypeOfBoat';
import { mapNumberToSailGroupType } from '../../../data/TypeOfSailGroup';
import { mapNumberToSailingType } from '../../../data/TypeOfSailing';
import {
    AudioMediaFile,
    Boat,
    ImageMediaFile,
    MediaFile,
    Property,
    PropertyType,
    SessionCurrentRaceTypeEnum,
    Sail,
    Session,
    SessionEvent,
    SessionEventTypeId,
    VideoMediaFile, EventRaceType
} from '../../models/index';
import {
    AudioMediaFileDto,
    BalanceEventDto,
    BoatDto,
    BoatDtoForSession,
    CalibrationEventDto,
    CancelledEventDto,
    CommentEventDto,
    FinishEventDto,
    ImageMediaFileDto,
    IssueEventDto,
    ListOfValuesPropertyDto,
    MarkEventDto,
    MediaFileDto,
    NumberPropertyDto,
    PropertyChangeEventDto,
    PropertyDefinitionDto,
    PropertyDto,
    RaceTypeDto,
    RacingEventDto,
    SailDto,
    SailUpEventDto,
    SessionDto,
    SessionEventDto,
    StartSessionEventDto,
    StopSessionEventDto,
    TestingEventDto,
    VideoMediaFileDto
} from './dtos';

@Injectable({
    providedIn: 'root'
})
export class ModelToDtoService {
    sessionModelToDto(session: Session, boat: Boat): SessionDto {
        return {
            boat: this.boatModelToDtoForSessionJson(boat),
            changeablePropertiesDefinition: this.toPropertyDefinitionDtos(
                session.changeableProperties
            ),
            sessionPropertiesDefinition: this.toPropertyDefinitionDtos(
                session.staticProperties
            ),
            sessionProperties: this.toSessionProperties(
                session.staticProperties
            ),
            startDateTimeUtc: new Date(
                session.events.find(
                    (event) =>
                        event.typeOfEvent === SessionEventTypeId.START_SESSION
                ).dateTimeUtc
            ).toISOString(),
            endDateTimeUtc: new Date(session.endDate).toISOString(),
            events: this.eventsToDto(session.events),
            group: session.group,
            id: session.id,
            name: session.name,
            sails: this.createSailsDto(session.sails, boat),
            typeOfSession: session.typeOfSession
        };
    }

    public boatModelToDto(boat: Boat): BoatDto {
        return {
            canAsymmetricalSails: boat.canAsymmetricalSails,
            canFoil: boat.canFoil,
            canPlane: boat.canPlane,
            displacementKg: boat.displacement,
            downwind: boat.downwind,
            id: boat.id,
            // image: boat.image,
            lengthFt: boat.length_ft,
            name: boat.name,
            numberOfMasts: boat.numberOfMasts,
            typeOfBoat: mapNumberToBoatType(boat.typeOfBoat),
            typeOfSailing: boat.typeOfSailing
                ? mapNumberToSailingType(boat.typeOfSailing)
                : undefined,
            upwind: boat.upwind,
            sails: this.createSailsDtoFromBoat(boat),
            changeablePropertiesDefinition: this.toPropertyDefinitionDtos(
                boat.properties.filter((prop) => prop.canBeChangedWhileSailing)
            ),
            sessionPropertiesDefinition: this.toPropertyDefinitionDtos(
                boat.properties.filter((prop) => !prop.canBeChangedWhileSailing)
            )
        };
    }

    private boatModelToDtoForSessionJson(boat: Boat): BoatDtoForSession {
        return {
            canAsymmetricalSails: boat.canAsymmetricalSails,
            canFoil: boat.canFoil,
            canPlane: boat.canPlane,
            displacementKg: boat.displacement,
            downwind: boat.downwind,
            id: boat.id,
            // image: boat.image,
            lengthFt: boat.length_ft,
            name: boat.name,
            numberOfMasts: boat.numberOfMasts,
            typeOfBoat: mapNumberToBoatType(boat.typeOfBoat),
            typeOfSailing: boat.typeOfSailing
                ? mapNumberToSailingType(boat.typeOfSailing)
                : undefined,
            upwind: boat.upwind
        };
    }

    private toPropertyDefinitionDtos(
        changeableProperties: Property[]
    ): PropertyDefinitionDto[] {
        return changeableProperties?.map((prop) =>
            this.toPropertyDefinitionDto(prop)
        );
    }

    private toPropertyDefinitionDto(property: Property): PropertyDefinitionDto {
        const propertDefinitionDto = {
            id: property.id,
            comments: property.comments,
            propertyName: property.propertyName,
            typeOfProperty: property.typeOfProperty
        };

        if (property.typeOfProperty === PropertyType.LIST_OF_VALUES) {
            const listOfValuePropertyDefinition: ListOfValuesPropertyDto = {
                ...propertDefinitionDto,
                propertyValues: property.propertyValues
            };
            return listOfValuePropertyDefinition;
        } else {
            const numberPropertyDto: NumberPropertyDto = {
                ...propertDefinitionDto,
                increment: property.increment,
                maxBound: property.maxBound,
                minBound: property.minBound
            };
            return numberPropertyDto;
        }
    }

    private eventsToDto(events: SessionEvent[]): SessionEventDto[] {
        const eventDtos = events.map((event) => this.eventToDto(event));
        eventDtos.sort((event1, event2) =>
            new Date(event1.dateTimeUtc) < new Date(event2.dateTimeUtc) ? -1 : 1
        );
        return eventDtos;
    }

    private eventToDto(event: SessionEvent): SessionEventDto {
        switch (event.typeOfEvent) {
            case SessionEventTypeId.SAIL_UP:
                return this.sailUpEventToDto(event);
            case SessionEventTypeId.PROPERTY:
                return this.propertyEventToDto(event);
            case SessionEventTypeId.COMMENT:
                return this.commentEventToDto(event);
            case SessionEventTypeId.ISSUE:
                return this.issueEventToDto(event);
            case SessionEventTypeId.BALANCE:
                return this.balanceEventToDto(event);
            case SessionEventTypeId.CALIBRATION:
                return this.calibrationEventToDto(event);
            case SessionEventTypeId.RACE_START:
                return this.raceStartEventToDto(event);
            case SessionEventTypeId.TESTING:
                return this.testingEventToDto(event);
            case SessionEventTypeId.RACE_MARK:
                return this.raceMarkEventToDto(event);
            case SessionEventTypeId.RACE_FINISH:
                return this.raceFinishEventToDto(event);
            case SessionEventTypeId.CANCELLED:
                return this.cancelledEventToDto(event);
            case SessionEventTypeId.START_SESSION:
                return this.startSessionEventToDto(event);
            case SessionEventTypeId.STOP_SESSION:
                return this.stopSessionEventToDto(event);
            default:
                return undefined;
        }
    }

    private toEventDto(event: SessionEvent) {
        return {
            id: event.id,
            comment: event.comment?.comment,
            dateTimeUtc: new Date(event.dateTimeUtc).toISOString(),
            eventOption: event.eventOption,
            mediaFiles: this.toMediaFileDtos(event.comment?.mediaFiles)
        };
    }

    private sailUpEventToDto(event: SessionEvent): SailUpEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.SAIL_UP,
            sailsUp: event.sailsUp
        };
    }

    private propertyEventToDto(event: SessionEvent): PropertyChangeEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.PROPERTY,
            property: this.toProperty(event.property)
        };
    }

    private toProperty(propery: Property): PropertyDto {
        return {
            name: propery.propertyName,
            value: propery.valueCurrent
        };
    }

    private commentEventToDto(event: SessionEvent): CommentEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.COMMENT
        };
    }

    private issueEventToDto(event: SessionEvent): IssueEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.ISSUE
        };
    }

    private balanceEventToDto(event: SessionEvent): BalanceEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.BALANCE
        };
    }

    private calibrationEventToDto(event: SessionEvent): CalibrationEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.CALIBRATION
        };
    }

    private raceStartEventToDto(event: SessionEvent): RacingEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.RACE_START,
            raceNumber: event.raceNumber,
            raceType: this.toRaceTypeDto(event.raceType)
        };
    }

    private testingEventToDto(event: SessionEvent): TestingEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.TESTING,
            testdurationSec: event.duration
        };
    }

    private raceMarkEventToDto(event: SessionEvent): MarkEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.RACE_MARK,
            mark: event.mark
        };
    }

    private raceFinishEventToDto(event: SessionEvent): FinishEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.RACE_FINISH
        };
    }

    private cancelledEventToDto(event: SessionEvent): CancelledEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.CANCELLED
        };
    }

    private startSessionEventToDto(event: SessionEvent): StartSessionEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.START_SESSION
        };
    }

    private stopSessionEventToDto(event: SessionEvent): StopSessionEventDto {
        return {
            ...this.toEventDto(event),
            typeOfEvent: SessionEventTypeId.STOP_SESSION
        };
    }

    private createSailsDto(sails: string[], boat: Boat): SailDto[] {
        return sails.map((sail) => this.createSailDto(sail, boat));
    }

    private createSailsDtoFromBoat(boat: Boat): SailDto[] {
        return boat.sails.map((sail) => this.createSailDtoFromSail(sail));
    }

    private createSailDto(sail: string, boat: Boat): SailDto {
        const sailInBoat = boat.sails.find((thisSail) => thisSail.id === sail);
        return {
            id: sailInBoat.id,
            name: sailInBoat.name,
            reefs: sailInBoat.reefs,
            typeOfSail: mapNumberToSailGroupType(sailInBoat.typeOfSail),
            weightKg: sailInBoat.weight
        };
    }

    private createSailDtoFromSail(sail: Sail): SailDto {
        return {
            id: sail.id,
            name: sail.name,
            reefs: sail.reefs,
            typeOfSail: mapNumberToSailGroupType(sail.typeOfSail),
            weightKg: sail.weight
        };
    }

    private toSessionProperties(staticProperties: Property[]): PropertyDto[] {
        return staticProperties.map((prop) => this.toProperty(prop));
    }

    private toMediaFileDtos(
        mediaFiles: Array<AudioMediaFile | VideoMediaFile | ImageMediaFile>
    ) {
        return mediaFiles?.map((file) => this.toMediaFileDto(file));
    }

    private toMediaFileDto(mediaFile: MediaFile): MediaFileDto {
        switch (mediaFile.type) {
            case 'AUDIO':
                return {
                    id: mediaFile.id,
                    dateTimeUtc: new Date(
                        mediaFile.creationTimestamp
                    ).toISOString(),
                    mimeType: mediaFile.mimeType,
                    size: mediaFile.size,
                    type: 'AUDIO',
                    durationSec: (mediaFile as AudioMediaFile).durationSec
                } as AudioMediaFileDto;
            case 'IMAGE':
                return {
                    id: mediaFile.id,
                    dateTimeUtc: new Date(
                        mediaFile.creationTimestamp
                    ).toISOString(),
                    mimeType: mediaFile.mimeType,
                    size: mediaFile.size,
                    type: 'IMAGE'
                } as ImageMediaFileDto;
            case 'VIDEO':
                return {
                    id: mediaFile.id,
                    dateTimeUtc: new Date(
                        mediaFile.creationTimestamp
                    ).toISOString(),
                    mimeType: mediaFile.mimeType,
                    size: mediaFile.size,
                    type: 'VIDEO',
                    durationSec: (mediaFile as VideoMediaFile).durationSec
                } as VideoMediaFileDto;
        }
    }

    private toRaceTypeDto(raceType: EventRaceType): RaceTypeDto {
        switch (raceType) {
            case EventRaceType.PRACTICE:
                return RaceTypeDto.PRACTICE_START;
            case EventRaceType.RACE:
                return RaceTypeDto.RACE;
        }
    }
}
