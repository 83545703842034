import { Markdown } from '../shared/index';
import { DataMarkdownEnum } from '../shared/models/data.model';

export const MARKDOWN: Record<DataMarkdownEnum, Markdown> = {
    SETTINGS_FAQ: {
        type: 'SETTINGS_FAQ',
        title: 'Frequently Asked Questions',
        markdown: '# FAQ'
    },
    SETTINGS_TOS: {
        type: 'SETTINGS_TOS',
        title: 'Terms of Service',
        markdown: '# TES'
    },
    SETTINGS_PRIVACY: {
        type: 'SETTINGS_PRIVACY',
        title: 'Privacy Policy',
        markdown: '# Privacy Policy'
    },
    HELP_MYBOATS: {
        type: 'HELP_MYBOATS',
        title: 'My Boats',
        markdown:
            'Create a boat profile which will be associated to one or more sailing sessions.<br/>A boat profile contains information about your boat, but also about your sail inventory, and properties which you can create for your boat and for your sailing session.',
        color: 'blue'
    },
    HELP_BOATDETAILS: {
        type: 'HELP_BOATDETAILS',
        title: 'Boat Details',
        markdown:
            'Select the field which best describes your usage of the boat.',
        color: 'blue'
    },
    HELP_COMMENT: {
        type: 'HELP_COMMENT',
        title: 'Comment',
        markdown:
            'Use this page drop any notes and observations.<br/>You can also use it to store photos, videos and voice memos.',
        color: 'blue'
    },
    HELP_SESSIONS: {
        type: 'HELP_SESSIONS',
        title: 'Sailing Sessions',
        markdown:
            'A sailing session could be as short as a couple of hours on the racecourse or as long as an ocean race leg. This is where you will be able to quickly take all the notes for<br>- Sail changes<br>- Race starts and mark roundings<br>- Technical issues<br>- Changes in user defined values<br>- Testing phases<br>- ...<br>To each of these events you will easily add a photo, video, voice memo, or drop down some notes if you want. They will later enhance your post-analysis while looking at performance and will let you create rich reports automatically.',
        color: 'blue'
    },
    HELP_ADDSAIL: {
        type: 'HELP_ADDSAIL',
        title: 'Add Sail',
        markdown: '# Add Sail',
        color: 'blue'
    },
    HELP_EDITRACE: {
        type: 'HELP_EDITRACE',
        title: 'Edit Race',
        markdown: '# Edit Race',
        color: 'blue'
    },
    HELP_EVENTOVERVIEW: {
        type: 'HELP_EVENTOVERVIEW',
        title: 'Event Overview',
        markdown: '# Event Overview',
        color: 'blue'
    },
    HELP_ACTIVEEVENTS: {
        type: 'HELP_ACTIVEEVENTS',
        title: 'Active Events',
        markdown:
            'You have now started a session. While you are sailing, use the app to record sail changes, technical problems, race start sequences, testing phases and any other type of comment. To each event that you log, you can associate a photo, a video or just an audio recording.',
        color: 'blue'
    },
    HELP_EXPORTSESSION: {
        type: 'HELP_EXPORTSESSION',
        title: 'Export Session',
        markdown: '# Export Session',
        color: 'blue'
    },
    HELP_STARTRACE: {
        type: 'HELP_STARTRACE',
        title: 'Start Race',
        markdown:
            'Use this feature to set up race start times and to ping mark roundings and race finish.',
        color: 'blue'
    },
    HELP_SETTINGS: {
        type: 'HELP_SETTINGS',
        title: 'Settings',
        markdown: '# Settings',
        color: 'blue'
    },
    HELP_SESSIONBOAT: {
        type: 'HELP_SESSIONBOAT',
        title: 'Boat',
        markdown:
            'Select which boat is being used for the new sailing session.',
        color: 'blue'
    },
    HELP_SESSIONDETAIL: {
        type: 'HELP_SESSIONDETAIL',
        title: 'Session Detail',
        markdown:
            'Enter details for your session. Example of session names:<br>- Day 1 2022 Big boat series<br>- Training 2 San Francisco bay<br>The start date should be the day that the session starts, even if it will run over multiple days.<br>If you are using the app for multiple synchronous sessions make sure to give all the sessions the same meaningful Group name.',
        color: 'blue'
    },
    HELP_SAILINVENTORY: {
        type: 'HELP_SAILINVENTORY',
        title: 'Sail Inventory',
        markdown:
            "List your entire sail inventory of all the sails which you could take onboard during a sailing session. For each declared sail try to keep the sail name short but significative. When you have multiple versions of the same sail you could add a year suffix to distinguish them (eg: if you have more than one mainsail, call them Mn2019 and Mn2022). When multiple types could be applied to the same sail (eg: Headsail and Staysail) select the one for which the sail is used the most. A sail which can be used both upwind and downwind should be labelled as 'Headsail'.",
        color: 'blue'
    },
    HELP_BOATPROPERTY: {
        type: 'HELP_BOATPROPERTY',
        title: 'Configuration',
        markdown:
            'All properties of the boat are configured here. There are properties that can be changed during a session and those that can not be changed. Properties that can not be changed may correspond to boat setup (such as which mast is in use, which foils are on the boat, etc…). They could things like<br>- be how many crew are onboard<br>- whether the boat is in a specified configuration (light/heavy)<br>- What rating the boat is at<br>- which specific gear might be in use during the session (which foils)<br>A sailing session property can either be defined as a list of text (eg: Foils_v1, Foils_v2), or as a numerical value with a range and steps. Create properties for things that correspond to criterias that you will either want to compare performance for (compare two-handed sailing versus full-crew) or that will be used for filtering (creating an inshore versus offshore polar).<br>Changeable variables are values which will be logged manually during the session through this app when they change. They need to be entered manually because, although they are important for performance, there are no sensors in the electronics for them. Examples of such variables could be:<br>- Mast rake<br>- Gear stacking (fore/aft/centered)<br>- Ballast level % (if there is no sensor)<br>- Wind shear (low/high)<br>- Helmsman<br>A manual entry variable can either be defined as a list of text (eg: Foils_v1, Foils_v2), or as a numerical value with a range and steps.',
        color: 'blue'
    },
    HELP_SESSIONOVERVIEW: {
        type: 'HELP_SESSIONOVERVIEW',
        title: 'Session Overview',
        color: 'blue',
        markdown: ''
    },
    HELP_BOATDETAIL: {
        type: 'HELP_BOATDETAIL',
        title: 'Boat Detail',
        markdown:
            'Select the field which best describes your usage of the boat.',
        color: 'blue'
    },
    HELP_BOATOVERVIEW: {
        type: 'HELP_BOATOVERVIEW',
        title: 'Boat Overview',
        markdown: '# Boat Overview',
        color: 'blue'
    },
    HELP_CUSTOMPROPERTY: {
        type: 'HELP_CUSTOMPROPERTY',
        title: 'User var',
        markdown: 'Use this page to set values for your user variables.',
        color: 'blue'
    },
    HELP_COMMENT_TECHISSUE: {
        type: 'HELP_COMMENT_TECHISSUE',
        title: 'Incident',
        markdown:
            'Use this page to quickly mark down the time and type of any incident. You can also add notes, photos, videos and voice memos.',
        color: 'blue'
    },
    HELP_COMMENT_BALANCE: {
        type: 'HELP_COMMENT_BALANCE',
        title: 'Performance',
        markdown:
            'Use this feature to ping specific moments when the boat was performing well, or badly. This will later allow you go back to these moments and investigate what were the causes of good/bad performance.',
        color: 'blue'
    },
    HELP_COMMENT_CALIBRATION: {
        type: 'HELP_COMMENT_CALIBRATION',
        title: 'Calibration',
        markdown:
            "Use this feature to note down times where you changed a calibration setting in your electronics. This may prove useful when reviewing the day's calibration.",
        color: 'blue'
    },
    HELP_COMMENT_RACELEG: {
        type: 'HELP_COMMENT_RACELEG',
        title: 'Race',
        markdown:
            'Use this feature to set up race start times and to ping mark roundings and race finish.',
        color: 'blue'
    },
    HELP_COMMENT_TESTING: {
        type: 'HELP_COMMENT_TESTING',
        title: 'Testing',
        markdown:
            'Use this feature to record a phase of testing during your sailing session.',
        color: 'blue'
    }
};
