<app-layout>
    <div toolbarContent>
        <h4 *ngIf="currentImage?.uploadedImageBase64">Edit photo</h4>
        <h4 *ngIf="!currentImage?.uploadedImageBase64">Add photo</h4>
    </div>

    <ion-button button fill="clear" (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>

    <ion-button fill="clear" (click)="takeOrUploadImage()">
        <ion-icon slot="start" name="camera-outline"></ion-icon>
        Upload or take photo
    </ion-button>

    <div class="flex w-full justify-center">
        <image-cropper
            class="image-cropper"
            [aspectRatio]="90 / 109"
            [imageBase64]="imageBase64 | async"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
    </div>

    <ion-footer class="ion-no-border normal-footer pt-[10px] pb-[20px]">
        <ion-button
            [disabled]="!croppedImageBase64"
            shape="round"
            expand="full"
            (click)="addPhoto()"
            >Save photo
        </ion-button>
        <ion-button
            *ngIf="currentImage?.uploadedImageBase64"
            shape="round"
            expand="full"
            (click)="removePhoto()"
            class="cancel__button"
            >Remove photo
        </ion-button>
    </ion-footer>
</app-layout>
