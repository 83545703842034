import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnInit
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    Validator,
    Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { valueNotSet } from 'src/app/shared';
import {
    ModalListItem,
    ModalRolesEnum
} from 'src/app/shared/models/modelList.model';
import { ModalListComponent } from '../../modal-list/modal-list.component';

@Component({
    selector: 'app-input-select',
    templateUrl: 'input-select.component.html',
    styleUrls: ['input-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ObaInputSelectComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: ObaInputSelectComponent
        }
    ]
})
export class ObaInputSelectComponent
    implements ControlValueAccessor, Validator, OnInit
{
    @Input() public title = 'Label';
    @Input() list: Array<ModalListItem> | null | undefined;
    @Input() public setValueNotSet = false;

    @Input() listChanged$: Observable<Array<ModalListItem | null | undefined>>;

    private formControl: FormControl;

    public disabled: boolean;
    public value: string | null = '';
    public valueText: string | null = '';

    public onChange: (value: any) => void = () => {};
    public onTouched: () => void = () => {};

    public valueNotSet = valueNotSet;

    public isOpen = false;
    public css = '';

    public constructor(
        private readonly changeDetectionRef: ChangeDetectorRef,
        private readonly modalService: ModalService,
        private readonly injector: Injector
    ) {}

    public get isRequired(): boolean {
        return this.formControl.hasValidator(Validators.required);
    }

    public ngOnInit() {
        const ngControl = this.injector.get(NgControl);

        if (ngControl instanceof FormControlName) {
            this.formControl = this.injector
                .get(FormGroupDirective)
                .getControl(ngControl);
        } else {
            this.formControl = (ngControl as FormControlDirective)
                .form as FormControl;
        }
        this.listChanged$?.subscribe((newList) => {
            if (
                this.formControl &&
                !newList.find((item) => item.id === this.formControl.value)
            ) {
                this.formControl.setValue(undefined);
            }
        });
    }

    public writeValue(value: any): void {
        this.value = value;
        this.valueText = this.getValueText();
    }

    public registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public validate(_control: AbstractControl) {
        if (_control.valid) {
            return null;
        }

        return _control.errors;
    }

    public async selectOpen() {
        const data = await this.modalService.createModalAndWaitForDismiss(
            ModalListComponent,
            {
                title: `Select ${this.title.toLocaleLowerCase()}(s)`,
                hasClear: true,
                list: this.list
            }
        );

        if (data.role === ModalRolesEnum.clear) {
            this.value = null;
            this.onChange(this.value);
        }

        if (data.role === ModalRolesEnum.select) {
            this.value = data?.data?.id;
            this.onChange(this.value);
        }

        this.valueText = this.getValueText();
        this.changeDetectionRef.detectChanges();
    }

    private getValueText(): string | null {
        const textValue =
            this.list?.find((item) => item.id === this.value) ?? null;
        //   ?? {
        //   id: valueNotSet,
        // };

        // return isNullOrEmpty(textValue)
        //   ? valueNotSet
        //   : textValue.name ?? textValue.id;
        return textValue?.name ?? textValue?.id ?? null;
    }

    public trackBy = (index: number) => index;
}
