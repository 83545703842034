<app-layout [border]="true" [formGroup]="form">
    <ion-button button fill="clear" (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <h4 toolbarContent>{{ title }} sail</h4>
    <app-help contextButton [helpType]="helpType.helpAddSail"></app-help>
    <app-error-box
        class="p-[10px]"
        stickyContent
        *ngIf="(errors$ | async).length > 0"
    >
        <span *ngFor="let error of errors$ | async">
            {{ error }}
        </span>
    </app-error-box>
    <ng-container>
        <app-input-text title="Name" type="text" formControlName="name">
        </app-input-text>
        <app-input-select
            formControlName="typeOfSail"
            title="Type"
            [list]="TYPE_OF_SAIL_GROUP | listItem"
        ></app-input-select>
        <app-input-simple-select
            title="Number of reefs"
            [options]="reefList"
            [formControl]="form.controls.reefs"
        >
        </app-input-simple-select>
        <app-weight-input
            [title]="sailWeightTitle$ | async"
            formControlName="weight"
            [isMetric]="unitService.isMetric$() | async"
        >
        </app-weight-input>
    </ng-container>

    <ion-footer>
        <ion-button
            (click)="addSail()"
            shape="round"
            expand="full"
            [disabled]="hasErrors$ | async"
            >{{ title }}</ion-button
        >
    </ion-footer>
</app-layout>
