import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BoatStore } from 'src/app/my-boats/current-boat-store.service';
import { SessionStore } from 'src/app/sessions/session.store';
import { Property, SessionEvent, valueNotSet } from 'src/app/shared';
import { PropertyForm } from '../../../services/boats/properties/property-form';
import { PropertyService } from '../../../services/boats/properties/property.service';

@Component({
    selector: 'app-event-change-property',
    templateUrl: './event-change-property.page.html',
    styleUrls: ['./event-change-property.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventChangePropertyPage implements OnInit {
    @Input()
    public sessionEvent: SessionEvent;

    public sessionChangeablePropertyForm: FormGroup<PropertyForm>;

    public valueNotSet = valueNotSet;

    constructor(
        public readonly modalController: ModalController,
        public readonly sessionStore: SessionStore,
        private readonly propertyService: PropertyService,
        public readonly myBoatsStore: BoatStore
    ) {}

    ngOnInit() {
        this.sessionChangeablePropertyForm =
            this.propertyService.propertyToFormGroup(
                this.sessionEvent.property,
                false
            );
    }

    saveProperty() {
        if (
            JSON.stringify(this.sessionEvent) !==
            JSON.stringify(this.sessionChangeablePropertyForm.getRawValue())
        ) {
            this.modalController.dismiss(
                this.toSessionEvent(
                    this.sessionChangeablePropertyForm.getRawValue()
                ),
                'save'
            );
        } else {
            this.modalController.dismiss();
        }
    }

    private toSessionEvent(rawValue: Property): SessionEvent {
        return {
            ...this.sessionEvent,
            property: rawValue
        };
    }
}
