import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WizardService } from 'src/app/services/wizard.service';
import { DataService, ImageEnum } from 'src/app/shared';
import { SessionStore } from '../../session.store';

@Component({
    selector: 'app-session-detail-page',
    templateUrl: './session-detail.page.html',
    styleUrls: ['./session-detail.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionDetailPage {
    public readonly imageEnum = ImageEnum;

    constructor(
        public readonly wizardService: WizardService,
        public readonly sessionStore: SessionStore,
        public readonly dataService: DataService
    ) {}

    public selectTrackBy(_index: number, item: { id: number }) {
        return item.id;
    }
}
