import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Markdown } from '../../../../shared/index';

@Component({
    selector: 'app-internal-markdown',
    templateUrl: './markdown-internal.component.html',
    styleUrls: ['./markdown-internal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MarkdownInternalComponent implements OnInit {

    @Input() markdownData: Markdown | null | undefined;

    constructor() {}

    ngOnInit() {}
}
