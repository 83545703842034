import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentService } from '../../../services/comment.service';
import {
    dateStringAsDate,
    SailUp,
    SESSION_EVENT_TYPES,
    SessionEvent
} from '../../../shared';
import { SessionStore } from '../../session.store';

@Component({
    selector: 'app-edit-sails-up-page',
    templateUrl: './edit-sails-up.page.html',
    styleUrls: ['./edit-sails-up.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSailsUpPage implements OnInit {
    @Input()
    public sailsUpEvent: SessionEvent;

    public modifiedEvent$: BehaviorSubject<SessionEvent>;

    public dateTimeUtc$: Observable<Date>;

    public readonly toggleSail = (sailToToggle: SailUp) => {
        const exists = this.modifiedEvent$.value.sailsUp.some(
            (sail) => sail.id === sailToToggle.id
        );
        if (exists) {
            this.modifiedEvent$.next({
                ...this.modifiedEvent$.value,
                sailsUp: this.modifiedEvent$.value.sailsUp.filter(
                    (sail) => sail.id !== sailToToggle.id
                )
            });
            return;
        }
        this.modifiedEvent$.next({
            ...this.modifiedEvent$.value,
            sailsUp: [...this.modifiedEvent$.value.sailsUp, sailToToggle]
        });
    };

    public readonly changeReef = (reefChange: {
        id: string;
        reef: number | null;
    }) => {
        const sailUp = this.modifiedEvent$.value.sailsUp.find(
            (sail) => sail.id === reefChange.id
        );
        if (sailUp) {
            this.modifiedEvent$.next({
                ...this.modifiedEvent$.value,
                sailsUp: [
                    ...this.modifiedEvent$.value.sailsUp.filter(
                        (sail) => sail.id !== reefChange.id
                    ),
                    {
                        ...sailUp,
                        reef: reefChange.reef
                    }
                ]
            });
        }
    };

    public readonly editComment = async () => {
        const event = await this.commentService.openComment(
            SESSION_EVENT_TYPES.SAIL_UP,
            new Date(this.modifiedEvent$.value.dateTimeUtc),
            this.modifiedEvent$.value
        );

        if (event && event.comment) {
            this.modifiedEvent$.next({
                ...this.modifiedEvent$.value,
                comment: event.comment
            });
        }
    };

    public readonly editDate = async () => {
        const changeDateResult = await this.commentService.changeDate(
            new Date(this.modifiedEvent$.value.dateTimeUtc)
        );

        if (changeDateResult.data) {
            this.modifiedEvent$.next({
                ...this.modifiedEvent$.value,
                dateTimeUtc: dateStringAsDate(changeDateResult.data).getTime()
            });
        }
    };

    public readonly editEvent = () => {
        this.modalController.dismiss(this.modifiedEvent$.value, 'save');
    };

    get sailsUp$(): Observable<SailUp[]> {
        return this.modifiedEvent$.pipe(map((event) => event.sailsUp));
    }

    constructor(
        private readonly modalController: ModalController,
        private readonly commentService: CommentService,
        public readonly sessionStore: SessionStore
    ) {}

    public ngOnInit() {
        this.modifiedEvent$ = new BehaviorSubject<SessionEvent>({
            ...cloneDeep(this.sailsUpEvent),
            comment: {
                ...this.sailsUpEvent.comment
            }
        });

        this.dateTimeUtc$ = this.modifiedEvent$.pipe(
            map((event) => new Date(event.dateTimeUtc))
        );
    }

    dismiss() {
        this.modalController.dismiss();
    }

    protected readonly of = of;
}
