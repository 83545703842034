export const environment = {
  production: false,
  appId: 'a11b646a-3941-4608-93a1-9b04bcb46d54',
  tenantId: '93906376-87ca-4bc4-aef8-952b1ac2c902',
  apiUrl: 'https://oba-api-main.azurewebsites.net/api/',
  // apiUrl: 'http://localhost:8080/api/',
  // apiUrl: 'https://bcd5-86-103-34-105.ngrok-free.app/api/',
  redirectUrl: 'http://localhost:4200',
  b2c: {
    android: {
      redirectUri: 'com.sailingperformance.obaapp://oauth/redirect',
    },
    ios: {
      redirectUri: 'msauth.com.sailingperformance.obaapp://auth',
    }
  },
  version: "1.0.0"
};
