import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Property, PropertyType, valueNotSet } from 'src/app/shared';
import { PropertyForm } from '../../../services/boats/properties/property-form';
import { PropertyService } from '../../../services/boats/properties/property.service';

@Component({
    selector: 'app-upsert-property',
    templateUrl: './upsert-property.component.html',
    styleUrls: ['./upsert-property.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpsertPropertyComponent implements OnInit {
    public form: FormGroup<PropertyForm>;
    public values: FormArray<FormControl<string>>;

    @Input()
    public title: string;
    @Input()
    public allProperties$: Observable<Readonly<Property[]>>;
    @Input()
    public data: Property;
    @Input()
    public edit: boolean;

    constructor(
        public readonly modalController: ModalController,
        private readonly fb: FormBuilder,
        private readonly formBuilder: FormBuilder,
        private readonly propertyService: PropertyService
    ) {}

    public ngOnInit(): void {
        this.form = this.fb.group<PropertyForm>(
            {
                id: this.formBuilder.control(this.data?.id),
                propertyName: this.formBuilder.nonNullable.control(
                    this.data?.propertyName,
                    Validators.required
                ),
                typeOfProperty: this.formBuilder.nonNullable.control(
                    this.data?.typeOfProperty ?? PropertyType.LIST_OF_VALUES,
                    [Validators.required]
                ),
                minBound: this.formBuilder.control(this.data?.minBound ?? 0, [
                    Validators.required
                ]),
                maxBound: this.formBuilder.control(this.data?.maxBound ?? 0, [
                    Validators.required
                ]),
                increment: this.formBuilder.control(this.data?.increment ?? 1, [
                    Validators.required
                ]),
                valueDefault: this.formBuilder.control(
                    this.data?.valueDefault ?? valueNotSet
                ),
                valueCurrent: this.formBuilder.control(this.data?.valueCurrent),
                propertyValues: this.formBuilder.array<string>([]),
                comments: this.formBuilder.array<string>([]),
                canBeChangedWhileSailing: this.formBuilder.nonNullable.control(
                    !!this.data?.canBeChangedWhileSailing
                )
            },
            {
                validators: [this.propertyService.numberPropertyValidator()]
            }
        );

        this.values = this.form.controls.propertyValues;
        this.values.clear();
        this.data?.propertyValues?.forEach((prop) =>
            this.values.push(new FormControl(prop))
        );
    }

    public addProperty() {
        this.modalController.dismiss(this.form);
    }
}
