import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Injector,
    INJECTOR,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    Validator,
    Validators
} from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { valueNotSet } from 'src/app/shared';
import { UnitService } from '../../../../settings/unit.service';

@Component({
    selector: 'app-weight-input',
    templateUrl: 'weight-input.component.html',
    styleUrls: ['weight-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: WeightInputComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: WeightInputComponent
        }
    ]
})
export class WeightInputComponent
    implements ControlValueAccessor, Validator, AfterViewChecked, OnInit
{
    @Input() public title = 'Label';
    @Input() public type: 'number' | 'decimal' = 'number';
    public inputmode: 'numeric' = 'numeric';
    @Input() public setValueNotSet = false;
    @Input() public focusOnInit = false;
    @Input() public isMetric = true;

    @Output() public editingDone: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('input') inputElement: IonInput;

    private _control: NgControl;

    public disabled: boolean;
    public value: number = null;
    public onChange: (value: any) => void = () => {};
    public onTouched: () => void = () => {};
    public valueNotSet = valueNotSet;

    constructor(@Inject(INJECTOR) private injector: Injector) {}

    public ngOnInit() {
        this._control = this.injector.get(NgControl);
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    public ngAfterViewChecked(): void {
        if (this.focusOnInit) {
            setTimeout(() => this.inputElement.setFocus(), 10);
        }
    }

    public get isInvalid(): boolean {
        return this._control.invalid;
    }

    public get isRequired(): boolean {
        return this._control.control.hasValidator(Validators.required);
    }

    public registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public validate(_control: AbstractControl) {
        if (_control.valid) {
            return null;
        }

        return _control.errors;
    }

    calculateValue() {
        if (this.value === null) {
            return undefined;
        }
        return (
            this.isMetric
                ? this.value
                : this.value * UnitService.KG_LB_CONVERSION
        ).toFixed(1);
    }

    valueChange(value) {
        if (value === '') {
            this.onChange(undefined);
            return;
        }

        let valueToCheck = Number(value.replace(',', '.'));
        if (isFinite(valueToCheck)) {
            this.onChange(
                this.isMetric
                    ? Number(value)
                    : Number(value) / UnitService.KG_LB_CONVERSION
            );
        } else {
            this.onChange(undefined);
        }
    }
}
