import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import { AlertService } from '../../../services/alert.service';
import { ModalService } from '../../../services/modal.service';
import { Authority, AuthStore, DataService, Session } from '../../../shared';
import { SessionStore } from '../../session.store';
import { ExportStatusPage } from '../export-status/export-status.page';

export interface ExportSessionPageState {
    checkedItems: ReadonlyArray<Session>;
}

@Component({
    selector: 'app-export-session',
    templateUrl: './export-session.page.html',
    styleUrls: ['./export-session.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportSessionPage extends ComponentStore<ExportSessionPageState> {
    public readonly checkedItems$ = this.select((x) => x.checkedItems);
    public readonly isEnabled$ = this.select((x) => x.checkedItems).pipe(
        map((items) => !items?.length)
    );

    public readonly checkItem = this.effect(
        (trigger$: Observable<Readonly<Session>>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.checkedItems)),
                tap<[Session, ReadonlyArray<Session>]>(
                    ([_session, checkedItems]) => {
                        const hasItem = checkedItems.some(
                            (x) => x.id === _session.id
                        );
                        return this.patchState({
                            checkedItems: hasItem
                                ? checkedItems.filter(
                                      (item) => item.id !== _session.id
                                  )
                                : [_session, ...checkedItems]
                        });
                    }
                )
            )
    );

    public async exportSessions() {
        const sessions = await this.select((x) => x.checkedItems)
            .pipe(take(1))
            .toPromise();

        this.modalController.dismiss(null, '');

        const loggedIn = await this.authStore.ensureLoggedIn();

        if (loggedIn === 'notLoggedIn') {
            return;
        }

        if (!this.authStore.hasAuthority(Authority.UPLOAD_SESSION)) {
            await this.alertService.showAlert({
                title: 'You are not authorized to perform this action. Please contact info@advancedsailingperformance.com for cloud access.',
                choices: []
            });
            return;
        }

        this.modalService.createDialog(ExportStatusPage, {
            sessionsWithBoat: sessions.map((session) => {
                return {
                    session: session,
                    boat: this.dataService.getBoatById(session.boat)
                };
            })
        });
    }

    public helpType = DataMarkdownEnum;

    constructor(
        public readonly sessionStore: SessionStore,
        public readonly modalController: ModalController,
        private readonly modalService: ModalService,
        public readonly dataService: DataService,
        public readonly authStore: AuthStore,
        public readonly alertService: AlertService
    ) {
        super({
            checkedItems: []
        });
    }

    public getBoatName(session: Session): string {
        return this.dataService.getBoatById(session.boat).name;
    }

    public trackSession(_index: number, session: Readonly<Session>) {
        return session.id;
    }
}
