<ion-card
    *ngIf="testTimerService.timerRunning$ | async"
    (click)="timerClicked()"
    class="mx-[-22px] mt-[-15px] p-1"
    [ngClass]="isSticky ? 'sticky' : ''"
    [style.background-color]="
        testingEvent | eventColor : 'background' : SESSION_EVENT_TYPES.TESTING
    "
    [style.color]="
        testingEvent | eventColor : 'text' : SESSION_EVENT_TYPES.TESTING
    "
>
    <ion-card-content>
        <section class="grid grid-cols-[30px_1fr_1fr_10px] items-center w-full">
            <ion-icon name="bag-check-outline"></ion-icon>
            <div>
                <h4 class="text-white">Testing</h4>
            </div>
            <ion-label class="text-right text-[20px] font-[590]">
                {{ testTimerService.currentTimer$ | async }}
            </ion-label>
        </section>
    </ion-card-content>
</ion-card>
