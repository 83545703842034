<div class="ion-page h-full">
    <ion-row class="sticky">
        <app-error-box *ngIf="(errors$ | async).length > 0">
            <span *ngFor="let error of errors$ | async">
                {{ error }}
            </span>
        </app-error-box>
    </ion-row>
    <ion-content>
        <app-layout-header
            title="Sailing session details"
            subTitle="Please provide the sailing session details."
            [imageSource]="imageEnum.details"
        ></app-layout-header>
        <div [formGroup]="form">
            <ion-row>
                <ion-col>
                    <app-input-text
                        formControlName="name"
                        title="Session name"
                    ></app-input-text>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <app-input-text
                        formControlName="startDate"
                        title="Start date"
                        type="date"
                        inputmode="text"
                    ></app-input-text>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <app-multiple-choice-checkboxes
                        formControlName="typeOfSession"
                        title="Session type"
                        [list$]="of(sessionTypesWithDisplayValue)"
                    ></app-multiple-choice-checkboxes>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <app-input-text
                        formControlName="group"
                        title="Group"
                    ></app-input-text>
                </ion-col>
            </ion-row>
        </div>
    </ion-content>

    <ion-footer>
        <ion-button
            *ngIf="wizard"
            (click)="next.emit()"
            shape="round"
            expand="full"
            [disabled]="hasErrors$ | async"
            >Continue
            <ion-icon class="pl-5" name="arrow-forward-outline"></ion-icon>
        </ion-button>
        <ion-button
            *ngIf="!wizard"
            (click)="modalController.dismiss('save')"
            shape="round"
            expand="full"
            [disabled]="hasErrors$ | async"
            >Save
            <ion-icon class="pl-5" name="arrow-forward-outline"></ion-icon>
        </ion-button>
    </ion-footer>
</div>
