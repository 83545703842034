import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ImageEnum, MenuItem } from 'src/app/shared/models';

@Component({
    selector: 'app-logo-with-menu',
    templateUrl: './logo-with-menu.component.html',
    styleUrls: ['./logo-with-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoWithMenuComponent {
    @Input() public menuItems: Array<MenuItem>;
    @Input() public userName: string | null;
    @Input() public isMenu = true;

    @Output() public readonly login = new EventEmitter();
    @Output() public readonly useOffline = new EventEmitter();
    @Output() public readonly logout = new EventEmitter();

    public readonly imageEnum = ImageEnum;
}
