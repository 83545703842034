export interface TypeOfBoat {
    id: number;
    name: string;
}

export interface TypeOfSailing {
    id: number;
    name: string;
}

export interface TypeOfSail {
    id: number;
    name: string;
    group: number;
    staySail: boolean;
}

export interface TypeOfSailGroup {
    id: number;
    name: string;
    shortName: string;
    color: string;
    colorSelected: string;
}

export interface TypeOfProperty {
    id: number;
    name: string;
}

export interface TypeOfButtonColor {
    text: string;
    background: string;
}

export interface TypeOfButton {
    name: string;
    icon?: string | null;
    color: TypeOfButtonColor;
    colorSelected?: TypeOfButtonColor;
    colorPractice?: TypeOfButtonColor;
    colorCountDown?: TypeOfButtonColor;
    colorFinished?: TypeOfButtonColor;
}

export interface TypeOfEventGroup {
    id: number;
    name: string;
}

export interface TypeOfEventOption {
    eventButton: TypeOfButton;
    commentDefault?: string;
}

export enum TypeOfEventComponent {
    sailUp = 'SailUp',
    property = 'Property',
    default = 'Default',
    racing = 'Racing',
    testing = 'Testing'
}

export interface TypeOfMedia {
    id: number;
    name: string;
    extention: string;
    mimeType: string;
}

export interface TypeOfStop {
    name: string;
    interval: number;
}

export interface Markdown {
    type: string;
    title: string;
    markdown: string;
    color?: string;
}
