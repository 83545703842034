import { Injectable } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { isNullOrEmpty, Property, PropertyType } from '../../../shared/index';
import { PropertyForm } from './property-form';

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    constructor(private formBuilder: FormBuilder) {}

    propertyToFormGroup(
        property?: Property,
        setDefaultValue?: boolean
    ): FormGroup<PropertyForm> {
        return this.formBuilder.group<PropertyForm>(
            {
                id: this.formBuilder.control(property.id),
                propertyName: this.formBuilder.nonNullable.control(
                    property.propertyName,
                    [Validators.required]
                ),
                typeOfProperty: this.formBuilder.nonNullable.control(
                    property.typeOfProperty,
                    [Validators.required]
                ),
                propertyValues: this.formBuilder.array<string>(
                    property.propertyValues ? [...property.propertyValues] : []
                ),
                minBound: this.formBuilder.control(property.minBound ?? 0),
                maxBound: this.formBuilder.control(property.maxBound ?? 0),
                increment: this.formBuilder.control(property.increment ?? 1),
                valueDefault: this.formBuilder.control(property.valueDefault),
                valueCurrent: this.formBuilder.control(
                    isNullOrEmpty(property.valueCurrent) && setDefaultValue
                        ? property.valueDefault
                        : property.valueCurrent
                ),
                comments: this.formBuilder.array<string>([]),
                propertyEnabled: this.formBuilder.control(
                    !!property.propertyEnabled || !!property.valueCurrent
                ),
                canBeChangedWhileSailing: this.formBuilder.nonNullable.control(
                    !!property.canBeChangedWhileSailing,
                    [Validators.required]
                )
            },
            {
                validators: [this.numberPropertyValidator()]
            }
        );
    }

    public numberPropertyValidator(): ValidatorFn {
        return (formGroup: FormGroup<PropertyForm>) => {
            if (
                formGroup.controls.typeOfProperty.value !== PropertyType.NUMBER
            ) {
                return null;
            }

            if (!formGroup.controls.increment.value) {
                return { incrementRequired: true };
            }

            if (formGroup.controls.increment.value <= 0) {
                return { incrementMin: true };
            }

            return null;
        };
    }
}
