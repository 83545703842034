import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy
} from '@angular/core';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent implements OnInit {
    @Input() stepAmount: number;
    @Input() currentStep: number;

    steps: string[] = [];

    public ngOnInit() {
        for (let i = 1; i < this.stepAmount + 1; i++) {
            this.steps.push(String(i));
        }
    }
}
