import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageEnum } from 'src/app/shared';

@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesComponent {
    @Input() public image: ImageEnum;

    public imageEnum = ImageEnum;
}
