import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component
} from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { WizardService } from 'src/app/services/wizard.service';
import { ImageEnum, Property } from 'src/app/shared';
import { BoatStore } from '../../current-boat-store.service';
import { UpsertPropertyComponent } from '../../components/upsert-property/upsert-property.component';
import { ModalListComponent } from 'src/app/components/components/modal-list/modal-list.component';
import {
    ModalListItem,
    ModalRolesEnum
} from 'src/app/shared/models/modelList.model';

@Component({
    selector: 'app-configuration-page',
    templateUrl: './configuration-page.component.html',
    styleUrls: ['./configuration-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationPage {
    public readonly imageEnum = ImageEnum;
    constructor(
        public readonly myBoatsStore: BoatStore,
        private readonly wizardService: WizardService,
        private readonly modalService: ModalService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    public async addProperty() {
        const data = await this.modalService.createModalAndWaitForDismiss(
            UpsertPropertyComponent,
            {
                title: 'Add',
                edit: true,
                allProperties$: this.myBoatsStore.boatProperties$
            }
        );

        if (data?.data) {
            this.myBoatsStore.boatPropertyArray.push(data?.data);
        }
    }

    public async editProperty(event: { index: number; property: Property }) {
        const list = [
            {
                icon: ImageEnum.pencil,
                name: 'Edit',
                role: ModalRolesEnum.select,
                id: 'edit',
                selected: false
            },
            {
                icon: ImageEnum.bin,
                name: 'Remove',
                role: ModalRolesEnum.delete,
                id: 'remove'
            }
        ] as ModalListItem[];

        const data = await this.modalService.createModalAndWaitForDismiss(
            ModalListComponent,
            {
                title: 'Take action',
                list
            }
        );
        if (data.role === 'delete') {
            this.myBoatsStore.boatPropertyArray.removeAt(event.index);
        }

        if (data.role === 'select') {
            const prop = await this.modalService.createModalAndWaitForDismiss(
                UpsertPropertyComponent,
                {
                    title: 'Edit',
                    edit: true,
                    data: event.property,
                    allProperties$: this.myBoatsStore.boatProperties$
                }
            );
            if (prop?.data) {
                this.myBoatsStore.boatPropertyArray.removeAt(event.index);
                this.myBoatsStore.boatPropertyArray.push(prop?.data);
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    public next() {
        this.wizardService.next();
    }
}
