import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MarkdownModule } from 'ngx-markdown';
import { CommentPageComponent } from '../pages/comment-page/comment-page.component';
import { RaceTimerComponent } from '../sessions/components/race-timer/race-timer.component';
import { TestTimerComponent } from '../sessions/components/test-timer/test-timer.component';
import { SharedModule } from '../shared/shared.module';
import { BoatOverviewComponent } from './components/boat-overview/boat-overview.component';
import { CommentAudioComponent } from './components/comment-audio/comment-audio.component';
import { CommentComponent } from './components/comment/comment.component';
import { ErrorBoxComponent } from './components/error-box/error-box.component';
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { ImagesComponent } from './components/images/images.component';
import {
    ObaInputSelectComponent,
    ObaInputTextComponent
} from './components/inputs';
import { EditableItemComponent } from './components/inputs/editable-item/editable-item.component';
import { InputSimpleSelectComponent } from './components/inputs/input-simple-select/input-simple-select.component';
import { MultiplChoiceCheckboxesComponent } from './components/inputs/multiple-choice-checkboxes/multiple-choice-checkboxes.component';
import { WeightInputComponent } from './components/inputs/weight-input/weight-input.component';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LogoBoatComponent } from './components/logo-boat/logo-boat.component';
import { LogoWithMenuComponent } from './components/logo-with-menu/logo-with-menu.component';
import { LogoComponent } from './components/logo/logo.component';
import { MarkdownModalComponent } from './components/markdown-modal/markdown-modal.component';
import { MarkdownInternalComponent } from './components/markdown/markdown-internal/markdown-internal.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { ModalListComponent } from './components/modal-list/modal-list.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaneComponent } from './components/pane/pane.component';
import { PopoverWrapperComponent } from './components/popover-wrapper/popover-wrapper.component';
import { PopupComponent } from './components/popup/popup.component';
import { PropertyComponent } from './components/property/property.component';
import { SetDateTimeComponent } from './components/set-date-time/set-date-time.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { LongPressDirective } from './directives/longpress.directive';
import { EventColorPipe } from './pipes/event-color.pipe';
import { MsToUserPipe } from './pipes/ms-to-user.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
    declarations: [
        LogoComponent,
        PaneComponent,
        LogoBoatComponent,
        LogoWithMenuComponent,
        StepperComponent,
        LayoutHeaderComponent,
        WizardComponent,
        LayoutComponent,
        PropertyComponent,
        ModalComponent,
        MarkdownComponent,
        BoatOverviewComponent,
        CommentComponent,
        CommentPageComponent,
        CommentAudioComponent,
        SetDateTimeComponent,
        MsToUserPipe,
        SafeHtmlPipe,
        ObaInputTextComponent,
        ObaInputSelectComponent,
        ModalListComponent,
        LongPressDirective,
        PopoverWrapperComponent,
        PopupComponent,
        MarkdownModalComponent,
        HelpButtonComponent,
        ImagesComponent,
        EditableItemComponent,
        MultiplChoiceCheckboxesComponent,
        TestTimerComponent,
        RaceTimerComponent,
        EventColorPipe,
        WeightInputComponent,
        InputSimpleSelectComponent,
        ErrorBoxComponent,
        MarkdownInternalComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        SharedModule,
        ReactiveFormsModule,
        MarkdownModule.forRoot()
    ],
    exports: [
        LogoComponent,
        PaneComponent,
        LogoBoatComponent,
        LogoWithMenuComponent,
        StepperComponent,
        LayoutHeaderComponent,
        WizardComponent,
        LayoutComponent,
        PropertyComponent,
        ModalComponent,
        MarkdownComponent,
        BoatOverviewComponent,
        CommentComponent,
        CommentPageComponent,
        CommentAudioComponent,
        SetDateTimeComponent,
        MsToUserPipe,
        SafeHtmlPipe,
        ObaInputTextComponent,
        ObaInputSelectComponent,
        ModalListComponent,
        LongPressDirective,
        PopoverWrapperComponent,
        PopupComponent,
        MarkdownModalComponent,
        HelpButtonComponent,
        ImagesComponent,
        MultiplChoiceCheckboxesComponent,
        TestTimerComponent,
        EventColorPipe,
        WeightInputComponent,
        InputSimpleSelectComponent,
        RaceTimerComponent,
        ErrorBoxComponent
    ]
})
export class ComponentsModule {}
