import { Component } from '@angular/core';
import { WizardService } from 'src/app/services/wizard.service';
import { DataService, ImageEnum } from 'src/app/shared';
import { SessionStore } from '../../session.store';

@Component({
    selector: 'app-session-boat',
    templateUrl: './session-boat.page.html',
    styleUrls: ['./session-boat.page.scss']
})
export class SessionBoatPage {
    constructor(
        private sessionStore: SessionStore,
        public readonly wizardService: WizardService,
        public readonly dataService: DataService
    ) {}

    public trackBy = (index: number) => index;
    public readonly imageEnum = ImageEnum;

    public boatSelected(boat: string) {
        this.sessionStore.selectBoat(boat);
        this.wizardService.next();
    }
}
