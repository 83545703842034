import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    BoatFormGroup,
    BoatImage,
    ImageEnum,
    TypeOfBoat,
    TypeOfSailing
} from 'src/app/shared';
import { SelectOption } from '../../../components/components/inputs/input-simple-select/input-simple-select.component';
import { TYPE_OF_BOAT } from '../../../data/TypeOfBoat';
import { TYPE_OF_SAILING } from '../../../data/TypeOfSailing';
import { ModalService } from '../../../services/modal.service';
import { UnitService } from '../../../settings/unit.service';
import { BoatImageUploadComponent } from '../boat-image-upload/boat-image-upload.component';

@Component({
    selector: 'app-boat-details',
    templateUrl: './boat-details.component.html',
    styleUrls: ['./boat-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoatDetailsComponent implements OnInit {
    @Input() form: FormGroup<BoatFormGroup>;
    @Input() hasErrors: boolean | null = true;
    typeOfBoat: ReadonlyArray<TypeOfBoat> | null = TYPE_OF_BOAT;
    @Input() typeOfSailing: ReadonlyArray<TypeOfSailing> = TYPE_OF_SAILING;

    @Output() goToNextPage = new EventEmitter();
    public readonly imageEnum = ImageEnum;

    public boatImage$: BehaviorSubject<string> = new BehaviorSubject<string>(
        undefined
    );

    public numberOfMastsOptions: SelectOption<number>[] = [
        {
            value: 1,
            displayValue: '1'
        },
        {
            value: 2,
            displayValue: '2'
        },
        {
            value: 3,
            displayValue: '3'
        },
        {
            value: 4,
            displayValue: '4'
        }
    ];

    constructor(
        public unitService: UnitService,
        public modalService: ModalService,
        private _change: ChangeDetectorRef
    ) {}

    public submitForm() {
        if (!this.hasErrors) {
            this.goToNextPage.emit('');
        }
    }

    ngOnInit(): void {
        if (this.form.controls.image.value?.uploadedImageBase64) {
            this.boatImage$.next(
                this.form.controls.image.value.uploadedImageBase64
            );
        }

        this.form.controls.image.valueChanges.subscribe((boatImage) => {
            this.boatImage$.next(boatImage?.uploadedImageBase64);
        });
    }

    public selectTrackBy(_index: number, item: { id: number }) {
        return item.id;
    }

    get displacementTitle$(): Observable<string> {
        return this.unitService
            .weightShortString()
            .pipe(
                map(
                    (unitShortString) =>
                        'Displacement of boat (' + unitShortString + ')'
                )
            );
    }

    async addPhoto() {
        const boatImageUploadResult =
            await this.modalService.createModalAndWaitForDismiss<
                BoatImageUploadComponent,
                BoatImage
            >(BoatImageUploadComponent, {
                currentImage: this.form.controls.image.value
            });

        if (boatImageUploadResult.data) {
            this.form.controls.image.setValue(boatImageUploadResult.data);
            this._change.markForCheck();
        }
    }
}
