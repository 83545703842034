<app-comment
    class="h-full"
    [items$]="items$"
    [typeOfEvent]="SESSION_EVENT_TYPES.TESTING"
    [form]="form"
    (newItem)="addItem($event)"
    (removeItem)="removeItem($event)"
    (dismiss)="appCommentDismiss($event)"
    [withDateChange]="false"
    [saveButtonText]="'Save'"
    [title]="'Edit test phase'"
>
    <ng-container additionalFooterData>
        <ion-button
            shape="round"
            expand="full"
            (click)="dismiss()"
            class="cancel__button"
            >Cancel
        </ion-button>
    </ng-container>
</app-comment>
