import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { Boat, Session } from '../../../shared/index';
import { UploadService } from '../../../shared/services/upload/upload.service';

@Component({
    selector: 'app-export-status-page',
    templateUrl: './export-status.page.html',
    styleUrls: ['./export-status.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UploadService]
})
export class ExportStatusPage implements ViewWillEnter {
    public sessionsWithBoat: { session: Session; boat: Boat }[];

    constructor(
        private readonly modalController: ModalController,
        public readonly uploadService: UploadService
    ) {}

    public cancel() {
        this.modalController.dismiss(null, 'cancel');
    }

    public ionViewWillEnter() {
        this.uploadService.upload(this.sessionsWithBoat).catch(() => {
            this.modalController.dismiss();
        });

        // TODO mita: Give reason for error and show? Or at least tell the user something like "Unexpected error"? Maybe with an option to send log to us?
        this.uploadService.error$
            .pipe(filter((error) => error))
            .subscribe(() => {
                this.modalController.dismiss();
            });
    }
}
