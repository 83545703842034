import { Component, ChangeDetectionStrategy } from '@angular/core';
import { WizardService } from 'src/app/services/wizard.service';
import { DataService } from 'src/app/shared';
import { BoatStore } from '../../current-boat-store.service';

@Component({
    selector: 'app-configuration-overview-page',
    templateUrl: './configuration-overview.page.html',
    styleUrls: ['./configuration-overview.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationOverviewPage {
    public readonly form = this.myBoatsStore.forms.boatDetail.value;

    constructor(
        public readonly wizardService: WizardService,
        public readonly myBoatsStore: BoatStore,
        public readonly dataService: DataService
    ) {}

    public goToStep(value: number) {
        this.wizardService.goToStep(value);
    }

    public next() {
        this.wizardService.next();
    }
}
