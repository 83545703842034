import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    ModalListItem,
    ModalRolesEnum
} from 'src/app/shared/models/modelList.model';

@Component({
    selector: 'app-modal-list',
    templateUrl: './modal-list.component.html',
    styleUrls: ['./modal-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalListComponent {
    @Input() title = 'Take action';
    @Input() hasClear = false;
    @Input() list: ReadonlyArray<ModalListItem> | null;
    @Input() css: string;

    constructor(public readonly modalController: ModalController) {}
    public onDismiss() {
        this.modalController.dismiss(null, ModalRolesEnum.cancel);
    }
    public clearDismiss() {
        this.modalController.dismiss(null, ModalRolesEnum.clear);
    }
    public onSelect(listItem: ModalListItem) {
        this.modalController.dismiss(
            listItem,
            listItem.role || ModalRolesEnum.select
        );
    }
    public trackBy = (index: number) => index;
}
