export enum ImageEnum {
    alert = 'assets/icon/alert.svg',
    arrowRight = 'assets/icon/arrowRight.svg',
    asymmetricalSpinnaker = 'assets/icon/asymmetricalspinnaker.svg',
    bin = 'assets/icon/bin.svg',
    boats = 'assets/icon/boats.svg',
    calendar = 'assets/icon/calendar.svg',
    check = 'assets/icon/check.svg',
    clock = 'assets/icon/clock.svg',
    comment = 'assets/icon/comment.svg',
    copy = 'assets/icon/copy.svg',
    details = 'assets/icon/details.svg',
    download = 'assets/icon/download.svg',
    faq = 'assets/icon/faq.svg',
    headsail = 'assets/icon/headsail.svg',
    helm = 'assets/icon/helm.svg',
    help = 'assets/icon/help.svg',
    list = 'assets/icon/list.svg',
    location = 'assets/icon/location.svg',
    logout = 'assets/icon/logout.svg',
    mainsail = 'assets/icon/mainsail.svg',
    map = 'assets/icon/map.svg',
    notification = 'assets/icon/notification.svg',
    numbers = 'assets/icon/numbers.svg',
    open = 'assets/icon/open.svg',
    pencil = 'assets/icon/pencil.svg',
    play = 'assets/icon/play.svg',
    privacy = 'assets/icon/privacy.svg',
    about = 'assets/icon/about.svg',
    profile = 'assets/icon/profile.svg',
    sail = 'assets/icon/sail.svg',
    sailing = 'assets/icon/sailing.svg',
    sessionCardSails = 'assets/icon/sessioncardsail.svg',
    settings = 'assets/icon/settings.svg',
    share = 'assets/icon/share.svg',
    sliders = 'assets/icon/sliders.svg',
    staysail = 'assets/icon/staysail.svg',
    stop = 'assets/icon/stop.svg',
    symmetricalSpinnaker = 'assets/icon/symmetricalspinnaker.svg',
    tes = 'assets/icon/tes.svg',
    thumbUp = 'assets/icon/thumbUp.svg',
    time = 'assets/icon/time.svg',
    setting = 'setting',
    gauge = 'gauge',
    log = 'log',
    trash = 'assets/icon/trash.svg',
}
