import { Injectable } from '@angular/core';
import {
    addMinutes,
    differenceInMilliseconds,
    intervalToDuration
} from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    dateStringAsDate,
    durationToTime,
    Session,
    SessionEvent
} from '../../../shared/index';
import { SessionStore } from '../../session.store';

@Injectable({
    providedIn: 'root'
})
export class TestTimerService {
    private _currentTimer$: BehaviorSubject<string> =
        new BehaviorSubject<string>('');
    private _testingDone$: Subject<SessionEvent> = new Subject<SessionEvent>();

    private difference: number;
    private previous = 0;

    private startDateTime: Date;
    private interval: number;
    private timer;
    private isTimerRunning = false;
    private currentSession: Session;
    private _testingEvent: SessionEvent;

    constructor(private sessionStore: SessionStore) {
        combineLatest([
            sessionStore.currentSession$,
            sessionStore.lastTestingEvent$
        ]).subscribe(([session, testingEvent]) =>
            this.init(session, testingEvent)
        );
    }

    public init(currentSession: Session, testingEvent: SessionEvent): void {
        if (currentSession && testingEvent) {
            if (!!currentSession.testTimerStartedAt) {
                this.currentSession = currentSession;
                this._testingEvent = testingEvent;

                this.startDateTime = dateStringAsDate(
                    currentSession.testTimerStartedAt
                );

                this.interval = currentSession.testInterval;
                this.eventTimer();
                if (!this.isTimerRunning) {
                    this.timer = setInterval(() => {
                        this.eventTimer();
                    }, 1000);
                }

                this.isTimerRunning = true;
            } else {
                this._currentTimer$.next('');
                clearInterval(this.timer);
                this.isTimerRunning = false;
            }
        } else {
            this.clear();
        }
    }

    public eventTimer() {
        const currentDate = new Date();
        this.previous = this.difference;
        this.difference = 0;

        if (this.interval === 0) {
            this.difference = differenceInMilliseconds(
                currentDate,
                this.startDateTime
            );
        } else {
            this.difference = differenceInMilliseconds(
                addMinutes(this.startDateTime, this.interval),
                currentDate
            );
            if (this.difference <= 0) {
                this.sessionStore.stopTestingEvent(this._testingEvent);
                this._testingDone$.next(this._testingEvent);
                this.clear();
            }
        }

        const duration = intervalToDuration({ start: 0, end: this.difference });
        if (!this.currentSession?.paused) {
            this._currentTimer$.next(durationToTime(duration));
        }
    }

    get currentTimer$(): Observable<string> {
        return this._currentTimer$;
    }

    get timerRunning$(): Observable<boolean> {
        return this._currentTimer$.pipe(map((currentTimer) => !!currentTimer));
    }

    get testingEvent(): SessionEvent {
        return this._testingEvent;
    }

    get testingDone$(): Subject<SessionEvent> {
        return this._testingDone$;
    }

    private clear() {
        this.isTimerRunning = false;
        this._currentTimer$.next('');
        this._testingEvent = undefined;
        clearInterval(this.timer);
    }
}
