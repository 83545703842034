export enum DataTypeEnum {
    typeOfBoat = 'TYPEOFBOAT',
    typeOfEvent = 'TYPEOFEVENT',
    typeOfEventGroup = 'TYPEOFEVENTGROUP',
    typeOfMedia = 'TYPEOFMEDIA',
    typeOfProperty = 'TYPEOFPROPERTY',
    typeOfSail = 'TYPEOFSAIL',
    typeOfSailGroup = 'TYPEOFSAILGROUP',
    typeOfSailing = 'TYPEOFSAILING',
    typeOfStop = 'TYPEOFSTOP',
    boat = 'BOAT',
    session = 'SESSION',
    settings = 'SETTINGS',
    event = 'EVENT',
    markdown = 'MARKDOWN'
}

export enum DataTableEnum {
    authentication = 'AUTHENTICATION',
    obaData = 'OBADATA'
}

export enum DataMarkdownEnum {
    settingsFaq = 'SETTINGS_FAQ',
    settingsTermsOfService = 'SETTINGS_TOS',
    settingsPrivacy = 'SETTINGS_PRIVACY',
    helpComment = 'HELP_COMMENT',
    helpSessions = 'HELP_SESSIONS',
    helpMyBoats = 'HELP_MYBOATS',
    helpBoatDetails = 'HELP_BOATDETAILS',
    helpAddSail = 'HELP_ADDSAIL',
    helpEditRace = 'HELP_EDITRACE',
    helpEventOverview = 'HELP_EVENTOVERVIEW',
    helpActiveEvents = 'HELP_ACTIVEEVENTS',
    helpExportSession = 'HELP_EXPORTSESSION',
    helpStartRace = 'HELP_STARTRACE',
    helpSettings = 'HELP_SETTINGS',
    helpSessionBoat = 'HELP_SESSIONBOAT',
    helpSessionDetail = 'HELP_SESSIONDETAIL',
    helpSailInventory = 'HELP_SAILINVENTORY',
    helpBoatProperty = 'HELP_BOATPROPERTY',
    helpCustomProperty = 'HELP_CUSTOMPROPERTY',
    helpSessionOverview = 'HELP_SESSIONOVERVIEW',
    helpBoatDetail = 'HELP_BOATDETAIL',
    helpBoatOverview = 'HELP_BOATOVERVIEW',
    helpCommentTechIssue = 'HELP_COMMENT_TECHISSUE',
    helpCommentBalance = 'HELP_COMMENT_BALANCE',
    helpCommentCalibration = 'HELP_COMMENT_CALIBRATION',
    helpCommentRaceLeg = 'HELP_COMMENT_RACELEG',
    helpCommentTesting = 'HELP_COMMENT_TESTING'
}
