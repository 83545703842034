import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component
} from '@angular/core';
import { ModalListComponent } from 'src/app/components/components/modal-list/modal-list.component';
import { ModalService } from 'src/app/services/modal.service';
import { WizardService } from 'src/app/services/wizard.service';
import { DataService, ImageEnum, Sail } from 'src/app/shared';
import {
    ModalListItem,
    ModalRolesEnum
} from 'src/app/shared/models/modelList.model';
import { AddSailComponent } from '../../components/add-sail/add-sail.component';
import {
    AddSailResultType,
    AddSailService
} from '../../components/add-sail/add-sail.service';
import { BoatStore } from '../../current-boat-store.service';

@Component({
    selector: 'app-sail-inventory-page',
    templateUrl: './sail-inventory.page.html',
    styleUrls: ['./sail-inventory.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SailInventoryPage {
    constructor(
        public readonly myBoatsStore: BoatStore,
        public readonly dataService: DataService,
        public readonly wizardService: WizardService,
        private readonly modalService: ModalService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    public async addSail() {
        const addSailService = new AddSailService();

        const modal = await this.modalService.createFullModal(
            AddSailComponent,
            false,
            'Add',
            {
                allSails$: this.myBoatsStore.boatSails$,
                addSailService: addSailService
            }
        );

        const addSailResult = await addSailService.result$.toPromise();

        modal.dismiss();

        if (addSailResult.type == AddSailResultType.ADD) {
            this.myBoatsStore.addSailToBoat(addSailResult.sail);
        }

        this.changeDetectorRef.detectChanges();
    }

    public async editSail(event: { index: number; sail: Sail }) {
        const list = [
            {
                icon: ImageEnum.pencil,
                name: 'Edit',
                role: ModalRolesEnum.select,
                id: 'edit',
                selected: false
            },
            {
                icon: ImageEnum.bin,
                name: 'Remove',
                role: ModalRolesEnum.delete,
                id: 'remove'
            }
        ] as ModalListItem[];

        const data = await this.modalService.createModalAndWaitForDismiss(
            ModalListComponent,
            {
                title: 'Take action',
                list
            }
        );

        if (data.role === 'delete') {
            const sailUpFavoritesWithoutThisSail =
                this.myBoatsStore.forms.boatDetail.controls.sailUpFavorites
                    .getRawValue()
                    .filter(
                        (filter) =>
                            !filter.sailUp.find(
                                (sailUp) => sailUp.id == event.sail.id
                            )
                    );

            this.myBoatsStore.forms.boatDetail.controls.sailUpFavorites.setValue(
                sailUpFavoritesWithoutThisSail
            );
            this.myBoatsStore.sailsFormArray.removeAt(event.index);
        }

        if (data.role === 'select') {
            const addSailService = new AddSailService();

            const modal = await this.modalService.createModal(
                AddSailComponent,
                {
                    title: 'Update',
                    edit: true,
                    data: event.sail,
                    allSails$: this.myBoatsStore.boatSails$,
                    addSailService: addSailService
                }
            );

            const addSailResult = await addSailService.result$.toPromise();

            modal.dismiss();

            if (addSailResult.type == AddSailResultType.ADD) {
                this.myBoatsStore.sailsFormArray
                    .at(event.index)
                    ?.patchValue(addSailResult.sail);
            }
        }

        this.changeDetectorRef.detectChanges();
    }
}
