import { ChangeDetectionStrategy, Component, Input, Type } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../../services/modal.service';

type ModalComponentProps<Data> = {
    id?: string;
    title: string;
    edit: boolean;
    data: Data;
};

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent<Data, T extends ModalComponentProps<Data>> {
    @Input()
    public name: string;
    @Input()
    public component: Type<T>;
    @Input()
    public id?: string;
    @Input()
    public title: string;
    @Input()
    public data: Data;
    @Input()
    public props: { [key in keyof T]?: T[key] };

    constructor(
        public readonly modalService: ModalService,
        public readonly modalController: ModalController
    ) {}

    public async edit() {
        const data = await this.modalService.createModalAndWaitForDismiss<
            ModalComponentProps<Data>
        >(this.component, {
            data: this.data,
            id: this.id,
            title: this.title,
            edit: true,
            ...this.props
        });

        this.modalController.dismiss(data.data);

        return data.data;
    }
}
