<app-layout>
    <h4 toolbarContent>Edit race</h4>
    <ion-button button fill="clear" (click)="dismiss.emit()">
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <app-help contextButton [helpType]="helpType.helpEditRace"></app-help>

    <section
        class="border-b-[1px] border-[#eeee] mt-[36px] pb-[30px] mb-[30px]"
    >
        <p class="text-[17px] font-[590]">
            Race #{{ currentSession?.raceNumber }} time
        </p>
        <p class="text-[34px] leading-[41px] font-[590]">
            {{ clock }}
        </p>
    </section>

    <div
        class="grid grid-cols-[50px_1fr_100px] h-[30px] items-center mark__row"
        *ngIf="totalMarks.length > 0"
    >
        <div class="font-[590] col-span-2">Passed marks</div>
        <div class="text-right text-[#083A81]" (click)="editCurrentMark.emit()">
            Edit
        </div>
    </div>
    <div
        class="mark__row"
        [class.mark__row--normal]="(currentMark ?? 0) > 0"
        [class.mark__row--dashed]="(currentMark ?? 0) === 0"
        [class.current]="(currentMark ?? 0) === 0"
    >
        <ng-container
            *ngTemplateOutlet="
                (currentMark ?? 0) > 0
                    ? markPassed
                    : (currentMark ?? 0) === 0
                    ? markNormal
                    : markTodo
            "
        ></ng-container>

        <span class="markText">Start</span>
        <span class="text-right">
            {{ currentSession?.raceTimerStartedAt | date : 'HH:mm:ss' }}</span
        >
    </div>
    <div
        *ngFor="let mark of totalMarks"
        class="mark__row"
        [class.mark__row--normal]="(currentMark ?? 0) - 1 > mark.mark"
        [class.mark__row--dashed]="(currentMark ?? 0) - 1 <= mark.mark"
        [class.current]="(currentMark ?? 0) === mark.mark"
    >
        <app-images
            [image]="imageEnum.boats"
            *ngIf="(currentMark ?? 0) + 1 === mark.mark"
            class="boat"
        ></app-images>
        <ng-container
            *ngTemplateOutlet="
                (currentMark ?? 0) > mark.mark
                    ? markPassed
                    : (currentMark ?? 0) === mark.mark
                    ? markNormal
                    : markTodo
            "
        ></ng-container>

        <span
            class="markText"
            [class.race__todo]="(currentMark ?? 0) < mark.mark"
            >Mark {{ mark.mark }}</span
        >
        <span class="text-right">
            <ng-container *ngIf="(currentMark ?? 0) >= mark.mark">{{
                mark.event?.dateTimeUtc | date : 'HH:mm:ss'
            }}</ng-container></span
        >
    </div>
    <div class="mark__row mb-[300px]">
        <app-images
            [image]="imageEnum.boats"
            *ngIf="
                (currentMark ?? 0) > 0 &&
                (currentMark ?? 0) === totalMarks?.length
            "
            class="boat"
        ></app-images>
        <ng-container *ngTemplateOutlet="markTodo"></ng-container>
        <div class="race__todo">Finish race</div>
        <div>&nbsp;</div>
    </div>
    <div class="bottom_space">&nbsp;</div>
    <ion-footer style="z-index: 10000 !important; background-color: white">
        <ion-button
            expand="full"
            shape="round"
            (click)="save.emit()"
            *ngIf="raceEvent.raceType !== RaceTypeEnum.PRACTICE"
            >Save changes</ion-button
        >
        <ion-button
            expand="full"
            shape="round"
            (click)="practiceFinished.emit()"
            *ngIf="raceEvent.raceType === RaceTypeEnum.PRACTICE"
            >Practice start finished</ion-button
        >

        <ion-button
            expand="full"
            shape="round"
            (click)="cancel.emit()"
            class="mt-[20px] mb-[30px] cancel__button"
            >Cancel
            {{
                raceEvent.raceType === RaceTypeEnum.PRACTICE
                    ? 'practice start'
                    : 'race'
            }}</ion-button
        >
        <div
            class="grid grid-cols-[1fr_1fr] text-[#083A81] text-center text-[13px]"
        >
            <span (click)="editDate.emit()"
                ><ion-icon name="time-outline" class="mr-[12px]"></ion-icon
                >{{ date | date : 'dd/MM/yyyy HH:mm:ss' }}</span
            >
        </div>
    </ion-footer>
</app-layout>

<ng-template #markPassed>
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 8.874 19.804 7.7942 19.459 6.7832L17.8398 8.40234C17.9448 8.91834 18 9.453 18 10C18 14.411 14.411 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C11.633 2 13.1519 2.49389 14.4199 3.33789L15.8516 1.90625C14.2036 0.71225 12.185 0 10 0ZM19.293 1.29297L9 11.5859L5.70703 8.29297L4.29297 9.70703L9 14.4141L20.707 2.70703L19.293 1.29297Z"
            fill="#BBBBBB"
        />
    </svg>
</ng-template>
<ng-template #markNormal>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 0C4.489 0 0 4.489 0 10C0 15.511 4.489 20 10 20C15.511 20 20 15.511 20 10C20 4.489 15.511 0 10 0ZM10 2C14.4301 2 18 5.56988 18 10C18 14.4301 14.4301 18 10 18C5.56988 18 2 14.4301 2 10C2 5.56988 5.56988 2 10 2Z"
            fill="#083A81"
        />
    </svg>
</ng-template>
<ng-template #markTodo>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 0C4.489 0 0 4.489 0 10C0 15.511 4.489 20 10 20C15.511 20 20 15.511 20 10C20 4.489 15.511 0 10 0ZM10 2C14.4301 2 18 5.56988 18 10C18 14.4301 14.4301 18 10 18C5.56988 18 2 14.4301 2 10C2 5.56988 5.56988 2 10 2Z"
            fill="#BBBBBB"
        />
    </svg>
</ng-template>
