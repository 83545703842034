import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WizardService } from 'src/app/services/wizard.service';
import { DataService, ImageEnum } from 'src/app/shared';
import { SessionOverviewSessionData } from '../../components/session-overview/session-overview.component';
import { SessionStore } from '../../session.store';

@Component({
    selector: 'app-session-overview-page',
    templateUrl: './session-overview.page.html',
    styleUrls: ['./session-overview.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionOverviewPage {
    public readonly imageEnum = ImageEnum;

    constructor(
        public readonly wizardService: WizardService,
        public readonly sessionStore: SessionStore,
        public readonly dataService: DataService
    ) {}

    public editStep(value: number | null) {
        if (value) {
            this.wizardService.goToStep(value);
        } else {
            this.wizardService.next();
        }
    }

    getSession(): SessionOverviewSessionData {
        return this.sessionStore.form.getRawValue();
    }
}
