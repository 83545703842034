import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Markdown } from 'src/app/shared';

@Component({
    selector: 'app-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownComponent {
    @Input() markdownData: Markdown | null | undefined;
    @Input() backButtonLink: string;
}
