import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { Sail, SailForm } from 'src/app/shared';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import { DataService } from 'src/app/shared/services/data.service';
import { v4 as uuidv4 } from 'uuid';
import { SelectOption } from '../../../components/components/inputs/input-simple-select/input-simple-select.component';
import { TYPE_OF_SAIL_GROUP } from '../../../data/TypeOfSailGroup';
import { UnitService } from '../../../settings/unit.service';
import { AddSailService } from './add-sail.service';

@Component({
    selector: 'app-add-sail',
    templateUrl: './add-sail.component.html',
    styleUrls: ['./add-sail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSailComponent implements OnInit, OnDestroy {
    public form: FormGroup<SailForm>;
    @Input()
    public title: string;
    @Input()
    public data: Sail;
    @Input()
    public edit: boolean;

    @Input()
    public addSailService: AddSailService;

    @Input()
    public allSails$: Observable<Readonly<Sail[]>>;
    private allSails: Readonly<Sail[]>;

    public hasErrorsSubject$ = new BehaviorSubject<boolean>(true);
    public hasErrors$ = this.hasErrorsSubject$.asObservable();
    public errors$ = new BehaviorSubject<string[]>([]);

    public helpType = DataMarkdownEnum;

    private readonly componentDestroyed$: Subject<void> = new Subject<void>();

    public reefList: SelectOption<number>[] = [
        { value: 0, displayValue: '0' },
        { value: 1, displayValue: '1' },
        { value: 2, displayValue: '2' },
        { value: 3, displayValue: '3' },
        { value: 4, displayValue: '4' },
        { value: 5, displayValue: '5' }
    ];

    constructor(
        public readonly dataService: DataService,
        private readonly formBuilder: FormBuilder,
        public readonly unitService: UnitService
    ) {}

    ngOnInit() {
        this.allSails$.subscribe((allSails) => {
            this.allSails = allSails;
        });

        this.form = this.formBuilder.group<SailForm>({
            id: this.formBuilder.nonNullable.control(uuidv4(), [
                Validators.required
            ]),
            name: this.formBuilder.nonNullable.control('', [
                Validators.required
            ]),
            typeOfSail: this.formBuilder.nonNullable.control(null, [
                Validators.required
            ]),
            reefs: this.formBuilder.nonNullable.control(0, [
                Validators.required
            ]),
            weight: this.formBuilder.control(null)
        });

        if (this.edit) {
            this.form.patchValue({
                ...this.data
            });
        }

        this.checkErrorState();

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                debounceTime(200),
                tap(() => {
                    this.checkErrorState();
                })
            )
            .subscribe();
    }

    addSail() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        const rawValue: Sail = this.form.getRawValue();
        this.addSailService.addSail(rawValue);
    }

    dismiss() {
        this.addSailService.dismiss();
    }

    public selectTrackBy(_index: number, item) {
        return item.id;
    }

    get sailWeightTitle$(): Observable<string> {
        return this.unitService
            .weightShortString()
            .pipe(map((unitShortString) => 'Weight (' + unitShortString + ')'));
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    private checkErrorState() {
        const errors = [];

        let sailNameTaken = false;

        const sailNameExistsInPropertyArray = !!this.allSails.find(
            (sail) =>
                sail.name.toUpperCase() ===
                this.form.controls.name.value?.toUpperCase()
        );

        if (
            sailNameExistsInPropertyArray &&
            !(
                this.edit &&
                this.data?.name?.toUpperCase() ===
                    this.form.controls.name.value?.toUpperCase()
            )
        ) {
            sailNameTaken = true;
        }
        if (sailNameTaken) {
            errors.push('The name of the sail is already in use.');
        }

        const hasError = !this.form.valid || sailNameTaken;
        this.hasErrorsSubject$.next(hasError);
        this.errors$.next(errors);
    }

    protected readonly TYPE_OF_SAIL_GROUP = TYPE_OF_SAIL_GROUP;
}
