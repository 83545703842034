import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionType, StatusEnum } from '../../../shared/index';
import { PropertyForm } from '../../boats/properties/property-form';
import { SessionForm } from './session-form';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor(private formBuilder: FormBuilder) {}

    createFormGroup(): FormGroup<SessionForm> {
        return this.formBuilder.group<SessionForm>({
            id: this.formBuilder.control(null),
            name: this.formBuilder.nonNullable.control(null, [
                Validators.required
            ]),
            boat: this.formBuilder.nonNullable.control(null, [
                Validators.required
            ]),
            startDate: this.formBuilder.nonNullable.control(null, [
                Validators.required
            ]),
            endDate: this.formBuilder.control(null),
            location: this.formBuilder.control(null),
            typeOfSession: this.formBuilder.nonNullable.control(
                [] as SessionType[]
            ),
            group: this.formBuilder.control(null),
            sails: this.formBuilder.nonNullable.control([] as string[]),
            staticProperties: this.formBuilder.nonNullable.array(
                [] as FormGroup<PropertyForm>[]
            ),
            changeableProperties: this.formBuilder.nonNullable.array(
                [] as FormGroup<PropertyForm>[]
            ),
            raceType: this.formBuilder.control(null),
            testTimerStartedAt: this.formBuilder.control(null),
            raceTimerStartedAt: this.formBuilder.control(null),
            testInterval: this.formBuilder.control(null),
            raceInterval: this.formBuilder.control(null),
            status: this.formBuilder.nonNullable.control(StatusEnum.planned, [
                Validators.required
            ])
        });
    }
}
