import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { addMinutes } from 'date-fns';
import { ModalService } from 'src/app/services/modal.service';
import { PropertyService } from '../../services/boats/properties/property.service';
import { CommentService } from '../../services/comment.service';
import { SessionForm } from '../../services/session/properties/session-form';
import {
    isNullOrEmpty,
    Property,
    SessionCurrentRaceTypeEnum,
    Session,
    SessionEvent,
    SessionEventType,
    TypeOfEventComponent
} from '../../shared';
import { isRaceStarted } from '../helpers';
import { EditRacePageComponent } from '../pages/edit-race/edit-race.page';
import { EditSailsUpPage } from '../pages/edit-sails-up/edit-sails-up.page';
import { EventChangePropertiesPage } from '../pages/event-change-properties/event-change-properties-page.component';
import { EventChangePropertyPage } from '../pages/event-change-property/event-change-property-page.component';
import { SailsUpPage } from '../pages/sails-up/sails-up.page';
import { StartRacePage } from '../pages/start-race/start-race.page';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    constructor(
        private readonly commentService: CommentService,
        private readonly modalController: ModalController,
        private readonly modalService: ModalService,
        private readonly propertyService: PropertyService
    ) {}

    public async createEvent(
        event: Readonly<SessionEventType>,
        form: FormGroup<SessionForm>,
        currentSession: Readonly<Session>,
        sessionEvent?: Readonly<SessionEvent> | null | undefined,
        multipleEvents: boolean = false,
        isEdit: boolean = false
    ) {
        // Determine based on the event type what to execute
        switch (event.component) {
            case TypeOfEventComponent.sailUp:
                if (!isEdit) {
                    const modal = await this.modalController.create({
                        component: SailsUpPage
                    });

                    await modal.present();
                    const result = await modal.onWillDismiss<SessionEvent>();
                    return result.data;
                } else {
                    const modal = await this.modalController.create({
                        component: EditSailsUpPage,
                        componentProps: {
                            sailsUpEvent: sessionEvent
                        }
                    });

                    await modal.present();
                    const result = await modal.onWillDismiss<SessionEvent>();
                    return result.data;
                }
            case TypeOfEventComponent.testing:
                throw new Error(
                    'Testing should not use event service. In the future we want to completely replace this service.'
                );
            case TypeOfEventComponent.racing:
                // If the race is already started then show the `EditRaceComponent` else use `StartRaceComponent`
                if (isRaceStarted(currentSession)) {
                    const editRace =
                        await this.modalService.createModalAndWaitForDismiss(
                            EditRacePageComponent
                        );

                    if (
                        editRace?.role === 'cancel' &&
                        !isNullOrEmpty(editRace?.data)
                    ) {
                        form.patchValue({
                            raceInterval: null,
                            raceType: SessionCurrentRaceTypeEnum.none,
                            raceTimerStartedAt: null
                        });
                        return { ...editRace.data, isStop: true };
                    }

                    return;
                }

                const startRace =
                    await this.modalService.createModalAndWaitForDismiss<StartRacePage>(
                        StartRacePage,
                        {
                            typeOfEvent: event,
                            lastEvent: sessionEvent
                        }
                    );

                if (startRace?.role === 'save' && startRace?.data) {
                    const sessionEvent: SessionEvent = startRace.data;

                    let newTime: number;

                    if (sessionEvent.startTimestampMilliseconds) {
                        newTime = sessionEvent.startTimestampMilliseconds;
                    } else {
                        newTime = addMinutes(
                            new Date().getTime(),
                            Number(sessionEvent.raceIntervalMinutes)
                        ).getTime();
                    }
                    sessionEvent.dateTimeUtc = newTime;
                    form.patchValue({
                        raceInterval: 0,
                        raceType: startRace.data?.raceType,
                        raceTimerStartedAt: newTime
                    });
                    return { ...startRace.data, isStart: true };
                } else if (startRace?.role === 'cancel') {
                    form.patchValue({
                        raceType: SessionCurrentRaceTypeEnum.none,
                        raceTimerStartedAt: null
                    });
                    return { ...startRace.data, isStop: true };
                }
                break;

            case TypeOfEventComponent.property:
                if (multipleEvents) {
                    return await this.createMultipleProperties(form);
                } else {
                    return await this.createSingleProperty(sessionEvent);
                }
            default:
                return this.commentService.openComment(
                    event,
                    sessionEvent?.dateTimeUtc
                        ? new Date(sessionEvent.dateTimeUtc)
                        : new Date(),
                    sessionEvent || undefined
                );
        }
    }

    private async createMultipleProperties(form: FormGroup<SessionForm>) {
        const eventChangePropertiesReturnData =
            await this.modalService.createModalAndWaitForDismiss<
                EventChangePropertiesPage,
                SessionEvent[]
            >(EventChangePropertiesPage);

        if (
            eventChangePropertiesReturnData?.role === 'save' &&
            eventChangePropertiesReturnData?.data
        ) {
            const propArray = form.controls.changeableProperties;

            eventChangePropertiesReturnData.data.forEach((event) => {
                const index = propArray.controls.findIndex(
                    (x) =>
                        (x.value as Property).propertyName ===
                        event.property.propertyName
                );
                if (index >= 0) {
                    propArray.removeAt(index);
                }
                propArray.push(this.addProperty(event.property));
            });

            return eventChangePropertiesReturnData.data;
        } else {
            return;
        }
    }

    private async createSingleProperty(sessionEvent: SessionEvent) {
        const eventChangePropertyReturnData =
            await this.modalService.createModalAndWaitForDismiss<
                EventChangePropertyPage,
                SessionEvent
            >(EventChangePropertyPage, {
                sessionEvent: sessionEvent
            });

        if (
            eventChangePropertyReturnData?.role === 'save' &&
            eventChangePropertyReturnData?.data
        ) {
            return eventChangePropertyReturnData.data;
        }
    }

    private addProperty = (property: Readonly<Property>) =>
        this.propertyService.propertyToFormGroup(property);
}
