import { Pipe, PipeTransform } from '@angular/core';
import {
    EventRaceType,
    SessionCurrentRaceTypeEnum,
    SessionEventType, SessionEventTypeId,
    TypeOfButton,
    TypeOfButtonColor
} from '../../shared/index';
import { SessionEvent } from '../../shared/models/sessionEvent.model';

@Pipe({
    name: 'eventColor'
})
export class EventColorPipe implements PipeTransform {
    public transform(
        sessionEvent: Readonly<SessionEvent> | null,
        type: keyof TypeOfButtonColor,
        typeOfEvent?: Readonly<SessionEventType>
    ) {
        if (!sessionEvent || !typeOfEvent) {
            return null;
        }

        const typeOfButton: keyof TypeOfButton | null =
            sessionEvent.raceType === EventRaceType.PRACTICE
                ? 'colorPractice'
                : sessionEvent.raceType === EventRaceType.RACE ||
                  sessionEvent.typeOfEvent === SessionEventTypeId.TESTING
                ? 'colorSelected'
                : null;

        if (!typeOfButton) {
            return null;
        }

        if (!sessionEvent.isStart) {
            return null;
        }

        return sessionEvent.typeOfEvent === typeOfEvent.id &&
            sessionEvent.isStart
            ? typeOfEvent.eventButton[typeOfButton]?.[type]
            : '';
    }
}
