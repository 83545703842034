<ion-list lines="none" class="blue">
    <ion-list-header>
        <ion-grid>
            <ion-row class="logo">
                <app-logo></app-logo>
            </ion-row>
            <ion-row *ngIf="userName"
                ><h3>Welcome, {{ userName }}</h3></ion-row
            >
            <ion-row>
                <div class="divider"></div>
            </ion-row>
        </ion-grid>
    </ion-list-header>

    <ng-container *ngIf="userName; else loginTemplate">
        <ion-menu-toggle auto-hide="true" *ngIf="isMenu; else page">
            <ion-item
                [routerLink]="menuItem.link"
                *ngFor="let menuItem of menuItems"
            >
                <app-images
                    [image]="menuItem.icon"
                    class="svg-20 svg white mr-[8px]"
                ></app-images>
                <span>{{ menuItem.title }}</span>
            </ion-item>
            <ion-item (click)="logout.emit()">
                <app-images
                    [image]="imageEnum.logout"
                    class="svg-20 svg white mr-[8px]"
                ></app-images>
                <span>Logout</span>
            </ion-item>
        </ion-menu-toggle>
        <ng-template #page>
            <ion-item
                [routerLink]="menuItem.link"
                *ngFor="let menuItem of menuItems"
            >
                <app-images
                    [image]="menuItem.icon"
                    class="svg-20 svg white mr-[8px]"
                ></app-images>
                <span>{{ menuItem.title }}</span>
            </ion-item>
            <ion-item (click)="logout.emit()">
                <app-images
                    [image]="imageEnum.logout"
                    class="svg-20 svg white mr-[8px]"
                ></app-images>
                <span>Logout</span>
            </ion-item>
        </ng-template>
    </ng-container>
    <ng-template #loginTemplate>
        <ion-item>
            <div class="flex w-full justify-center">
                <ion-button
                    expand="block"
                    class="h-10"
                    color="secondary"
                    (click)="login.emit()"
                    >LOGIN
                </ion-button>
            </div>
        </ion-item>
        <ion-item>
            <div class="flex w-full justify-center">
                <ion-button
                    expand="block"
                    class="h-10"
                    color="secondary"
                    (click)="useOffline.emit()"
                    >USE OFFLINE</ion-button
                >
            </div>
        </ion-item>
    </ng-template>
</ion-list>
