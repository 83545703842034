import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ImageEnum, Sail, TypeOfSailGroup } from 'src/app/shared';
import { TYPE_OF_SAIL_GROUP } from '../../../data/TypeOfSailGroup';
import { SailService } from '../../../services/sail.service';

@Component({
    selector: 'app-sail-inventory',
    templateUrl: './sail-inventory.component.html',
    styleUrls: ['./sail-inventory.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SailInventoryComponent {
    @Input() sails: ReadonlyArray<Sail> | null;
    @Input() hasErrors: boolean | null;
    public typeOfSailGroup: ReadonlyArray<TypeOfSailGroup> | null =
        TYPE_OF_SAIL_GROUP;

    @Output() addSail = new EventEmitter();
    @Output() editSail = new EventEmitter<{ index: number; sail: Sail }>();
    @Output() goToNextPage = new EventEmitter();

    public readonly imageEnum = ImageEnum;

    constructor(private sailService: SailService) {}

    public next() {
        if (!this.hasErrors) {
            this.goToNextPage.emit('');
        }
    }

    public selectTrackBy(_index: number, item: { id: number }) {
        return item.id;
    }

    // NICE mita: we NEED an enum for sailTypes!!
    getSailImage(sail: Sail) {
        return this.sailService.getSailImageByGroupId(sail.typeOfSail);
    }
}
