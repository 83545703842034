export interface Property {
    id?: number;
    propertyName: string;
    typeOfProperty?: PropertyType;
    propertyValues: string[];
    minBound?: number;
    maxBound?: number;
    increment?: number;
    valueDefault?: string;
    valueCurrent?: string;
    comments?: string[];
    propertyEnabled?: boolean;
    canBeChangedWhileSailing: boolean;
}

export enum PropertyType {
    LIST_OF_VALUES = 'LIST_OF_VALUES',
    NUMBER = 'NUMBER'
}
