import { ImageEnum } from './images.model';

export enum ModalRolesEnum {
    cancel = 'cancel',
    select = 'select',
    clear = 'clear',
    delete = 'delete',
    share = 'share',
}

export interface ModalListItem {
    id: string;
    name?: string;
    icon?: ImageEnum;
    role?: ModalRolesEnum;
    selected?: boolean;
    css?: string;
}

export interface ModalList {
    list: ModalListItem[];
}
