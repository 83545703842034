<ion-card (click)="selected.emit(boat.id)">
    <ion-row class="grid grid-cols-[100px_1fr_20px] p-[20px]">
        <ion-col class="self-center">
            <ion-img
                *ngIf="
                    !boat.image?.uploadedImageBase64 &&
                    !boat.image?.defaultImagePath
                "
                src="assets/boats2.svg"
            ></ion-img>
            <ion-img
                *ngIf="
                    !boat.image?.uploadedImageBase64 &&
                    boat.image?.defaultImagePath
                "
                [src]="boat.image.defaultImagePath"
            ></ion-img>
            <ion-img
                *ngIf="boat.image?.uploadedImageBase64"
                [src]="boat.image?.uploadedImageBase64"
            ></ion-img>
        </ion-col>
        <ion-col>
            <ion-row>
                <h4>{{ boat.name }}</h4>
            </ion-row>
            <ion-row>
                <ion-label class="footnote-regular">{{
                    typeOfBoats | lookup : 'id' : 'name' : boat.typeOfBoat
                }}</ion-label>
            </ion-row>
            <ion-row class="grid grid-cols-[32px_1fr]">
                <ion-col>
                    <app-images
                        [image]="imageEnum.boats"
                        class="svg-20 pt-[16px]"
                    ></app-images>
                </ion-col>
                <ion-col>{{ sailsConcat }} </ion-col>
            </ion-row>
            <ion-row class="grid grid-cols-[32px_1fr]">
                <ion-col>
                    <app-images
                        [image]="imageEnum.helm"
                        class="svg-20 pt-[16px]"
                    ></app-images>
                </ion-col>
                <ion-col>{{ boatPropertyConcat }} </ion-col>
            </ion-row>
            <ion-row class="grid grid-cols-[32px_1fr]">
                <ion-col>
                    <app-images
                        [image]="imageEnum.sliders"
                        class="svg-20 pt-[16px]"
                    ></app-images>
                </ion-col>
                <ion-col>{{ customPropertyConcat }} </ion-col>
            </ion-row>
        </ion-col>
        <ion-col class="self-center">
            <ion-icon
                name="chevron-forward-outline"
                *ngIf="!hasMenu"
            ></ion-icon>
            <ion-icon name="ellipsis-horizontal" *ngIf="hasMenu"></ion-icon>
        </ion-col>
    </ion-row>
</ion-card>
