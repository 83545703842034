import {HttpClient, HttpEventType, HttpHeaderResponse, HttpRequest} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {FileTransfer} from '@awesome-cordova-plugins/file-transfer';
import {Capacitor} from '@capacitor/core';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {BehaviorSubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {Boat, MediaFile, Session} from '../../models/index';
import {AuthStore} from '../auth.store';
import {ModelToDtoService} from './modelToDto.service';

export interface UploadServiceState {
    totalSize: number;
    uploadedSoFar: number;
    done: boolean;
}

@Injectable()
export class UploadService {
    private uploadState$: BehaviorSubject<UploadServiceState> = this.createUploadState();

    public readonly isDone$ = this.createIsDone();
    public readonly progress$ = this.createProgress();

    public readonly error$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private httpClient: HttpClient, private modelToDtoService: ModelToDtoService, private authStore: AuthStore, private ngZone: NgZone) {
    }

    public async upload(sessionsWithBoat: { session: Session; boat: Boat }[]) {
        const size = this.calculateSize(sessionsWithBoat);

        this.patchState({
            totalSize: size
        });

        for (const sessionWithBoat of sessionsWithBoat) {
            const boatDto = this.modelToDtoService.boatModelToDto(sessionWithBoat.boat);

            const boatUpload = new HttpRequest('PUT', environment.apiUrl + 'auth/boat/upload', boatDto, {
                responseType: 'text'
            });

            await this.httpClient.request(boatUpload).toPromise();

            const sessionDto = this.modelToDtoService.sessionModelToDto(sessionWithBoat.session, sessionWithBoat.boat);

            const sessionUpload = new HttpRequest('PUT', environment.apiUrl + 'auth/session/upload', sessionDto, {
                responseType: 'text'
            });
            await this.httpClient.request(sessionUpload).toPromise();

            await this.uploadFiles(sessionWithBoat);
        }

        this.patchState({
            done: true
        });
    }

    private async uploadFiles(sessionAndBoat: {
        session: Session; boat: Boat;
    }): Promise<void> {
        const mediaFiles: (MediaFile & { eventId: string })[] = [];

        for (const event of sessionAndBoat.session.events) {
            if (event.comment?.mediaFiles) {
                mediaFiles.push(...event.comment.mediaFiles.map((mediaFile) => ({
                    ...mediaFile, eventId: event.id
                })));
            }
        }

        for (const mediaFile of mediaFiles) {
            await this.uploadMediaFile(mediaFile, sessionAndBoat);
        }
    }

    private async uploadMediaFile(mediaFile: MediaFile & { eventId: string }, sessionAndBoat: {
        session: Session; boat: Boat;
    }): Promise<void> {

        const mediaFileWithDateTimeUtc: MediaFile & {
            eventId: string; dateTimeUtc: string;
        } = {
            ...mediaFile, dateTimeUtc: new Date(mediaFile.creationTimestamp).toISOString()
        };

        const getUriResult = await Filesystem.getUri({
            path: mediaFile.filename, directory: Directory.Library
        });

        const fetchResponse = await fetch(Capacitor.convertFileSrc(getUriResult.uri));

        const blob = await fetchResponse.blob();

        console.log('sending upload request');

        const fileTransferObject = FileTransfer.create();

        const token = await this.authStore.getToken();

        let lastLoadedState = 0;

        fileTransferObject.onProgress((event: ProgressEvent) => {

            // Execute it in angular so we get updates
            this.ngZone.run(() => {
                if (lastLoadedState > event.loaded) {
                    return;
                }
                const uploadIncreasedBy = event.loaded - lastLoadedState;
                console.log(mediaFile.id + ' uploaded ' + event.loaded + ' so far and increased by ' + uploadIncreasedBy);
                lastLoadedState = event.loaded;
                this.increaseProgress(uploadIncreasedBy);
            });

        });

        try {
            await fileTransferObject.upload(getUriResult.uri, environment.apiUrl + 'auth/media/upload-media-v2', {
                fileKey: 'media', headers: {
                    Authorization: `Bearer ${token}`
                }, params: {
                    contentLength: String(blob.size),
                    mediaFile: JSON.stringify(mediaFileWithDateTimeUtc),
                    sessionId: sessionAndBoat.session.id,
                    eventId: mediaFile.eventId,
                }
            },);
        } catch (e) {
            this.error$.next(true);
        }

        return;
    }

    private calculateSize(sessions: { session: Session; boat: Boat }[]) {
        let size = 0;
        sessions.forEach((session) => {
            session.session.events?.forEach((event) => {
                event.comment?.mediaFiles?.forEach((mediaFile) => {
                    size = size + mediaFile.size;
                });
            });
        });

        return size;
    }

    private increaseProgress(uploadIncreasedBy: number) {
        console.log('increasing by' + uploadIncreasedBy);
        console.log('uploaded so far: ' + (this.uploadState$.value.uploadedSoFar + uploadIncreasedBy));
        console.log('total size:' + (this.uploadState$.value.totalSize));
        console.log('uploaded so far: ' + (this.uploadState$.value.totalSize / (this.uploadState$.value.uploadedSoFar + uploadIncreasedBy)) + '%');
        this.patchState({
            uploadedSoFar: this.uploadState$.value.uploadedSoFar + uploadIncreasedBy
        });
    }

    private patchState(partialState: Partial<UploadServiceState>) {
        this.uploadState$.next({
            ...this.uploadState$.value, ...partialState
        });
    }

    private createUploadState() {
        return new BehaviorSubject<UploadServiceState>({
            totalSize: 0, uploadedSoFar: 0, done: false
        });
    }

    private createIsDone() {
        return this.uploadState$.pipe(map((state) => state.done));
    }

    private createProgress() {
        const progressObersable = this.uploadState$.pipe(map((state) => Math.min(99, Math.round(100 / ((state.totalSize || 1) / state.uploadedSoFar)))));
        progressObersable.subscribe((progress) => {
            console.log(progress);
        });
        return progressObersable;
    }
}
