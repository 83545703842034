<div class="ion-page">
    <ion-content class="layout_content white">
        <app-layout-header
            title="Great!"
            subTitle="Your boat was successfully created. Please check the details below."
            [imageSource]="imageEnum.thumbUp"
        ></app-layout-header>
        <ion-row class="grid grid-cols-[32px_1fr_50px]">
            <app-images
                [image]="imageEnum.boats"
                class="svg-20 pt-[16px]"
            ></app-images>
            <h4 class="ml-4 w-3/4">Boat details</h4>
            <a class="pt-[16px]" (click)="goToStep.emit(0)">Edit</a>
        </ion-row>
        <ion-row class="mt-4">
            <ion-col>
                <ion-row class="bloc footnote">Boat name</ion-row>
                <ion-row>{{ boat.name }}</ion-row>
            </ion-col>
        </ion-row>
        <ion-row class="mt-4">
            <ion-col>
                <ion-row class="bloc footnote">Type</ion-row>
                <ion-row>{{
                    typeOfBoats | lookup : 'id' : 'name' : boat.typeOfBoat
                }}</ion-row>
            </ion-col>
        </ion-row>
        <ion-row class="mt-4">
            <ion-col>
                <ion-row class="bloc footnote">Number of masts</ion-row>
                <ion-row>{{ boat.numberOfMasts }}</ion-row>
            </ion-col>
        </ion-row>
        <ion-row class="mt-4">
            <ion-col>
                <ion-row class="bloc footnote">Length (ft)</ion-row>
                <ion-row>{{ boat.length_ft }}</ion-row>
            </ion-col>
        </ion-row>
        <hr class="my-4" />

        <ion-row class="grid grid-cols-[32px_1fr_50px]">
            <app-images
                [image]="imageEnum.sail"
                class="svg-20 pt-[16px]"
            ></app-images>
            <h4 class="ml-4 w-3/4">Sail inventory</h4>
            <a class="pt-[16px]" (click)="goToStep.emit(1)">Edit</a>
        </ion-row>
        <ion-row class="mt-4" *ngFor="let sail of boat.sails">
            <ion-col>
                <ion-row class="bloc footnote">{{ sail.name }}</ion-row>
                <ion-row>{{
                    typeOfSailGroup | lookup : 'id' : 'name' : sail.typeOfSail
                }}</ion-row>
            </ion-col>
        </ion-row>

        <hr class="my-4" />

        <ion-row class="grid grid-cols-[32px_1fr_50px]">
            <app-images
                [image]="imageEnum.helm"
                class="svg-20 pt-[16px]"
            ></app-images>
            <h4 class="ml-4 w-3/4">Properties</h4>
            <a class="pt-[16px]" (click)="goToStep.emit(2)">Edit</a>
        </ion-row>

        <ion-row class="mt-4" *ngFor="let property of boat.properties">
            <ng-container *ngIf="isPropertyString(property)">
                <ion-col>
                    <ion-row class="bloc footnote">{{
                        property.propertyName
                    }}</ion-row>
                    <ion-row>{{ property.propertyValues.join(', ') }}</ion-row>
                </ion-col>
            </ng-container>

            <ng-container *ngIf="isPropertyNumber(property)">
                <ion-col>
                    <ion-row class="bloc footnote">{{
                        property.propertyName
                    }}</ion-row>
                    <ion-row class="bloc footnote"
                        >{{ property.minBound }} - {{ property.maxBound }},
                        {{ property.valueDefault }}</ion-row
                    >
                </ion-col>
            </ng-container>
        </ion-row>
    </ion-content>
    <ion-footer>
        <ion-button
            shape="round"
            type="submit"
            expand="full"
            (click)="next.emit()"
            >Save
        </ion-button>
    </ion-footer>
</div>
