import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, from, Observable } from 'rxjs';
import {
    filter,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { BoatsService } from '../../../my-boats/boats.service';
import { CommentService } from '../../../services/comment.service';
import {
    Boat,
    filterEmpty,
    Sail,
    SailsUpFavorite,
    SailUp,
    SessionEvent,
    SessionEventTypeId
} from '../../../shared';
import { SessionStore } from '../../session.store';

export interface SailsUpState {
    sailsUp: ReadonlyArray<SailUp>;
    date: Readonly<number>;
    event?: SessionEvent;
    segment: Segment;
}

type Segment = 'sails' | 'favorites' | 'history';

export interface ComponentWithFooter {
    footer: TemplateRef<any>;
}

@Component({
    selector: 'app-sails-up-page',
    templateUrl: './sails-up.page.html',
    styleUrls: ['./sails-up.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SailsUpPage
    extends ComponentStore<SailsUpState>
    implements OnInit
{
    @ViewChild('sails') public readonly sails: ComponentWithFooter | null;

    public readonly currentSegment$ = this.select((x) => x.segment);
    public readonly date$ = this.select((x) =>
        x.date ? new Date(x.date) : undefined
    );

    public readonly favorites$ =
        // We only want to see favorite when every sail of the favorite was chosen for this session. So we have to filter this way
        combineLatest([
            this.sessionStore.currentBoat$,
            this.sessionStore.currentSession$
        ]).pipe(
            map(([boat, currentSession]) => {
                const favorites: SailsUpFavorite[] =
                    boat?.sailUpFavorites ?? [];
                return favorites.filter((favorite) =>
                    favorite.sailUp.every((sailUp) =>
                        currentSession.sails.includes(sailUp.id)
                    )
                );
            })
        );

    public readonly sailsUp$ = this.select((x) => x.sailsUp);
    public readonly sailsUpHistory$ = this.sessionStore.getEventTypeHistory(
        SessionEventTypeId.SAIL_UP
    );

    public readonly currentSailUpEvent$ = this.sessionStore
        .select((x) => x.currentSession)
        .pipe(
            filterEmpty(),
            map((session) => session.events ?? []),
            map((events) =>
                events.find(
                    (event) => event.typeOfEvent === SessionEventTypeId.SAIL_UP
                )
            )
        );

    public readonly toggleSail = this.effect(
        (trigger$: Observable<Readonly<SailUp>>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.sailsUp)),
                tap(([result, sailsUp]) => {
                    const exists = sailsUp.some(
                        (sail) => sail.id === result.id
                    );
                    if (exists) {
                        this.patchState({
                            sailsUp: sailsUp.filter(
                                (sail) => sail.id !== result.id
                            )
                        });
                        return;
                    }
                    this.patchState({
                        sailsUp: [...sailsUp, result]
                    });
                })
            )
    );

    public readonly changeReef = this.effect(
        (trigger$: Observable<{ id: string; reef: number | null }>) =>
            trigger$.pipe(
                withLatestFrom(this.select((x) => x.sailsUp)),
                tap(([result, sailsUp]) => {
                    const sailUp = sailsUp.find(
                        (sail) => sail.id === result.id
                    );
                    if (sailUp) {
                        this.patchState({
                            sailsUp: [
                                ...sailsUp.filter(
                                    (sail) => sail.id !== result.id
                                ),
                                {
                                    ...sailUp,
                                    reef: result.reef
                                }
                            ]
                        });
                        return;
                    }
                })
            )
    );

    public readonly setSails = this.effect(
        (trigger$: Observable<ReadonlyArray<SailUp>>) =>
            trigger$.pipe(
                tap((sailUps: ReadonlyArray<SailUp>) => {
                    this.patchState({
                        sailsUp: [...sailUps]
                    });
                })
            )
    );

    public readonly editDate = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(this.date$),
            switchMap(([, date]) =>
                from(this.commentService.changeDate(new Date(date))).pipe(
                    filter((result) => result.role === 'save'),
                    tap((result) =>
                        this.patchState({
                            date: result.data?.getTime() || undefined
                        })
                    )
                )
            )
        )
    );

    public readonly addComment = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            switchMap(() =>
                this.commentService.openDefaultComment().pipe(
                    filterEmpty(),
                    tap((result) =>
                        this.patchState({
                            event: result
                        })
                    )
                )
            )
        )
    );

    public readonly selectSails = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(this.select((x) => x)),
            tap<[string, SailsUpState]>(([, state]) =>
                this.modalController.dismiss(
                    {
                        id: uuidv4(),
                        comment: state.event?.comment,
                        dateTimeUtc: state.date,
                        typeOfEvent: SessionEventTypeId.SAIL_UP,
                        sailsUp: state.sailsUp.map<SailUp>((sailUp) => ({
                            name: sailUp.name,
                            id: sailUp.id,
                            reef: sailUp.reef
                        }))
                    } as SessionEvent,
                    'save'
                )
            )
        )
    );

    public readonly changeSegment = this.effect(
        (trigger$: Observable<Segment>) =>
            trigger$.pipe(
                tap<Segment>((segment) =>
                    this.patchState({
                        segment
                    })
                )
            )
    );

    public readonly selectFavorite = this.effect(
        (trigger$: Observable<SailsUpFavorite>) =>
            trigger$.pipe(
                withLatestFrom(this.sessionStore.selectedSessionSails$),
                tap<[SailsUpFavorite, Sail[]]>(([favorites, sails]) => {
                    const onlyFavorites = sails.filter((sail) =>
                        favorites.sailUp.find(
                            (sailToFind) => sailToFind.id === sail.id
                        )
                    );

                    let favoritesWithReefs = onlyFavorites.map((favorite) => {
                        const sailUp = favorites.sailUp.find(
                            (toFind) => toFind.id === favorite.id
                        );
                        return {
                            ...favorite,
                            reef: sailUp.reef
                        };
                    });

                    return this.patchState({
                        sailsUp: favoritesWithReefs
                    });
                }),
                withLatestFrom(this.select((x) => x)),
                tap<[string, SailsUpState]>(([, state]) =>
                    this.modalController.dismiss(
                        {
                            id: uuidv4(),
                            comment: state.event?.comment,
                            dateTimeUtc: state.date,
                            typeOfEvent: SessionEventTypeId.SAIL_UP,
                            sailsUp: state.sailsUp.map<SailUp>((sailUp) => ({
                                name: sailUp.name,
                                id: sailUp.id,
                                reef: sailUp.reef
                            }))
                        } as SessionEvent,
                        'save'
                    )
                )
            )
    );

    public readonly saveAsFavorite = this.effect((trigger$: Observable<void>) =>
        trigger$.pipe(
            withLatestFrom(
                this.select((x) => x.sailsUp),
                this.sessionStore.currentBoat$
            ),
            tap<[void, ReadonlyArray<SailUp>, Boat]>(([, sailUps, boat]) =>
                this.boatsService.addSailUpFavorite(
                    [...sailUps].sort((a, b) => a.name.localeCompare(b.name)),
                    boat.id
                )
            ),
            tap(() => this.changeSegment('favorites'))
        )
    );

    constructor(
        private readonly modalController: ModalController,
        private readonly commentService: CommentService,
        public readonly sessionStore: SessionStore,
        public readonly boatsService: BoatsService
    ) {
        super({
            sailsUp: [],
            date: new Date().getTime(),
            segment: 'sails'
        });
    }

    public ngOnInit(): void {
        this.favorites$
            .pipe(
                take(1),
                filter((sails) => sails.length > 0),
                tap(() => this.changeSegment('favorites'))
            )
            .subscribe();

        combineLatest([
            this.currentSailUpEvent$,
            this.sessionStore.selectedSessionSails$
        ]).subscribe(([event, sails]) => {
            if (event) {
                this.setSails(
                    event.sailsUp.filter((sailUp) =>
                        sails.some((sail) => sail.id == sailUp.id)
                    )
                );
            }
        });
    }

    public get sessionHasNoPriorSailUpEvent$(): Observable<boolean> {
        return this.sessionStore.hasSailUpEvent$.pipe(
            map((hasPriorEvent) => !hasPriorEvent)
        );
    }

    public dismiss() {
        this.modalController.dismiss();
    }

    public clearHistory() {
        this.sessionStore.clearHistory(SessionEventTypeId.SAIL_UP);
    }

    removeFavorite(sailsUpFavorite: Readonly<SailsUpFavorite>) {
        this.sessionStore.currentBoat$.pipe(take(1)).subscribe((boat) => {
            this.boatsService.removeSailUpFavorite(sailsUpFavorite, boat.id);
        });
    }
}
