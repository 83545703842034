<div class="flex justify-center">
    <div *ngFor="let step of steps; let x = index" class="inline-block">
        <span
            [class.step-current]="x === currentStep"
            [class.step-completed]="x < currentStep"
            [class.step-todo]="x > currentStep"
        ></span>
        <hr *ngIf="x < stepAmount - 1" />
    </div>
</div>
