import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { isNumeric } from 'rxjs/internal-compatibility';
import { ImageEnum } from 'src/app/shared';
import { PropertyForm } from '../../../services/boats/properties/property-form';
import { SessionForm } from '../../../services/session/properties/session-form';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-session-boat-configuration',
    templateUrl: './session-boat-configuration.component.html',
    styleUrls: ['./session-boat-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionBoatConfigurationComponent implements OnInit {
    @Input() public form: FormGroup<SessionForm>;
    @Input() public staticPropertiesForm: FormArray<FormGroup<PropertyForm>>;
    public initialValues: Record<string, string|number> = {};
    @Input() public hasErrors: boolean | null = true;
    @Input() public wizard = true;

    @Output() goToNextPage = new EventEmitter();

    public readonly imageEnum = ImageEnum;

    constructor(public readonly modalController: ModalController) {}

    ngOnInit(): void {
        for (const staticProperty of this.staticPropertiesForm.controls) {

            if (!staticProperty.value.id) {
                staticProperty.controls.id.setValue(uuidv4());
            }

            if (staticProperty.value.valueCurrent) {
                this.initialValues[staticProperty.value.id] = staticProperty.value.valueCurrent;
            } else if (staticProperty.value.valueDefault && isNumeric(staticProperty.value.valueDefault)) {
                this.initialValues[staticProperty.value.id] = staticProperty.value.valueDefault;
            } else {
                this.initialValues[staticProperty.value.id] = staticProperty.value.minBound || 0;
            }

        }
    }

    public formatNumber(value: number) {
        return Math.round((value + Number.EPSILON) * 10000) / 10000;
    }

    isValueCurrentSet(property: FormGroup<PropertyForm>) {
        return (
            property.value.valueCurrent?.length > 0 &&
            isNumeric(property.value.valueCurrent)
        );
    }

    numberValueChange($event: any, property: FormGroup<PropertyForm>) {
        const newValue = this.formatNumber($event.detail.value).toString();
        property.controls.valueCurrent.setValue(newValue);
    }
}
