import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Injector,
    INJECTOR,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    Validator,
    Validators
} from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { valueNotSet } from 'src/app/shared';

@Component({
    selector: 'app-input-text',
    templateUrl: 'input-text.component.html',
    styleUrls: ['input-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ObaInputTextComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: ObaInputTextComponent
        }
    ]
})
export class ObaInputTextComponent
    implements ControlValueAccessor, Validator, AfterViewChecked, OnInit
{
    @Input() public title = 'Label';
    @Input() public type: 'text' | 'number' | 'decimal' | 'date' = 'text';
    @Input() public inputmode: 'text' | 'numeric' = 'text';
    @Input() public setValueNotSet = false;
    @Input() public focusOnInit = false;
    @Input() public min;
    // NICE mita: This is ugly... but otherwise we need to break up the property defaultValue into two types
    @Input() public convertNumberToString = false;

    @Output() public editingDone: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('input') inputElement: IonInput;

    public disabled: boolean;
    public value = '';
    public onChange: (value: any) => void = () => {};
    public onTouched: () => void = () => {};
    public valueNotSet = valueNotSet;

    private _control: NgControl;

    constructor(@Inject(INJECTOR) private injector: Injector) {}

    public ngOnInit() {
        this._control = this.injector.get(NgControl);
    }

    public get isInvalid(): boolean {
        return this._control.invalid;
    }

    public get isRequired(): boolean {
        return this._control.control.hasValidator(Validators.required);
    }

    public writeValue(value: any): void {
        if (this.type === 'date') {
            this.value = new Date(value).toDateString();
        }
        this.value = value;
    }

    public ngAfterViewChecked(): void {
        if (this.focusOnInit) {
            setTimeout(() => this.inputElement.setFocus(), 10);
        }
    }

    public registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public validate(_control: AbstractControl) {
        if (_control.valid) {
            return null;
        }

        return _control.errors;
    }

    valueChange(value) {
        if (this.type == 'number' || this.type == 'decimal') {
            if (value === '') {
                this.onChange(undefined);
                return;
            }

            let valueToCheck = Number(value.replace(',', '.'));
            if (isFinite(valueToCheck)) {
                if (this.convertNumberToString) {
                    this.onChange(value);
                } else {
                    this.onChange(Number(value));
                }
            } else {
                this.onChange(undefined);
            }
        } else if (this.type == 'date') {
            if (value) {
                this.onChange(new Date(value).getTime());
            }
        } else {
            this.onChange(value);
        }
    }
}
