import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    isDevMode,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MediaFileData} from '@awesome-cordova-plugins/media-capture';
import {MediaCapture} from '@awesome-cordova-plugins/media-capture/ngx';
import {Photo} from '@capacitor/camera';
import {Capacitor} from '@capacitor/core';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {Keyboard} from '@capacitor/keyboard';
import {MediaFile} from '@ionic-native/media-capture';
import {AlertController, Platform} from '@ionic/angular';
import {GenericResponse, VoiceRecorder} from 'capacitor-voice-recorder';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DataMarkdownEnum} from 'src/app/shared/models/data.model';
import {v4 as uuidv4} from 'uuid';
import {CommentService} from '../../../services/comment.service';
import {ImageService} from '../../../services/image.service';

import {
    AudioMediaFile,
    CommentMedia,
    MediaFile as ObaMediaFile,
    SessionCurrentRaceTypeEnum,
    SessionEventType,
    SessionEventTypeId,
    TimerService,
    TypeOfEventOption
} from '../../../shared';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TimerService]
})
export class CommentComponent implements OnChanges, OnInit, OnDestroy {
    @Input() public canRecord = true;
    @Input() public canTakePicture = true;
    @Input() public items$: Observable<ReadonlyArray<CommentMedia>>;
    @Input() public typeOfEvent: SessionEventType;
    @Input() public form: FormGroup;
    @Input() contentHeader: TemplateRef<any>;
    @Input() withDateChange = true;
    @Input() showSaveButton = true;
    @Input() saveButtonText = 'Save';
    @Input() title: string;

    @Output() public readonly newItem = new EventEmitter<CommentMedia>();
    @Output() public readonly recordingStarted = new EventEmitter<boolean>();
    @Output() public readonly removeItem = new EventEmitter<CommentMedia>();
    @Output() public readonly dismiss = new EventEmitter<string>();

    public image: Photo;
    public isRecording = false;
    public hasRecording = false;
    public currentlyPlaying: AudioMediaFile | null;
    public readonly time$ = this.timerService.time$;
    public helpText: DataMarkdownEnum = DataMarkdownEnum.helpComment;

    private audioRef: HTMLAudioElement | null;
    private audioTimerStartedAt: number;

    private base64MediaDataOrUrl: Record<string, BehaviorSubject<string>> = {};

    private itemSubscription: Subscription;

    constructor(private readonly alertController: AlertController, private readonly changeDetectorRef: ChangeDetectorRef, private readonly timerService: TimerService, private readonly mediaCapture: MediaCapture, private readonly commentService: CommentService, private readonly imageService: ImageService, private readonly platform: Platform,) {
    }

    ngOnDestroy(): void {
        this.itemSubscription.unsubscribe();
    }

    ngOnInit(): void {
         this.itemSubscription = this.items$.subscribe(async (items) => {
            for (const item of items) {
                if (!this.base64MediaDataOrUrl[item.id]) {
                    this.base64MediaDataOrUrl[item.id] = new BehaviorSubject<string>(undefined);
                }
                if (!this.base64MediaDataOrUrl[item.id].value) {
                    await this.loadBase64Data(item);
                }
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const {typeOfEvent} = changes;
        switch (typeOfEvent?.currentValue?.id as SessionEventTypeId) {
            case SessionEventTypeId.ISSUE: {
                this.helpText = DataMarkdownEnum.helpCommentTechIssue;
                break;
            }
            case SessionEventTypeId.BALANCE: {
                this.helpText = DataMarkdownEnum.helpCommentBalance;
                break;
            }
            case SessionEventTypeId.CALIBRATION: {
                this.helpText = DataMarkdownEnum.helpCommentCalibration;
                break;
            }
            case SessionEventTypeId.RACE_START: {
                this.helpText = DataMarkdownEnum.helpCommentRaceLeg;
                break;
            }
            case SessionEventTypeId.TESTING: {
                this.helpText = DataMarkdownEnum.helpCommentTesting;
                break;
            }
            default: {
                this.helpText = DataMarkdownEnum.helpComment;
                break;
            }
        }
    }

    public async takePicture() {
        const image = await this.imageService.takeOrUploadImage();

        let filename = image.path;

        filename = await this.copyToDataPath(filename);

        const statResult = await Filesystem.stat({
            path: filename,
            directory: Directory.Library
        });

        this.newItem.next({
            id: uuidv4(),
            type: 'IMAGE',
            creationTimestamp: image.creationTimestamp,
            mimeType: image.mimeType,
            filename: filename,
            size: statResult.size
        });
    }

    private async copyToDataPath(path: string) {
        const filename = path.split(/[\\/]/).pop();

        if (this.platform.is('ios') && path.startsWith('/private')) {
            path = `file://${path}`;
        }

        await Filesystem.copy({
            from: path, toDirectory: Directory.Library, to: filename
        });

        Filesystem.deleteFile({
            path: path
        });

        return filename;
    }

    public async recordVideo() {
        await Keyboard.hide();
        setTimeout(() => this.recordVideoInternal(), 500);
    }

    private async recordVideoInternal() {
        const result = await this.mediaCapture.captureVideo({
            quality: 50
        });
        const file = (result as Array<MediaFile>)[0];

        let formatDataResolve;
        let mediaFileData: MediaFileData;

        const formatDataResolved = new Promise((resolve) => {
            formatDataResolve = resolve;
        });

        file.getFormatData((data) => {
            mediaFileData = data;
            formatDataResolve();
        });

        await formatDataResolved;

        const statResult = await Filesystem.stat({
            path: file.fullPath
        });

        const creationStartedAt = statResult.ctime - mediaFileData.duration * 1000;

        let filename = file.fullPath;
        filename = await this.copyToDataPath(filename);

        this.newItem.emit({
            id: uuidv4(),
            type: 'VIDEO',
            durationSec: mediaFileData.duration,
            creationTimestamp: creationStartedAt,
            mimeType: file.type,
            filename: filename,
            size: statResult.size
        });
    }

    public async recordAudio() {
        const canRecord = await VoiceRecorder.hasAudioRecordingPermission();
        if (!canRecord.value) {
            const requestPermissionResponse = await VoiceRecorder.requestAudioRecordingPermission();
            if (!requestPermissionResponse.value) {
                const alertController = await this.alertController.create({
                    message: 'Missing voice recording permissions.'
                });
                await alertController.present();
                return;
            }
        }

        this.timerService.start();
        this.audioTimerStartedAt = new Date().getTime();
        this.recordingStarted.next(true);
        this.isRecording = true;

        if (this.hasRecording) {
            await VoiceRecorder.resumeRecording();
        } else {
            this.hasRecording = true;
            await VoiceRecorder.requestAudioRecordingPermission();
            await VoiceRecorder.startRecording()
                .then((result: GenericResponse) => console.log(result.value))
                .catch((error) => console.log(error));
        }
    }

    public async stopRecording() {
        this.timerService.pause();
        await VoiceRecorder.pauseRecording();
        this.isRecording = false;
        this.recordingStarted.next(true);
        this.changeDetectorRef.detectChanges();
    }

    public async done() {
        this.timerService.clear();
        if (this.hasRecording) {
            const result = await VoiceRecorder.stopRecording();
            this.recordingStarted.next(false);

            const filename = uuidv4();

            const writeFileResult = await Filesystem.writeFile({
                path: filename, data: result.value.recordDataBase64, directory: Directory.Library
            });

            const statResult = await Filesystem.stat({
                path: writeFileResult.uri
            });

            this.newItem.emit({
                id: uuidv4(),
                type: 'AUDIO',
                durationSec: parseFloat((result.value.msDuration / 1000).toFixed(2)),
                mimeType: result.value.mimeType,
                creationTimestamp: this.audioTimerStartedAt,
                filename: filename,
                size: statResult.size
            });
        }

        this.isRecording = false;
        this.hasRecording = false;
        this.dismiss.next('save');
    }

    public async playAudio(item: AudioMediaFile) {
        if (this.audioRef) {
            this.audioRef.pause();
            this.audioRef = null;
            this.currentlyPlaying = null;
            return;
        }

        this.currentlyPlaying = item;

        this.audioRef = new Audio(`data:${item.mimeType};base64,${this.base64MediaDataOrUrl[item.id].value}`);
        this.audioRef.oncanplaythrough = () => this.audioRef?.play();
        this.audioRef.onended = () => {
            this.currentlyPlaying = null;
            this.audioRef = null;
            this.changeDetectorRef.detectChanges();
        };
        this.audioRef.load();
    }

    public select(eventOption: Readonly<TypeOfEventOption>) {
        this.form.patchValue({
            eventOption: eventOption.eventButton.name
        });
    }

    public trackEventOption(_index: number, eventOption: Readonly<TypeOfEventOption>) {
        return eventOption.eventButton;
    }

    public async changeDate() {
        const result = await this.commentService.changeDate(this.form.get('date')?.value);
        if (result.data) {
            this.form.get('date')?.patchValue(result.data);
            this.changeDetectorRef.detectChanges();
        }
    }

    public trackItems(index: number) {
        return index;
    }

    protected readonly RaceTypeEnum = SessionCurrentRaceTypeEnum;

    getDataBase64(item: ObaMediaFile): BehaviorSubject<string> {
        return this.base64MediaDataOrUrl[item.id];
    }

    private async loadBase64Data(item: ObaMediaFile) {
        if (item.type === 'AUDIO') {
            const readFileResult = await Filesystem.readFile({
                path: item.filename,
                directory: Directory.Library
            });

            if (typeof readFileResult.data === 'string') {
                this.base64MediaDataOrUrl[item.id].next(readFileResult.data);
            } else {
                const base64 = await this.blobToBase64(readFileResult.data);
                this.base64MediaDataOrUrl[item.id].next(base64);
            }
        } else {
            const getUriResult = await Filesystem.getUri({
                path: item.filename,
                directory: Directory.Library
            });

            const videoUrl = Capacitor.convertFileSrc(getUriResult.uri);
            this.base64MediaDataOrUrl[item.id].next(videoUrl);
        }
    }

    blobToBase64(blob): Promise<string> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }

    protected readonly isDevMode = isDevMode;
}
