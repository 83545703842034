import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { DataMarkdownEnum } from 'src/app/shared/models/data.model';
import {ImageEnum, SessionCurrentRaceTypeEnum, Session, EventRaceType} from '../../../shared';
import { SessionEvent } from '../../../shared/models/sessionEvent.model';

@Component({
    selector: 'app-edit-race',
    templateUrl: './edit-race.component.html',
    styleUrls: ['./edit-race.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRaceComponent {
    @Input() public currentSession: Readonly<Session> | null | undefined;
    @Input() public clock: string | null;
    @Input() public raceEvent: SessionEvent;
    @Input() public totalMarks: ReadonlyArray<{
        mark: number;
        event: Readonly<SessionEvent> | undefined;
    }> | null;
    @Input() public currentMark: number | null;
    @Input() public date: Date | null;

    @Output() public readonly dismiss = new EventEmitter();
    @Output() public readonly editCurrentMark = new EventEmitter();
    @Output() public readonly save = new EventEmitter();
    @Output() public readonly cancel = new EventEmitter();
    @Output() public readonly editDate = new EventEmitter();
    @Output() public readonly addComment = new EventEmitter();
    @Output() public readonly practiceFinished = new EventEmitter();
    public readonly imageEnum = ImageEnum;
    public helpType = DataMarkdownEnum;
    protected readonly RaceTypeEnum = EventRaceType;
}
