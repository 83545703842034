import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-editable-item',
    templateUrl: './editable-item.component.html',
    styleUrls: ['./editable-item.component.scss']
})
export class EditableItemComponent {
    @Input()
    public control: FormControl<string>;

    @Input()
    public deletable = false;

    @Output()
    public delete: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public editingDone: EventEmitter<void> = new EventEmitter<void>();

    public itemShown = true;

    constructor() {}

    toggle = () => {
        this.itemShown = !this.itemShown;
    };

    editingDoneMethod() {
        this.toggle();
        this.editingDone.next();
    }
}
