<div
    class="bg-white text-black text-center p-[10px] w-[90%] flex flex-col items-center rounded-lg"
>
    <ng-container *ngTemplateOutlet="(isDone$ | async) ? done : inProgress"></ng-container>
    <ng-container *ngIf="(isDone$ | async) === false; else uploadFinished">
        <div class="mt-[30px] font-[590] text-[22px] leading-[26px]">
            {{ progress$ | async }}% Exporting
        </div>
        <div class="stay-on-page-text secondary leading-[26px] text-[20px]">
            Please stay on this page until the upload is complete.
        </div>
        <ion-button
            expand="full"
            shape="round"
            color="light"
            (click)="cancel.emit()"
            >Cancel</ion-button
        >
    </ng-container>
    <ng-template #uploadFinished>
        <div class="mt-[30px] font-[590] text-[22px] leading-[26px]">
            Exported
        </div>
        <div class="stay-on-page-text secondary leading-[26px]">
            Your sailing sessions were successfully exported.
        </div>
        <ion-button
            expand="full"
            shape="round"
            color="light"
            (click)="cancel.emit()"
            >Got it</ion-button
        >
    </ng-template>
</div>

<ng-template #inProgress>
    <svg
        width="51"
        height="42"
        viewBox="0 0 51 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.5 0.333984C20.7271 0.333984 16.2517 2.70462 13.5371 6.59212C6.30169 6.85879 0.5 12.8277 0.5 20.1257C0.5 26.5402 4.98792 31.9156 10.9858 33.3052C11.1129 31.8843 11.4405 30.5237 11.9217 29.2362C7.77588 28.2695 4.66667 24.5632 4.66667 20.1257C4.66667 14.9569 8.87292 10.7507 14.0417 10.7507L15.832 10.8483L16.4749 9.73747C18.3333 6.50622 21.7917 4.50065 25.5 4.50065C30.65 4.50065 34.968 8.19408 35.7743 13.2816L36.0876 15.2835L38.3784 14.9824C38.5972 14.9533 38.8146 14.9173 39.0417 14.9173C43.0625 14.9173 46.3333 18.1882 46.3333 22.209C46.3333 25.4986 44.1311 28.2526 41.1331 29.1589C41.6248 30.4484 41.9601 31.8171 42.0934 33.2441C46.9309 31.9025 50.5 27.4694 50.5 22.209C50.5 16.034 45.5897 10.9838 39.4689 10.7588C37.6647 4.61712 32.0604 0.333984 25.5 0.333984ZM25.5 19.1369C19.6708 19.666 15.0833 24.5776 15.0833 30.5464C15.0833 33.1318 15.9406 35.5558 17.4718 37.5329L15.0833 39.9173H23.4167V31.584L20.4829 34.5177C19.7121 33.349 19.25 31.9965 19.25 30.5423C19.25 26.8777 21.975 23.8669 25.5 23.3564V19.1369ZM29.6667 21.1673V29.5007L32.6004 26.5669C33.3713 27.7356 33.8333 29.0882 33.8333 30.5423C33.8333 34.2069 31.1083 37.2178 27.5833 37.7282V41.9478C33.4125 41.4186 38 36.5049 38 30.5382C38 27.9528 37.1427 25.5288 35.6115 23.5518L38 21.1673H29.6667Z"
            fill="black"
        />
    </svg>
</ng-template>

<ng-template #done>
    <svg
        width="51"
        height="42"
        viewBox="0 0 51 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.5 0.333984C20.6365 0.333984 16.5429 2.9371 13.8952 6.61247C6.51137 6.69625 0.5 12.7227 0.5 20.1256C0.5 27.5804 6.58694 33.6673 14.0417 33.6673H39.0417C45.345 33.6673 50.5 28.5123 50.5 22.209C50.5 15.9821 45.4561 10.9183 39.2573 10.7954C37.4479 4.79609 32.0682 0.333984 25.5 0.333984ZM25.5 4.50065C30.6808 4.50065 34.9256 8.25368 35.7539 13.1798L36.0469 14.9173H39.0417C43.0925 14.9173 46.3333 18.1581 46.3333 22.209C46.3333 26.2599 43.0925 29.5006 39.0417 29.5006H14.0417C8.83806 29.5006 4.66667 25.3293 4.66667 20.1256C4.66667 14.922 8.83806 10.7506 14.0417 10.7506C14.1146 10.7506 14.2843 10.7652 14.5625 10.7791L15.8361 10.8442L16.4709 9.73747C18.2711 6.60972 21.6206 4.50065 25.5 4.50065ZM30.277 11.361L21.3333 20.3047L16.5563 15.5277L13.6104 18.4736L21.3333 26.1966L33.223 14.307L30.277 11.361Z"
            fill="#14D58F"
        />
    </svg>
</ng-template>
