<ng-container *ngIf="currentSegment$ | async as currentSegment">
    <app-layout class="h-full" [scrollY]="false">
        <h4 toolbarContent>Sails up</h4>
        <ion-button button fill="clear" (click)="dismiss()">
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        <div class="flex flex-col h-full">
            <ion-segment
                mode="md"
                [value]="currentSegment"
                (ionChange)="changeSegment($any($event).detail.value)"
                class="mb-[32px] sticky"
            >
                <ion-segment-button value="sails">
                    <ion-label>Sails</ion-label>
                </ion-segment-button>
                <ion-segment-button value="favorites">
                    <ion-label>Favorites</ion-label>
                </ion-segment-button>
                <ion-segment-button value="history">
                    <ion-label>History</ion-label>
                </ion-segment-button>
            </ion-segment>
            <div class="h-full relative">
                <ng-container [ngSwitch]="currentSegment">
                    <div *ngSwitchCase="'sails'" class="mt-[32px] h-full">
                        <app-sails-up
                            [date]="date$ | async"
                            [sailsUp$]="sailsUp$"
                            (dismiss)="dismiss()"
                            (addComment)="addComment()"
                            (editDate)="editDate()"
                            (selectSails)="selectSails()"
                            (toggleSail)="toggleSail($event)"
                            (saveAsFavorite)="saveAsFavorite()"
                            (changeReef)="changeReef($event)"
                            [isFirstEvent$]="sessionHasNoPriorSailUpEvent$"
                            #sails
                        ></app-sails-up>
                    </div>
                    <span *ngSwitchCase="'favorites'" class="mt-[32px] h-full">
                        <app-sails-up-favorites
                            class="h-full"
                            [favorites]="favorites$ | async"
                            (removeFavorite)="removeFavorite($event)"
                            (selectFavorite)="selectFavorite($event)"
                        ></app-sails-up-favorites>
                    </span>
                    <span *ngSwitchCase="'history'" class="mt-[32px] h-full">
                        <app-sails-up-history
                            [history]="sailsUpHistory$ | async"
                            (clearHistory)="clearHistory()"
                        ></app-sails-up-history>
                    </span>
                </ng-container>
            </div>
        </div>
    </app-layout>
</ng-container>
