import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import {
    Comment,
    CommentMedia,
    SessionEventTypeId,
    SessionEvent,
    SessionEventType
} from '../../shared';

@Component({
    selector: 'app-comment-page',
    templateUrl: './comment-page.component.html',
    styleUrls: ['./comment-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentPageComponent implements OnInit {
    public items$: BehaviorSubject<Array<CommentMedia>> = new BehaviorSubject(
        []
    );
    public typeOfEvent: SessionEventType;
    // NICE mita: type
    public form: FormGroup;
    public sessionEvent: SessionEvent | null | undefined;
    public date?: Date;

    constructor(private readonly modalController: ModalController) {}

    public addItem(comment: Readonly<CommentMedia>) {
        this.items$.value.push(comment);
        this.items$.next(this.items$.value);
    }

    public removeItem(comment: Readonly<CommentMedia>) {
        this.items$.next(this.items$.value.filter((item) => item !== comment));
    }

    public dismiss(role?: string) {
        this.modalController.dismiss(
            {
                mediaFiles: this.items$.value,
                comment: this.form.get('comment')?.value,
                date: this.form.get('date')?.value
                    ? new Date(this.form.get('date')?.value).getTime()
                    : undefined,
                eventOption: this.form.get('eventOption')?.value
            } as Comment,
            role
        );
    }

    public ngOnInit(): void {
        this.form = new FormGroup({
            eventOption: new FormControl(
                this.sessionEvent?.comment?.eventOption ?? '',
                this.typeOfEvent.id !== SessionEventTypeId.COMMENT &&
                this.typeOfEvent.id !== SessionEventTypeId.RACE_START
                    ? Validators.required
                    : null
            ),
            comment: new FormControl(
                this.sessionEvent?.comment?.comment ?? '',
                this.typeOfEvent.id === SessionEventTypeId.COMMENT
                    ? Validators.required
                    : null
            ),
            date: new FormControl(
                this.date,
                this.date ? Validators.required : null
            )
        });

        if (this.sessionEvent?.comment) {
            this.items$.next(this.sessionEvent.comment.mediaFiles);
        }
    }
}
