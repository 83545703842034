import { ChangeDetectionStrategy, Component } from '@angular/core';
import { differenceInMilliseconds } from 'date-fns';
import { take, tap } from 'rxjs/operators';
import { ModalListComponent } from 'src/app/components/components/modal-list/modal-list.component';
import { PopupComponent } from 'src/app/components/components/popup/popup.component';
import { CommentService } from 'src/app/services/comment.service';
import { ModalService } from 'src/app/services/modal.service';
import {
    DataService,
    dateStringAsDate,
    ImageEnum,
    SESSION_EVENT_TYPES,
    SessionEvent,
    SessionEventTypeId
} from 'src/app/shared';
import {
    ModalListItem,
    ModalRolesEnum
} from 'src/app/shared/models/modelList.model';
import { SessionStore } from '../../session.store';
import { TestingEventService } from '../testing-event/testing-event.service';

@Component({
    selector: 'app-event-overview-page',
    templateUrl: './event-overview.page.html',
    styleUrls: ['./event-overview.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventOverviewPage {
    constructor(
        public readonly sessionStore: SessionStore,
        public readonly modalService: ModalService,
        public readonly dataService: DataService,
        public readonly commentService: CommentService,
        private readonly testingEventService: TestingEventService
    ) {}

    public async openModal(event: SessionEvent) {
        const list = [
            {
                icon: ImageEnum.time,
                name: 'Change time',
                role: ModalRolesEnum.select,
                id: 'time',
                selected: false
            },
            {
                icon: ImageEnum.pencil,
                name: 'Edit event',
                role: ModalRolesEnum.select,
                id: 'edit'
            },
            {
                icon: ImageEnum.bin,
                name: 'Remove event',
                role: ModalRolesEnum.select,
                id: 'remove',
                css: 'text-red-600'
            }
        ] as ReadonlyArray<ModalListItem>;

        const data = await this.modalService.createModalAndWaitForDismiss(
            ModalListComponent,
            {
                title: `Action: Current Session`,
                hasClear: false,
                list
            }
        );
        if (data.role === ModalRolesEnum.select) {
            if (data.data.id === 'remove') {
                const result = await this.modalService.createDialog(
                    PopupComponent,
                    {
                        icon: ImageEnum.bin,
                        title: 'Please confirm',
                        text: 'Are you sure you want to remove the event? Action cannot be undone',
                        buttons: [
                            {
                                text: 'Remove event',
                                role: 'select',
                                color: 'danger'
                            },
                            { text: 'Cancel', role: 'cancel', color: 'light' }
                        ]
                    }
                );
                if (result.role === 'select') {
                    this.sessionStore.removeEvent(event);
                }
            } else if (
                data.data.id === 'time' ||
                event.typeOfEvent === SessionEventTypeId.RACE_MARK ||
                event.typeOfEvent === SessionEventTypeId.RACE_FINISH ||
                event.typeOfEvent === SessionEventTypeId.CANCELLED ||
                event.typeOfEvent === SessionEventTypeId.START_SESSION ||
                event.typeOfEvent === SessionEventTypeId.STOP_SESSION
            ) {
                const result = await this.commentService.changeDate(
                    new Date(event.dateTimeUtc)
                );
                if (result.data) {
                    this.sessionStore
                        .select((x) =>
                            x.currentSession?.events.find(
                                (f) =>
                                    f.typeOfEvent ===
                                    SessionEventTypeId.START_SESSION
                            )
                        )
                        .pipe(
                            take(1),
                            tap(async (sessionEvent) => {
                                if (
                                    differenceInMilliseconds(
                                        dateStringAsDate(
                                            sessionEvent?.dateTimeUtc
                                        ),
                                        dateStringAsDate(result.data)
                                    ) > 0 &&
                                    event.typeOfEvent !==
                                        SessionEventTypeId.START_SESSION
                                ) {
                                    await this.modalService.createDialog(
                                        PopupComponent,
                                        {
                                            icon: ImageEnum.alert,
                                            title: 'Error',
                                            text: 'The selected time cannot be before the Start session.',
                                            buttons: [
                                                {
                                                    text: 'Ok',
                                                    role: 'select',
                                                    color: 'primary'
                                                }
                                            ]
                                        }
                                    );
                                } else {
                                    event.dateTimeUtc = dateStringAsDate(
                                        result.data
                                    ).getTime();
                                    this.sessionStore.saveDate(event);
                                }
                            })
                        )
                        .subscribe();
                }
            } else if (data.data.id === 'edit') {
                if (event.typeOfEvent === SessionEventTypeId.TESTING) {
                    this.testingEventService.openEditPage(event);
                } else {
                    const typeOfEvent = SESSION_EVENT_TYPES[event.typeOfEvent];
                    this.sessionStore.editEvent([typeOfEvent, event]);
                }
            }
        }
    }

    public help() {}
}
