import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    CommentMedia,
    DataService,
    SESSION_EVENT_TYPES,
    SessionEvent,
    SessionEventTypeId
} from 'src/app/shared/index';
import { SessionStore } from '../../../session.store';
import { EditTestingService } from './edit-testing.service';

@Component({
    selector: 'app-edit-testing',
    templateUrl: './edit-testing.page.html',
    styleUrls: ['./edit-testing.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTestingPage implements OnInit, OnDestroy {
    @Input()
    public editTestingService: EditTestingService;

    @Input()
    public testingEvent: SessionEvent;

    public items$: BehaviorSubject<Array<CommentMedia>> = new BehaviorSubject(
        []
    );
    public form: FormGroup;
    public date? = new Date();

    private readonly componentDestroyed$ = new Subject<void>();

    private eventWasChanged = false;

    constructor(
        public readonly sessionStore: SessionStore,
        public readonly dataService: DataService
    ) {}

    public addItem(comment: Readonly<CommentMedia>) {
        this.items$.value.push(comment);
        this.items$.next(this.items$.value);
    }

    public removeItem(comment: Readonly<CommentMedia>) {
        this.items$.next(this.items$.value.filter((item) => item !== comment));
    }

    public appCommentDismiss(role: string) {
        if (role === 'save') {
            this.editTesting();
        } else {
            this.dismiss();
        }
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public dismiss() {
        this.editTestingService.cancelEditing();
    }

    public editTesting() {
        this.editTestingService.editTestingEvent({
            sessionEvent: {
                ...this.testingEvent,
                comment: {
                    comment: this.form.get('comment')?.value,
                    mediaFiles: this.items$.value,
                    date: this.form.get('date')?.value
                }
            }
        });
    }

    public trackBy = (index: number) => index;

    public ngOnInit(): void {
        this.form = new FormGroup({
            eventOption: new FormControl(''),
            comment: new FormControl(this.testingEvent?.comment?.comment ?? ''),
            date: new FormControl(
                this.testingEvent?.dateTimeUtc,
                Validators.required
            ),
            interval: new FormControl(
                this.testingEvent?.testIntervalMinutes ?? 0,
                Validators.required
            )
        });

        this.eventWasChanged = false;

        this.items$.next(this.testingEvent.comment.mediaFiles);

        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
                this.eventWasChanged = true;
            });
    }

    protected readonly SessionEventTypeId = SessionEventTypeId;
    protected readonly SESSION_EVENT_TYPES = SESSION_EVENT_TYPES;
}
