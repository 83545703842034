<hr class="ml-[20px] w-full" />
<div class="event-item ml-[20px] my-[20px]">
    <ion-row class="items-center grid grid-cols-[32px_160px_1fr_40px]">
        <app-images [image]="imageSource" class="svg-20 w-4"></app-images>
        <ion-col>{{ title }}</ion-col>
        <ion-col>{{ event.dateTimeUtc | date : 'HH:mm:ss' }}</ion-col>
        <ion-buttons>
            <ion-button fill="clear" (click)="openModal.emit(event)"
                ><ion-icon name="ellipsis-horizontal"></ion-icon
            ></ion-button>
        </ion-buttons>
    </ion-row>
    <ion-row class="pl-[36px] font-bold"><ng-content></ng-content> </ion-row>
    <div
        class="pl-[32px] rounded p-2 bg-amber-200"
        *ngIf="
            showComment &&
            ((event?.comment?.comment && event?.comment?.comment !== '') ||
                (event?.comment?.mediaFiles?.length ?? 0) > 0)
        "
    >
        {{ event?.comment?.comment }}
        <!--    NICE: Maybe implement in the future in a prettiert way. For now its disabled-->
        <div class="grid grid-cols-3" *ngIf="false">
            <!--      *ngIf="(event?.comment?.mediaFiles?.length ?? 0) > 0"-->
            >
            <div
                class="relative h-[80px] w-[80px] bg-[#083A81] rounded items-center flex justify-center"
                [ngClass]="{ 'pt-[26px]': item.type === 'AUDIO' }"
                *ngFor="
                    let item of event?.comment?.mediaFiles;
                    trackBy: trackBy
                "
            >
                <div *ngIf="item.type === 'AUDIO'" class="h-full w-[40px]">
                    <div class="text-white mt-[10px]">
                        {{ item.durationSec | msToUser }}
                    </div>
                </div>

                <!--          // NICE mita-->

                <!--        <div *ngIf="item.type === 'IMAGE'" class="h-full w-[40px]">-->
                <!--          <img-->
                <!--            src="data:image/png;base64,{{ item.data }}"-->
                <!--            class="h-full w-[40px]"-->
                <!--          />-->
                <!--        </div>-->

                <!--          // NICE mita:-->
                <!--        <div *ngIf="item.type === 'VIDEO'">-->
                <!--          <video class="h-full w-[40px]" controls>-->
                <!--            <source type="video/mp4" [src]="item.data | safeUrl: 'VIDEO'" />-->
                <!--          </video>-->
                <!--        </div>-->
            </div>
        </div>
    </div>
</div>
