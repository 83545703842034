import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageEnum } from 'src/app/shared';

@Component({
    selector: 'app-layout-header',
    templateUrl: './layout-header.component.html',
    styleUrls: ['./layout-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutHeaderComponent {
    @Input() imageSource: ImageEnum | null = null;
    @Input() title: string | null = null;
    @Input() subTitle: string | null = null;
}
