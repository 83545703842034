import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { OAuth2Client } from '@byteowls/capacitor-oauth2';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import jwt_decode from 'jwt-decode';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../services/alert.service';
import { filterEmpty } from '../helpers';
import { ObaAuthInterceptor } from '../interceptors/oba-auth-interceptor.service';
import { HttpService } from './http.service';
import { IonicStorageService } from './ionic-storage.service';

export interface AuthState {
    name: string;
    mail: string;
    token: string;
    refreshToken: string;
    authorities: Authority[];
}

export enum Authority {
    UPLOAD_SESSION = 'UPLOAD_SESSION',
    READ_SESSION = 'READ_SESSION',
    WRITE_ROLES = 'WRITE_ROLES',
    READ_USER_DATA = 'READ_USER_DATA'
}

@Injectable({
    providedIn: 'root'
})
export class AuthStore {
    private static AUTH_STORAGE_KEY = 'OBA_AUTH_STORAGE_KEY';

    private _auth$: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>(
        {
            mail: '',
            name: '',
            refreshToken: '',
            token: '',
            authorities: []
        }
    );

    private readonly _isInitialized$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public readonly isInitialized$: Observable<boolean> =
        this._isInitialized$.asObservable();

    public readonly name$ = this._auth$.pipe(map((auth) => auth.name));

    public readonly userProfile$ = this._auth$.pipe(
        map((auth) => ({
            firstName: auth.name.split(' ')[0],
            lastName: auth.name.substring(auth.name.split(' ')[0].length + 1),
            mail: auth.mail
        }))
    );

    public readonly isLoggedIn$ = this._auth$.pipe(map((x) => Boolean(x.name)));

    private readonly authConfig = {
        appId: environment.appId,
        authorizationBaseUrl: `https://obaapp.b2clogin.com/tfp/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/authorize`,
        scope: 'openid a11b646a-3941-4608-93a1-9b04bcb46d54 offline_access',
        accessTokenEndpoint:
            'https://obaapp.b2clogin.com/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/token',
        // accessTokenEndpoint: `https://login.microsoftonline.com/${environment.tenantId}/oauth2/v2.0/token`,
        // resourceUrl: 'https://graph.microsoft.com/v1.0/me/',
        responseType: 'token',
        logsEnabled: true,
        web: {
            pkceEnabled: true,
            redirectUrl: environment.redirectUrl,
            responseType: 'code',
            accessTokenEndpoint:
                'https://obaapp.b2clogin.com/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/token',
            windowOptions: 'height=600,left=0,top=0',
            windowTarget: '_self'
        },
        android: {
            pkceEnabled: true,
            responseType: 'code',
            redirectUrl: environment.b2c.android.redirectUri,
            accessTokenEndpoint:
                'https://obaapp.b2clogin.com/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/token',
            handleResultOnNewIntent: true,
            handleResultOnActivityResult: true
        },
        // All these values are duplicated on purpose, do NOT clean this up. ios does not use the default values from above!
        ios: {
            pkceEnabled: true, // workaround for bug #111
            redirectUrl: environment.b2c.ios.redirectUri,
            responseType: 'code',
            accessTokenEndpoint:
                'https://obaapp.b2clogin.com/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/token',
            appId: environment.appId,
            authorizationBaseUrl: `https://obaapp.b2clogin.com/tfp/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/authorize`,
            scope: 'openid a11b646a-3941-4608-93a1-9b04bcb46d54 offline_access',
            logsEnabled: true
        }
    };

    constructor(
        private readonly router: Router,
        private readonly platform: Platform,
        private readonly msalService: MsalService,
        private readonly msalBroadcastService: MsalBroadcastService,
        private readonly deviceDetectorService: DeviceDetectorService,
        private readonly httpService: HttpService,
        private readonly ionicStorageService: IonicStorageService,
        private readonly alertService: AlertService
    ) {
        this.initialize();

        if (this.useMsal()) {
            this.msalBroadcastService.msalSubject$
                .pipe(
                    filter(
                        (result) =>
                            result.eventType ===
                                EventType.ACQUIRE_TOKEN_SUCCESS ||
                            result.eventType === EventType.HANDLE_REDIRECT_END
                    ),
                    map(
                        () => this.msalService?.instance?.getAllAccounts()?.[0]
                    ),
                    filterEmpty()
                )
                .subscribe((result) => {
                    this._auth$.next({
                        ...this._auth$.value,
                        name: result.username,
                        mail: result.username
                    });
                });
        }

        this.isLoggedIn$
            .pipe(
                distinctUntilChanged(),
                filter((loggedIn) => loggedIn)
            )
            .subscribe(() => {
                this.httpService
                    .get('auth/role/authorities', {
                        context: new HttpContext().set(
                            ObaAuthInterceptor.IS_QUIET,
                            true
                        )
                    })
                    .pipe(take(1))
                    .subscribe((authorities: string[]) => {
                        this._auth$.next({
                            ...this._auth$.value,
                            authorities: this.filterAuthorities(authorities)
                        });
                    });
            });
    }

    public async useOffline() {
        this._auth$.next({
            name: 'Unknown User (Offline Mode)',
            mail: '',
            token: '',
            refreshToken: '',
            authorities: []
        });
        this.router.navigate(['home']);
    }

    public async login() {
        if (this.useMsal()) {
            await this.msalService.loginRedirect().toPromise();
            return;
        }

        const result = await OAuth2Client.authenticate(this.authConfig);
        const decodedToken: any = jwt_decode(
            result.access_token_response.access_token
        );

        const newAuthState: AuthState = {
            name: decodedToken.emails[0],
            mail: decodedToken.emails[0],
            token: result.access_token_response.access_token,
            refreshToken: result.access_token_response.refresh_token,
            authorities: []
        };

        this._auth$.next(newAuthState);

        this.saveToStorage();

        // NICE mita: Make a call to one of the unprotected routes to register the user in the server
        // there should be an endpoint just for i will do this later
        this.httpService
            .get('auth/assets/faq', {
                context: new HttpContext().set(
                    ObaAuthInterceptor.IS_QUIET,
                    true
                )
            })
            .pipe(take(1))
            .subscribe();
    }

    public logout() {
        if (this.useMsal()) {
            this.msalService.logout();
            return;
        }

        from(OAuth2Client.logout(this.authConfig)).subscribe(() => {
            this._auth$.next({
                name: '',
                mail: '',
                token: '',
                refreshToken: '',
                authorities: []
            });

            this.saveToStorage();

            if (this.platform.is('ios')) {
                Browser.open({
                    url:
                        'https://obaapp.b2clogin.com/tfp/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/logout?post_logout_redirect_uri=' +
                        environment.b2c.ios.redirectUri
                });
                Browser.addListener('browserPageLoaded', () => {
                    Browser.close();
                });
            } else {
                Browser.open({
                    url:
                        'https://obaapp.b2clogin.com/tfp/obaapp.onmicrosoft.com/b2c_1_emailreg/oauth2/v2.0/logout?post_logout_redirect_uri=' +
                        environment.b2c.android.redirectUri
                });
            }
            this.router.navigate(['home']);
        });
    }

    public async askDeleteAccount() {
        const DELETE_CHOICE = 'DELETE';
        const choice = await this.alertService.showAlert({
            choices: [
                {
                    text: 'Yes, delete',
                    id: DELETE_CHOICE
                }
            ],
            title: "Delete account",
            message: `Do you really want to delete your account?
                     <br><br>An additional confirmation message will be sent to your accounts email address.
                     After confirmation, your account will be permanently deleted and cannot be restored.`
        });

        if (choice === DELETE_CHOICE) {
            const OK_CHOICE = 'OK';
            const choice = await this.alertService.showAlert({
                choices: [
                    {
                        text: 'Ok',
                        id: OK_CHOICE
                    }
                ],
                title: "Confirmation sent",
                message: `A confirmation message has been sent to your accounts email address.
                          Please confirm the deletion of your account using the confirmation link in this email.
                          <br><br>
                          Please contact info@advancedsailingperformance.com if you do not receive an email within 24 hours.
                          <br><br>
                          You will now be logged out.`
            });

            if (choice == OK_CHOICE) {
                this.logout();
            }
        }
    }

    private async initialize() {
        const auth: AuthState | undefined = await this.ionicStorageService.get(
            AuthStore.AUTH_STORAGE_KEY
        );

        if (auth) {
            this._auth$.next(auth);
        }

        this._isInitialized$.next(true);
    }

    public async getToken(): Promise<string | undefined> {
        if (this.useMsal()) {
            return this.getMsalToken();
        } else {
            const oauthToken = await this.getOauthToken();
            return oauthToken;
        }
    }

    private useMsal() {
        const isMobileWeb = this.platform.is('mobileweb');
        if (isMobileWeb) {
            return true;
        }
        if (this.deviceDetectorService.isMobile()) {
            return false;
        }
        return this.platform.is('desktop');
    }

    private getMsalToken(): Promise<string> {
        let account;
        if (!!this.msalService.instance.getActiveAccount()) {
            account = this.msalService.instance.getActiveAccount();
        } else {
            account = this.msalService.instance.getAllAccounts()[0];
        }

        const accessTokenRequest = {
            scopes: [
                'https://obaapp.onmicrosoft.com/a11b646a-3941-4608-93a1-9b04bcb46d54/Api.Use'
            ],
            account: account
        };

        return this.msalService
            .acquireTokenSilent(accessTokenRequest)
            .pipe(
                catchError(async () => undefined),
                map((result?: AuthenticationResult) => {
                    const accessToken = result?.accessToken;
                    return accessToken;
                })
            )
            .toPromise();
    }

    private getOauthToken(): Promise<string | undefined> {
        return this._auth$
            .pipe(
                take(1),
                switchMap((state) => {
                    const token: string = state.token;
                    if (token) {
                        const decodedToken: any = jwt_decode(token);
                        const currentTimePlusTwoMinutes =
                            Math.floor(Date.now() / 1000) + 60 * 2; // Convert milliseconds to seconds
                        const tokenExpiresSoon =
                            decodedToken.exp < currentTimePlusTwoMinutes;

                        if (tokenExpiresSoon) {
                            return this.oauthRefreshAndReturnToken();
                        } else {
                            return of(token);
                        }
                    } else {
                        return of(undefined);
                    }
                })
            )
            .toPromise();
    }

    public hasAuthority(authority: Authority): boolean {
        return !!this._auth$.value.authorities?.includes(authority);
    }

    public async ensureLoggedIn(): Promise<'loggedIn' | 'notLoggedIn'> {
        let token = await this.getToken();

        if (!token) {
            token = await this.handlePossibleLoginAndGetToken();
            if (!token) {
                return 'notLoggedIn';
            } else {
                return 'loggedIn';
            }
        } else {
            return 'loggedIn';
        }
    }

    public async handlePossibleLoginAndGetToken(): Promise<string | undefined> {
        const shouldLogin = await this.askShouldLogin();

        if (shouldLogin) {
            await this.login();
            return this.getToken();
        } else {
            return undefined;
        }
    }

    private async askShouldLogin() {
        const alertResult = await this.alertService.showAlert({
            title: 'You need to be logged in to perform this operation. Do you want to login?',
            choices: [
                {
                    text: 'Yes',
                    id: 'YES'
                }
            ]
        });
        return alertResult === 'YES';
    }

    // Returns undefind if token could not be refreshed (login possibly needed)
    private oauthRefreshAndReturnToken(): Observable<string | undefined> {
        return this._auth$.pipe(
            take(1),
            switchMap((state) =>
                OAuth2Client.refreshToken({
                    appId: this.authConfig.appId,
                    accessTokenEndpoint: this.authConfig.accessTokenEndpoint,
                    scope: this.authConfig.scope,
                    refreshToken: state.refreshToken
                }).catch(() => {
                    console.log('Why no error?');
                    return undefined;
                })
            ),
            map((response) => {
                if (!response) {
                    return undefined;
                }

                this._auth$.next({
                    ...this._auth$.value,
                    token: response.access_token,
                    refreshToken: response.refresh_token
                });

                return response.access_token;
            })
        );
    }

    private saveToStorage() {
        this.ionicStorageService.set(
            AuthStore.AUTH_STORAGE_KEY,
            this._auth$.value
        );
    }

    private filterAuthorities(authorities: string[]): Authority[] {
        return authorities.filter((authorityString) =>
            Object.values(Authority)
                .map((authority) => authority.toString())
                .includes(authorityString)
        ) as Authority[];
    }
}
