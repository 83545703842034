<app-comment
    class="h-full"
    [items$]="items$"
    [typeOfEvent]="SESSION_EVENT_TYPES.TESTING"
    [form]="form"
    (newItem)="addItem($event)"
    (removeItem)="removeItem($event)"
    (dismiss)="dismissFromAppComment($event)"
    [withDateChange]="false"
    [showSaveButton]="false"
    title="Test phase running"
>
    <ng-container additionalHeader>
        <app-test-timer
            *ngIf="!testingStopped"
            [isSticky]="false"
            [clickable]="false"
        >
        </app-test-timer>
        <div class="flex flex-col">
            <span class="mb-1">Start time: {{startTimeFormatted}}</span>
            <span *ngIf="testingStopped" class="mb-1"
                >Duration: {{calculateDuration()}}s
            </span>
        </div>
    </ng-container>

    <ng-container additionalData [formGroup]="form">
        <app-input-select
            title="Auto stop"
            [list]="durations | listItem"
            formControlName="interval"
        ></app-input-select>
    </ng-container>

    <ng-container additionalFooterData>
        <ion-button
            *ngIf="!testingStopped"
            shape="round"
            expand="full"
            (click)="stop()"
            class="cancel__button"
            >End testing
        </ion-button>
        <ion-button
            *ngIf="!testingStopped"
            shape="round"
            expand="full"
            (click)="cancelTesting()"
            class="cancel__button"
            >Cancel testing
        </ion-button>
        <ion-button
            *ngIf="testingStopped"
            shape="round"
            expand="full"
            (click)="sendStopTesting()"
            class="cancel__button"
            >Save and Close
        </ion-button>
        <ion-button
            *ngIf="testingStopped"
            shape="round"
            expand="full"
            (click)="cancelTesting()"
            class="cancel__button"
            >Discard test
        </ion-button>
    </ng-container>
</app-comment>
