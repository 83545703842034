import { BoatType } from '../../../data/TypeOfBoat';
import { SailGroupType } from '../../../data/TypeOfSailGroup';
import { SailingType } from '../../../data/TypeOfSailing';
import {
    BoatspeedEnum,
    PropertyType,
    SessionEventTypeId,
    SessionType
} from '../../models/index';

export interface SessionDto {
    id: string;
    name: string;
    boat: BoatDtoForSession;
    startDateTimeUtc: string;
    endDateTimeUtc: string;
    typeOfSession: SessionType[];
    group: string;
    sails: SailDto[];

    sessionPropertiesDefinition: PropertyDefinitionDto[];
    sessionProperties: PropertyDto[];
    changeablePropertiesDefinition: PropertyDefinitionDto[];
    events: SessionEventDto[];
}

export interface BoatDtoForSession {
    id: string;
    name: string;
    typeOfBoat: BoatType;
    numberOfMasts: number;
    lengthFt: number;
    displacementKg?: number;
    typeOfSailing?: SailingType;
    canPlane?: boolean;
    canFoil?: boolean;
    canAsymmetricalSails?: boolean;
    upwind?: BoatspeedEnum;
    downwind?: BoatspeedEnum;
    // image?: BoatImage;
}

export interface BoatDto {
    id: string;
    name: string;
    typeOfBoat: BoatType;
    numberOfMasts: number;
    lengthFt: number;
    displacementKg?: number;
    typeOfSailing?: SailingType;
    canPlane?: boolean;
    canFoil?: boolean;
    canAsymmetricalSails?: boolean;
    upwind?: BoatspeedEnum;
    downwind?: BoatspeedEnum;
    sails: SailDto[];
    sessionPropertiesDefinition: PropertyDefinitionDto[];
    changeablePropertiesDefinition: PropertyDefinitionDto[];
    // image?: BoatImage;
}

export interface SailDto {
    id: string;
    name: string;
    typeOfSail: SailGroupType;
    reefs: number;
    weightKg?: number;
}

export interface ListOfValuesPropertyDto extends PropertyDefinitionDto {
    propertyValues: string[];
}

export interface NumberPropertyDto extends PropertyDefinitionDto {
    minBound: number;
    maxBound: number;
    increment: number;
}

export interface PropertyDto {
    name: string;
    value: string | number;
}

export interface PropertyDefinitionDto {
    id: number;
    propertyName: string;
    typeOfProperty: PropertyType;
    comments?: string[];
}

export interface SessionEventDto {
    id: string;
    dateTimeUtc: string;
    typeOfEvent: SessionEventTypeId;
    comment: string;
    mediaFiles: MediaFileDto[];
    eventOption: string;
}

export interface MediaFileDto {
    type: 'AUDIO' | 'VIDEO' | 'IMAGE';
    id: string;
    dateTimeUtc: string | null;
    mimeType: string;
    size: number;
}

export interface AudioMediaFileDto extends MediaFileDto {
    type: 'AUDIO';
    durationSec: number;
}

export interface VideoMediaFileDto extends MediaFileDto {
    type: 'VIDEO';
    durationSec: number;
}

export interface ImageMediaFileDto extends MediaFileDto {
    type: 'IMAGE';
}

export interface SailUpEventDto extends SessionEventDto {
    sailsUp: SailUpDto[];
    typeOfEvent: SessionEventTypeId.SAIL_UP;
}

export interface PropertyChangeEventDto extends SessionEventDto {
    property: PropertyDto;
    typeOfEvent: SessionEventTypeId.PROPERTY;
}

export interface CommentEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.COMMENT;
}

export interface IssueEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.ISSUE;
}

export interface BalanceEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.BALANCE;
}

export interface CalibrationEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.CALIBRATION;
}

export interface RacingEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.RACE_START;
    raceType: RaceTypeDto;
    raceNumber: number;
}

export enum RaceTypeDto {
    PRACTICE_START = 'PRACTICE_START',
    RACE = 'RACE'
}

export interface TestingEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.TESTING;
    testdurationSec: number;
}

export interface MarkEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.RACE_MARK;
    mark: number;
}

export interface FinishEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.RACE_FINISH;
}

export interface CancelledEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.CANCELLED;
}

export interface StartSessionEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.START_SESSION;
}

export interface StopSessionEventDto extends SessionEventDto {
    typeOfEvent: SessionEventTypeId.STOP_SESSION;
}

export interface SailUpDto {
    name: string;
    reef: number | null;
}
