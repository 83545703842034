import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SessionEvent } from '../../../../shared/index';

export enum EditTestingResultRole {
    SAVE = 'SAVE',
    CANCEL = 'CANCEL'
}

export interface EditTestingResultData {
    sessionEvent: SessionEvent;
}

export interface EditTestingResult {
    role: EditTestingResultRole;
    data?: EditTestingResultData;
}

@Injectable()
export class EditTestingService {
    private _editTestingResult$: Subject<EditTestingResult> =
        new Subject<EditTestingResult>();

    constructor() {}

    editTestingEvent(resultData: EditTestingResultData) {
        this._editTestingResult$.next({
            role: EditTestingResultRole.SAVE,
            data: resultData
        });
    }

    cancelEditing() {
        this._editTestingResult$.next({
            role: EditTestingResultRole.CANCEL
        });
    }

    public get editTestingResult$(): Observable<EditTestingResult> {
        return this._editTestingResult$;
    }
}
