import {
    HttpContextToken,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../services/alert.service';
import { AuthStore } from '../services/index';

@Injectable({
    providedIn: 'root'
})
export class ObaAuthInterceptor implements HttpInterceptor {
    static readonly IS_QUIET = new HttpContextToken<boolean>(() => false);

    constructor(
        private authStore: AuthStore,
        private alertService: AlertService,
        private toastController: ToastController
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                'ngrok-skip-browser-warning': 'value'
            }
        });

        if (!this.isRequestForApi(req, environment.apiUrl)) {
            console.log('Request is not for api: ' + req.url);
            return next.handle(req);
        }

        const isQuiet = req.context.get(ObaAuthInterceptor.IS_QUIET);

        return from(this.authStore.getToken()).pipe(

            switchMap((token) => {

                if (!token && !isQuiet) {
                    return this.authStore.handlePossibleLoginAndGetToken();
                } else if (!token && isQuiet) {
                    return throwError(
                        'No token and quiet request - Can\'t execute'
                    );
                } else {
                    return of(token);
                }
            }),

            switchMap((token) => {
                if (!token) {
                    if (!isQuiet) {
                        this.showNotLoggedInToast();
                    }
                    return throwError('No token available');
                }

                const modifiedReq = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });

                return next.handle(modifiedReq).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (!(error.error instanceof ErrorEvent)) {
                            if (error.status === 403) {
                                if (!isQuiet) {
                                    this.showNotAllowedAlert();
                                }
                            }
                        }
                        return throwError(error);
                    })
                );
            })
        );
    }

    private async showNotLoggedInToast() {
        const toast = await this.toastController.create({
            message: 'You need to be logged in for this action',
            color: 'danger',
            duration: 2500
        });
        toast.present();
    }


    private isRequestForApi(req: HttpRequest<any>, apiUrl: string): boolean {
        const requestUrl = req.url;

        return requestUrl.startsWith(apiUrl);
    }

    private async showNotAllowedAlert() {
        this.alertService.showAlert({
            title: 'You are not authorized to perform this action. Please contact info@advancedsailingperformance.com for cloud access.',
            choices: []
        });
    }
}
