import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Sail } from '../../../shared/index';

export enum AddSailResultType {
    ADD = 'ADD',
    DISMISS = 'DISMISS'
}

export interface AddSailResult {
    sail?: Sail;
    type: AddSailResultType;
}

@Injectable({
    providedIn: 'root'
})
export class AddSailService {
    private _result$: Subject<AddSailResult> = new Subject<AddSailResult>();

    constructor() {}

    addSail(sail: Sail) {
        this._result$.next({
            sail: sail,
            type: AddSailResultType.ADD
        });
        this._result$.complete();
    }

    dismiss() {
        this._result$.next({
            type: AddSailResultType.DISMISS
        });
        this._result$.complete();
    }

    get result$(): Subject<AddSailResult> {
        return this._result$;
    }
}
