import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IonicStorageService {
    private _storage: Storage | null = null;
    private initialized$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this._storage = storage;
        this.initialized$.next(true);
    }

    public async set(key: string, value: any): Promise<any> {
        await this.initialized$
            .pipe(
                filter((value) => value === true),
                first()
            )
            .toPromise();
        return await this._storage?.set(key, value);
    }

    public async get(key: string): Promise<any> {
        await this.initialized$
            .pipe(
                filter((value) => value === true),
                first()
            )
            .toPromise();
        return await this._storage?.get(key);
    }
}
