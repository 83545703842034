import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'has'
})
export class HasPipe implements PipeTransform {
    public transform<T>(
        items: ReadonlyArray<T>,
        prop: keyof T,
        value: any
    ): boolean {
        return Boolean(items.find((item) => item[prop] === value));
    }
}

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
    public transform<T>(
        values: ReadonlyArray<T> | null | undefined,
        joinProp: keyof T
    ) {
        if (!values) {
            return '';
        }
        return values.map((value) => value[joinProp]).join(', ');
    }
}

@Pipe({
    name: 'length'
})
export class LengthPipe implements PipeTransform {
    public transform<T>(
        input: ReadonlyArray<T> | null | undefined,
        hasLength: number,
        doesNotHaveLength: number
    ) {
        return input?.length ? Number(hasLength) : Number(doesNotHaveLength);
    }
}
