<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button button fill="clear" (click)="dismiss.emit()">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
        <h4 class="text-center">Voice comment</h4>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="h-[40%] grid grid-rows-[2fr_1fr] place-items-center">
        <div *ngIf="!hasRecording; else showIsRecording">
            Click once on the button below to start recording
        </div>
        <ng-template #showIsRecording>
            <div class="flex items-center">
                <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-[10px]"
                >
                    <circle
                        cx="4"
                        cy="4"
                        r="4"
                        [ngClass]="{
                            'fill-[#BBBBBB]': !isRecording,
                            'fill-[#C84A22]': isRecording
                        }"
                    />
                </svg>

                <span
                    *ngIf="isRecording; else audioPause"
                    class="text-[#C84A22]"
                >
                    Recording {{ time }}
                </span>
                <ng-template #audioPause>
                    <span>Paused {{ time }}</span>
                </ng-template>
            </div>
        </ng-template>

        <div class="grid grid-cols-[3fr_1fr] w-full items-center">
            <div class="ml-[50%]" *ngIf="!isRecording; else showStop">
                <ion-button
                    button
                    class="rounded"
                    fill="clear"
                    (click)="recordAudio.emit()"
                >
                    <svg
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="35"
                            cy="35"
                            r="35"
                            fill="#083A81"
                            fill-opacity="0.97"
                        />
                        <path
                            d="M35.0001 21.667C32.7907 21.667 31.0001 23.4577 31.0001 25.667V33.667C31.0001 35.8763 32.7907 37.667 35.0001 37.667C37.2094 37.667 39.0001 35.8763 39.0001 33.667V25.667C39.0001 23.4577 37.2094 21.667 35.0001 21.667ZM35.0001 24.3337C35.7347 24.3337 36.3334 24.9323 36.3334 25.667V33.667C36.3334 34.4017 35.7347 35.0003 35.0001 35.0003C34.2654 35.0003 33.6667 34.4017 33.6667 33.667V25.667C33.6667 24.9323 34.2654 24.3337 35.0001 24.3337ZM25.6667 33.667C25.6667 38.3674 29.1459 42.2446 33.6667 42.8936V47.0003H36.3334V42.8936C40.8543 42.2446 44.3334 38.3674 44.3334 33.667H41.6667C41.6667 37.3483 38.6814 40.3337 35.0001 40.3337C31.3187 40.3337 28.3334 37.3483 28.3334 33.667H25.6667Z"
                            fill="white"
                        />
                    </svg>
                </ion-button>
            </div>
            <ng-template #showStop>
                <div class="ml-[50%]">
                    <ion-button
                        button
                        class="rounded"
                        (click)="stopRecording.emit()"
                        fill="clear"
                    >
                        <svg
                            width="70"
                            height="70"
                            viewBox="0 0 70 70"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="35" cy="35" r="35" fill="#C84A22" />
                            <path
                                d="M25.6667 25.667V44.3337H44.3334V25.667H25.6667ZM41.6667 41.667H28.3334V28.3337H41.6667V41.667Z"
                                fill="white"
                            />
                        </svg>
                    </ion-button>
                </div>
            </ng-template>
            <ion-button
                button
                class="w-[60px]"
                shape="round"
                (click)="done.emit()"
                *ngIf="hasRecording"
                >Done</ion-button
            >
        </div>
    </div>
</ion-content>
