import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { TYPE_OF_BOAT } from '../../../data/TypeOfBoat';
import { Boat, ImageEnum, TypeOfBoat } from '../../../shared';

@Component({
    selector: 'app-boat-overview',
    templateUrl: './boat-overview.component.html',
    styleUrls: ['./boat-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoatOverviewComponent implements OnChanges {
    @Input() public boat: Boat;
    public typeOfBoats: ReadonlyArray<TypeOfBoat> = TYPE_OF_BOAT;
    @Input() public hasMenu = false;

    @Output() public readonly selected = new EventEmitter<string>();

    public readonly imageEnum = ImageEnum;

    public sailsConcat: string;
    public boatPropertyConcat: string;
    public customPropertyConcat: string;

    public ngOnChanges(changes: SimpleChanges): void {
        const { boat } = changes;
        if (boat?.currentValue) {
            this.sailsConcat = this.boat?.sails?.map((x) => x.name)?.join(', ');
            this.boatPropertyConcat = this.boat?.properties
                ?.filter((prop) => !prop.canBeChangedWhileSailing)
                ?.map((x) => x.propertyName)
                ?.join(', ');
            this.customPropertyConcat = this.boat?.properties
                ?.filter((prop) => prop.canBeChangedWhileSailing)
                ?.map((x) => x.propertyName)
                ?.join(', ');
        }
    }
}
