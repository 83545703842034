import { ChangeDetectorRef, Component, Optional } from '@angular/core';
import { take } from 'rxjs/operators';
import { AddSailComponent } from 'src/app/my-boats/components/add-sail/add-sail.component';
import { BoatStore } from 'src/app/my-boats/current-boat-store.service';
import { ModalService } from 'src/app/services/modal.service';
import { WizardService } from 'src/app/services/wizard.service';
import { DataService, ImageEnum, Sail } from 'src/app/shared';
import { BoatsService } from '../../../my-boats/boats.service';
import {
    AddSailResultType,
    AddSailService
} from '../../../my-boats/components/add-sail/add-sail.service';
import {
    ActionSheetService,
    ChoiceActionSheetConfig
} from '../../../services/action-sheet.service';
import { SessionStore } from '../../session.store';

@Component({
    selector: 'app-session-sail-page',
    templateUrl: './session-sail.page.html',
    styleUrls: ['./session-sail.page.scss']
})
export class SessionSailPage {
    public readonly imageEnum = ImageEnum;

    constructor(
        @Optional() public readonly wizardService: WizardService,
        public readonly dataService: DataService,
        public readonly sessionStore: SessionStore,
        private readonly modalService: ModalService,
        private readonly actionSheetService: ActionSheetService,
        private readonly changeDetectionRef: ChangeDetectorRef,
        private readonly myBoatsStore: BoatStore,
        private readonly boatsService: BoatsService
    ) {}

    public async addSail() {
        const addSailService = new AddSailService();

        const modal = await this.modalService.createFullModal(
            AddSailComponent,
            false,
            'Add',
            {
                allSails$: this.sessionStore.availableSessionSails$,
                addSailService: addSailService
            }
        );

        const addSailResult = await addSailService.result$.toPromise();

        modal.dismiss();

        if (addSailResult.type == AddSailResultType.ADD) {
            this.sessionStore.currentBoat$.pipe(take(1)).subscribe((boat) => {
                this.boatsService.upsertSailToBoat(addSailResult.sail, boat.id);
            });
        }

        this.changeDetectionRef.detectChanges();
    }

    public async editSail(event: { index: number; sail: Sail }) {
        const EDIT = 'EDIT';
        const config: ChoiceActionSheetConfig = {
            choices: [
                {
                    text: 'Edit',
                    id: EDIT
                }
            ]
        };

        const actionSheetResult =
            await this.actionSheetService.showChoiceActionSheet(config);

        if (actionSheetResult == EDIT) {
            const addSailService = new AddSailService();

            const modal = await this.modalService.createModal<AddSailComponent>(
                AddSailComponent,
                {
                    data: event.sail,
                    allSails$: this.sessionStore.availableSessionSails$,
                    addSailService: addSailService,
                    edit: true,
                    title: 'Edit'
                }
            );

            const addSailResult = await addSailService.result$.toPromise();

            modal.dismiss();

            if (addSailResult.type == AddSailResultType.ADD) {
                this.sessionStore.currentBoat$
                    .pipe(take(1))
                    .subscribe((boat) => {
                        this.boatsService.upsertSailToBoat(
                            addSailResult.sail,
                            boat.id
                        );
                    });
            }
        }

        this.changeDetectionRef.detectChanges();
    }
}
