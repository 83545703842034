<app-layout [border]="true">
    <ion-button button fill="clear" (click)="modalController.dismiss(null)">
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <h4 toolbarContent>User var</h4>

    <app-edit-property-base [propertyForm]="sessionChangeablePropertyForm">
    </app-edit-property-base>

    <ion-footer>
        <ion-button shape="round" expand="full" (click)="saveProperty()">
            Change property
        </ion-button>
    </ion-footer>
</app-layout>
