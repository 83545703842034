import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private readonly domSanitizer: DomSanitizer) {}

    public transform(input?: string | null) {
        if (!input) {
            return '';
        }

        const safeHtml: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml(input);
        return safeHtml;
    }
}
