import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import {
    MsalGuard,
    MsalModule,
    MsalRedirectComponent
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    InteractionType,
    PublicClientApplication
} from '@azure/msal-browser';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ObaAuthInterceptor } from './shared/interceptors/oba-auth-interceptor.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '__mydb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        AppRoutingModule,
        ComponentsModule,
        SharedModule,
        ReactiveFormsModule,
        ColorPickerModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                // MSAL Configuration
                auth: {
                    clientId: environment.appId,
                    authority: `https://obaapp.b2clogin.com/obaapp.onmicrosoft.com/B2C_1_emailreg`,
                    knownAuthorities: ['obaapp.b2clogin.com'],
                    redirectUri: environment.redirectUrl
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    storeAuthStateInCookie: true // set to true for IE 11
                },
                system: {
                    loggerOptions: {
                        loggerCallback: () => {},
                        piiLoggingEnabled: false
                    }
                }
            }),
            {
                // The routing guard configuration.
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: [
                        'https://obaapp.onmicrosoft.com/a11b646a-3941-4608-93a1-9b04bcb46d54/Api.Use'
                    ]
                }
            },
            {
                interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
                protectedResourceMap: new Map([
                    [
                        environment.apiUrl + '*',
                        [
                            'https://obaapp.onmicrosoft.com/a11b646a-3941-4608-93a1-9b04bcb46d54/Api.Use'
                        ]
                    ]
                ])
            }
        )
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        MediaCapture,
        Keyboard,
        AppVersion,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ObaAuthInterceptor,
            multi: true
        },
        MsalGuard,
        AndroidPermissions
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
