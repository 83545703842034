<app-edit-race
    class="h-full"
    [currentSession]="currentSession$ | async"
    [clock]="clock$ | async"
    [totalMarks]="totalMarks$ | async"
    [currentMark]="currentMark$ | async"
    [date]="date$ | async"
    [raceEvent]="(raceEvent$ | async)"
    (dismiss)="modalController.dismiss(null, 'cancel')"
    (cancel)="cancel()"
    (editCurrentMark)="editCurrentMark()"
    (save)="save()"
    (editDate)="editDate()"
    (addComment)="addComment()"
    (practiceFinished)="practiceFinished()"
></app-edit-race>
