<div class="ion-page">
    <ion-content class="layout_content white">
        <app-layout-header
            title="Sail inventory"
            [imageSource]="imageEnum.sail"
        ></app-layout-header>
        <ng-container *ngIf="typeOfSailGroup as sailsGroup">
            <div class="px-5">
                <a (click)="addSail.emit()">
                    <ion-icon
                        size="large"
                        name="add-outline"
                        class="mr-2 align-middle"
                    ></ion-icon>
                    <span class="align-middle">Add a sail...</span></a
                >
            </div>
            <div *ngFor="let sail of sails; let i = index">
                <hr class="mt-6 mb-5" />

                <ion-row class="grid grid-cols-[32px_1fr_50px]">
                    <ion-col>
                        <app-images
                            [image]="getSailImage(sail)"
                            class="w-5 pt-3 ml-2"
                        ></app-images>
                    </ion-col>
                    <ion-col>
                        <ion-row class="block">{{ sail.name }}</ion-row>
                        <span class="footnote">{{
                            sailsGroup
                                | lookup : 'id' : 'name' : sail.typeOfSail
                        }}</span>
                    </ion-col>
                    <ion-col>
                        <ion-buttons>
                            <ion-button
                                (click)="editSail.emit({ index: i, sail })"
                            >
                                <ion-icon
                                    name="ellipsis-horizontal-outline"
                                ></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-col>
                </ion-row>
            </div>
        </ng-container>
        <div class="bottom_space">&nbsp;</div>
    </ion-content>
    <ion-footer>
        <ion-button
            (click)="next()"
            shape="round"
            expand="full"
            [disabled]="hasErrors"
            >Next
        </ion-button>
    </ion-footer>
</div>
