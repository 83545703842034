import { Injectable } from '@angular/core';
import {
    addMinutes,
    differenceInMilliseconds,
    intervalToDuration
} from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
    dateStringAsDate,
    durationToTime,
    Session,
    SessionEvent
} from '../../../shared/index';
import { SessionStore } from '../../session.store';

@Injectable({
    providedIn: 'root'
})
export class RaceTimerService {
    private _currentTimer$: BehaviorSubject<string> =
        new BehaviorSubject<string>('');
    private _timerRunning$: Observable<boolean> = this._currentTimer$.pipe(
        map((currentTimer) => !!currentTimer),
        distinctUntilChanged()
    );

    public difference: number;
    private previous = 0;

    private startDateTime: Date;
    private timer;
    private isTimerRunning = false;
    private currentSession: Session;
    private _raceEvent: SessionEvent;
    private _timingChanged$: Subject<void> = new Subject<void>();

    constructor(private sessionStore: SessionStore) {
        combineLatest([
            sessionStore.currentSession$,
            sessionStore.lastRacingEvent$
        ]).subscribe(([session, raceEvent]) => this.init(session, raceEvent));
    }

    public init(currentSession: Session, raceEvent: SessionEvent): void {
        if (currentSession && raceEvent) {
            if (!!currentSession.raceTimerStartedAt) {
                this.currentSession = currentSession;
                this._raceEvent = raceEvent;

                this.startDateTime = dateStringAsDate(
                    currentSession.raceTimerStartedAt
                );

                this.eventTimer();
                if (!this.isTimerRunning) {
                    this.timer = setInterval(() => {
                        this.eventTimer();
                    }, 1000);
                }

                this.isTimerRunning = true;
            } else {
                this.clear();
            }
        }
    }

    public eventTimer() {
        const currentDate = new Date();
        this.previous = this.difference;
        this.difference = differenceInMilliseconds(
            currentDate,
            this.startDateTime
        );

        if (this.previous < 0 && this.difference >= 0) {
            this._timingChanged$.next();
        }

        const duration = intervalToDuration({ start: 0, end: this.difference });
        if (!this.currentSession?.paused) {
            this._currentTimer$.next(durationToTime(duration));
        }
    }

    get currentTimer$(): Observable<string> {
        return this._currentTimer$;
    }

    get timerRunning$(): Observable<boolean> {
        return this._timerRunning$;
    }

    get raceEvent(): SessionEvent {
        return this._raceEvent;
    }

    get timingChanged$(): Subject<void> {
        return this._timingChanged$;
    }

    private clear() {
        this.isTimerRunning = false;
        this._currentTimer$.next('');
        this._raceEvent = undefined;
        clearInterval(this.timer);
    }
}
