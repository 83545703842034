import { differenceInMilliseconds, parseISO } from 'date-fns';
import { SessionEventTypeId, SailUp, Session } from '../shared';
import { SessionEvent } from '../shared/models/sessionEvent.model';
import { v4 as uuidv4 } from 'uuid';

export const isRaceStarted = (currentSession: Readonly<Session>) =>
    differenceInMilliseconds(
        new Date(),
        parseISO(String(currentSession.raceTimerStartedAt))
    ) > 0;

export const createSailsUpEvent = (sailsUp: ReadonlyArray<SailUp>) =>
    ({
        id: uuidv4(),
        dateTimeUtc: new Date().getTime(),
        typeOfEvent: SessionEventTypeId.SAIL_UP,
        sailsUp
    } as SessionEvent);
