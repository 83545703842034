<app-layout>
    <ion-button button fill="clear" (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <h4 toolbarContent>Date & time</h4>
    <div>
        <ion-datetime
            [value]="dateString"
            (ionChange)="onDateChange($event)"
        ></ion-datetime>
        <ion-item lines="none">
            <div class="grid gap-[15px] grid-rows-[auto_1fr] w-full">
                <ion-button
                    button
                    class="font-[590] h-[50px]"
                    color="light"
                    (click)="now()"
                    >NOW</ion-button
                >
                <div class="grid grid-cols-[1fr_1fr_1fr_1fr] gap-[15px]">
                    <ion-button
                        button
                        class="h-[50px] font-[590]"
                        color="light"
                        *ngFor="let option of timeOptions"
                        (click)="updateDateTime(option)"
                    >
                        {{ option.label }}
                    </ion-button>
                </div>
            </div>
        </ion-item>
        <ion-footer class="ion-no-border normal-footer pt-[10px]">
            <ion-button shape="round" expand="full" (click)="save()"
                >Save changes</ion-button
            >
        </ion-footer>
    </div>
</app-layout>
