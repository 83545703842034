<app-start-race
    class="h-full"
    [form]="form"
    [isEdit]="isEdit$ | async"
    [options]="options"
    [date]="date$ | async"
    (closes)="modalController.dismiss(null)"
    (startRace)="startRace($event)"
    (cancel)="cancel()"
    (editDate)="editDate()"
    (addComment)="addComment()"
>
</app-start-race>
