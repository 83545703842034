import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { SetDateTimeComponent } from '../components/components/set-date-time/set-date-time.component';
import { CommentPageComponent } from '../pages/comment-page/comment-page.component';
import {
    Comment,
    DataService,
    SESSION_EVENT_TYPES,
    SessionEvent,
    SessionEventType
} from '../shared/index';

@Injectable({
    providedIn: 'root'
})
export class CommentService {
    public readonly defaultCommentEvent$ = of(SESSION_EVENT_TYPES.COMMENT);

    constructor(
        private readonly modalController: ModalController,
        private readonly dataService: DataService
    ) {}

    public openDefaultComment(): Observable<SessionEvent | null> {
        return this.defaultCommentEvent$.pipe(
            switchMap((comment) => from(this.openComment(comment))),
            take(1)
        );
    }

    public async openComment(
        typeOfEvent: SessionEventType,
        date?: Date,
        sessionEvent?: SessionEvent
    ) {
        const data: SessionEvent | null = await this.createCommentComponent(
            typeOfEvent,
            date,
            sessionEvent
        );
        return data;
    }

    public async changeDate(date: Date | number) {
        if (typeof date === 'number') {
            date = new Date(date);
        }

        const modal = await this.modalController.create({
            component: SetDateTimeComponent,
            componentProps: {
                date
            }
        });

        await modal.present();

        const result = await modal.onWillDismiss<Date>();
        return result;
    }

    private async createCommentComponent(
        typeOfEvent: SessionEventType,
        date?: Date,
        sessionEvent?: SessionEvent
    ) {
        const dialog = await this.modalController.create({
            component: CommentPageComponent,
            componentProps: {
                typeOfEvent,
                date,
                sessionEvent,
                items: sessionEvent?.comment?.mediaFiles ?? []
            }
        });

        await dialog.present();

        const result = await dialog.onDidDismiss<Comment>();
        if (result.role === 'save' && result.data) {
            const data: SessionEvent = {
                id: uuidv4(),
                dateTimeUtc: result.data.date ?? new Date().getTime(),
                typeOfEvent: typeOfEvent.id,
                comment: result.data,
                eventOption: result.data.eventOption
            };
            return data;
        }

        return null;
    }
}
